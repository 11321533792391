.form-section-title {
  font-size: 24px;
  font-weight: 600;
  color: var(--primary-color);
}
.workshop-form .ant-input:not(#workshop_form_about, #workshop_setup_warranty),
.workshop-form .ant-input-number-input {
  border: none !important;
  border-bottom: 1px solid #444 !important;
  border-radius: 0 !important;
  padding: 0 !important;
}
.workshop-form .ant-form-item-extra {
  color: #444 !important;
  font-family: Emprint-semibold;
  font-size: 16px;
  line-height: 20px;
}
.workshop-form
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: none !important;
  border-bottom: 1px solid #444 !important;
  border-radius: 0 !important;
  padding: 0 !important;
}
.extra-small-font {
  font-size: 12px;
}
.small-font {
  font-size: 14px;
}
.medium-font {
  font-size: 16px;
}
.large-font {
  font-size: 24px;
}
.primary-color {
  color: var(--primary-color);
}
.gray-color {
  color: var(--gray-color);
}
.zero-margin {
  margin: 0;
}
.simple-lightblue-highlight {
  background-color: #eff6ff;
  padding: 0.1em 0.2em;
}
.workshop-form .logo-container {
  width: 220px;
  height: 220px;
}
@media only screen and (max-width: 992px) {
  .workshop-form .logo-container {
    width: 200px;
    height: 200px;
  }
}

