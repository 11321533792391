.entry-request-container {
  position: relative;
  top: 0;
  left: 0;
  background: #FFFFFF;
  border: 1px solid #CCCCCC;
  border-left: 0;
  height: calc(100vh - 90px);
  overflow: hidden;
  width: 100%;
}

@media (max-width: 990px) {
  .entry-request-container {
    height: calc(100vh - 117px);
  }
}
.entry-request-container.contain-in-body {
  display: none;
}


/* Drawer */
.ant-drawer.entry-request-drawer.contain-in-div.ant-drawer-open {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.entry-request-drawer.ant-drawer-open {
  top: 0;
}
.entry-request-drawer .ant-drawer-header {
  padding: 0;
  display: none;
}
.entry-request-drawer .ant-drawer-body {
  padding: 0 !important;
  position: relative;
  top: 0;
  left: 0;
  height: 100vh;
}
.entry-request-drawer .entry-request-wrapper {
  height: 100vh; 
}

.entry-request-drawer.contain-in-div .ant-drawer-body,
.entry-request-drawer.contain-in-div .entry-request-wrapper {
  height: calc(100vh - 90px);
  overflow: hidden;
}
@media (max-width: 990px) {
  .entry-request-drawer.contain-in-div .ant-drawer-body,
  .entry-request-drawer.contain-in-div .entry-request-wrapper  {
    height: calc(100vh - 117px);
  }
}

@media(min-width: 860px) {
  .entry-request-drawer {
    position: absolute;
    z-index: 80;
  }
}
.entry-request-drawer.ant-drawer-open .ant-drawer-content-wrapper {
  height: 100% !important;
}
.entry-request-drawer .ant-spin-nested-loading > div > .ant-spin  {
  min-height: calc(100vh - 128px);
}
.entry-request-drawer .wheel-loader {
  background: rgba(0,0,0,0.3);
}

/* Header */
.entry-request-drawer .figure-modal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: auto;
  /* padding: 20px; */
  margin: 0;
  gap: 5px;
}
.entry-request-drawer .figure-modal .ant-image {
  width: 52px;
  height: 52px;
}
.entry-request-drawer .figure-modal .image-modal {
  width: 52px;
  height: 52px;
  object-fit: cover;
  border-radius: 6px;
}
.entry-request-drawer .figure-modal p {
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  text-align: center;
  color: #444444;
  margin-bottom: 0px;
}

/** close button */
.entry-request-drawer .entry-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  align-content: center;
  border-bottom: 1px dashed #CCCCCC;
  position: relative;
  justify-content: space-between;
  position: fixed;
  /* top: 80px; */
  top: 0;
  z-index: 2;
  background: #fff;
  /* box-shadow: -2px 2px 5px 0px rgba(0,0,0,0.25); */
  height: 74px;
  gap: 10px
}

.entry-request-drawer.contain-in-div  .entry-header {
  justify-content: flex-end;
}
.entry-request-drawer.contain-in-div  .entry-header .figure-modal,
.entry-request-drawer.contain-in-div  .entry-header .close-button {
  display: none !important;
}


.entry-request-drawer .entry-header .options-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
}
/* .entry-request-drawer .entry-header .options-container .ant-btn {
  width: 40px;
  height: 40px;
  padding: 0;
} */
.entry-request-drawer .entry-header .options-container .vehicle-icon {
  padding-top: 10px;
}

.entry-request-drawer .entry-header .options-container svg {
  width: 30px;
  height: auto;
}
.entry-request-drawer .entry-header .options-container .close-button svg {
  width: 26px
}

.entry-request-drawer .entry-header .ant-btn {
  border: 0;
  box-shadow: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
}

@media (max-width: 575px) {
  .entry-request-drawer .entry-header {
    padding: 10px;
  }
}
@media (min-width: 860px) {
  .entry-request-drawer .entry-header {
    position: absolute;
  }
}
@media (min-width: 993px) {
  
  /* .entry-request-drawer .entry-header .ant-btn {
    position: absolute;
    right: 10px;
    top: 10px;
  } */
}
/** close button */
/* Header */

/* Messages */
.entry-request-drawer  .messages-scroll {
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-flow: column;
  padding: 20px 0 0 0;
  background: url(./background-desktop.png); 
}
@media (max-width: 992px) {
  .entry-request-drawer  .messages-scroll {
    background: url(./background-mobile.png); 
  }
}
/* Messages */

/* Request card styles */
.entry-request-drawer .message-card {
  display: flex;
  width: 80%;
  color: #444;
  position: relative;
  padding: 0px 20px 15px 20px;
  -webkit-user-select: none;
  user-select: none;
  /* border-bottom: 1px dashed #CCCCCC; */
  /* background: #EEEEEE; */
  flex-direction: row;
  gap: 10px;
}
@media (max-width: 768px) {
  .entry-request-drawer .message-card {
    width: 90%;
    padding: 0px 15px 15px 15px;
  }
}
@media (max-width: 575px) {
  .entry-request-drawer .message-card {
    width: 100%;
    padding: 0px 10px 15px 10px;
  }
}
.entry-request-drawer .message-card.incoming { 
  align-self: start;
  justify-content: flex-start;
}
.entry-request-drawer .message-card.outgoing { 
  align-self: end;
  justify-content: flex-end;
}
.entry-request-drawer .message-card.outgoing .card-message { 
  align-self: end;
  justify-content: flex-end;
}
.entry-request-drawer .message-card .message-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: center;
  width: auto;
  /* min-width: 50%; */
  padding: 10px 0px;
  border-radius: 10px;
  
}
.entry-request-drawer .message-card.incoming .card-message { 
  background: #DDDDDD;
}
.entry-request-drawer .message-card.outgoing .card-message { 
  background: #EEEEEE;
}

.entry-request-drawer .message-card .card-message {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: auto;
  align-content: flex-start;
  justify-content: flex-start;
  gap: 0px;
  background: #EEEEEE;
  padding: 10px 20px;
  border-radius: 10px;
}
.entry-request-drawer .message-card .card-message > div {
  width: calc(100% - 30px);
}
.entry-request-drawer .message-card .card-message .anticon {
  color: var(--mariner-color);
  text-align: left;
  margin-right: 2px;
}
.entry-request-drawer .message-card .card-message p,
.entry-request-drawer .message-card .card-message h6 {
  font-size: 14px;
  line-height: 17px;
  color: #444444;
  margin-bottom: 0;
  word-wrap: break-word;
  max-width: 100%;
}
.entry-request-drawer .message-card .card-message p.nickname {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}
.entry-request-drawer .message-card .card-message p.nickname svg {
  height: 16px;
  line-height: 20px;
}
.entry-request-drawer .message-card .card-date {
  padding: 10px 10px 0 10px;
  place-self: start;
}
.entry-request-drawer .message-card.outgoing .card-date { 
  place-self: end;
}

.entry-request-drawer .message-card .card-date p {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  color: #444444;
  margin: 0;
}
.entry-request-drawer .message-card .attached-container-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 15px;
}
/*
.entry-request-drawer .message-card .attached-container-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 100%;
  overflow-x: scroll;
  margin-top: 10px;
} 
*/

@media (min-width: 993px) {
  .entry-request-drawer .message-card {
    /* padding: 20px 15vw; */
    /* width: 600px; */
    /* margin: 0 auto; */
  }
}
/* Request card styles */

/* ----- Entry Footer ----- */
.entry-request-drawer .entry-footer {
  padding: 10px 20px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #CCCCCC;
  z-index: 2;
  background: #fff;
  position: fixed;
  bottom: 0;
  width: 100%;
}
@media (max-width: 575px) {
  .entry-request-drawer .entry-footer {
    padding: 10px;
  }
}
@media (min-width: 860px) {
  .entry-request-drawer .entry-footer {
    position: absolute;
  }
}
/* Submit messages */
.entry-request-drawer .submit-messages {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: flex-end;
  width: 100%;
  gap: 10px;
}
.entry-request-drawer .submit-messages .ant-form {
  width: 100%;
  padding: 0 !important;
}
.entry-request-drawer .submit-messages .ant-form .ant-form-item {
  margin-bottom: 0;
  transition: all 0.5s ease;
}
.entry-request-drawer .submit-messages .ant-form .ant-form-item.ant-form-item-with-help.ant-form-item-has-error {
  margin-bottom: 5px;
}
.entry-request-drawer .submit-messages .ant-form textarea.ant-input {
  padding: 10px 20px;
  min-height: 35px !important;
  font-size: 16px;
  line-height: 20px;
  color: #444;
  border-radius: 6px;
}
.entry-request-drawer .submit-messages .ant-form .ant-form-item {
  margin-bottom: 0 !important;
}
.entry-request-drawer .submit-messages .ant-btn {
  width: 40px;
  height: 40px;
  min-width: 40px;
  max-width: 40px;
  border: 0;
  box-shadow: none;
  color: var(--primary-color);
}
.entry-request-drawer .submit-messages .hide {
  opacity: 0;
}
/* 
.entry-request-drawer .submit-messages .ant-btn:last-of-type.ant-btn-icon-only > .anticon {
  margin-right: 5px;
  margin-top: 2px;
}
*/

.entry-request-drawer .submit-messages span div span span svg {
  width: 28px;
  height: auto;
}
.entry-request-drawer .submit-messages span svg {
  width: 28px;
  height: auto;
}

/* 
.entry-request-drawer .message-card.submit-messages .submit-btn {
  width: 240px;
  align-self: center;
}
.entry-request-drawer .message-card.submit-messages .submit-btn span {
  font-size: 16px;
  font-weight: 600;
}
*/
/* Submit messages */
/* Termination Message */
.termination-message {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: var(--mariner-color);
  margin: 20px auto;
}
/* Termination Message */
/* ----- Entry Footer ----- */

.entry-request-drawer .attachments-wrapper {
  width: 100%;
  margin-bottom: 10px;
}
.entry-request-drawer .attachments-wrapper > p { 
  color: var(--mariner-color);
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 5px;
}
.attached-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 120px;
  max-width: 100px;
  border-radius: 6px;
  border: 1px solid var(--black-20);
  padding: 5px;
  background-color: #fafafa;
}
.comunication-attached-image.ant-image-preview-operations-wrapper {
  z-index: 5011;
  opacity: 1;
  background: rgba(0,0,0,0.5);
}
.comunication-attached-image .ant-image-preview-mask,
.comunication-attached-image .ant-image-preview-wrap {
  z-index: 5010;
}

.entry-request-drawer .attached-representation-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  gap: 10px;
}
.attached-container div img {
  width: 70px;
  height: 70px;
  border-radius: 10px;
  object-fit: cover;
}
.attached-container a {
  background: none;
  box-shadow: none;
  border: none;
  border-radius: 50px;
}

.attached-container-audio {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 220px;
  border-radius: 30px;
  border: 2px solid var(--primary-color);
  padding: 10px 15px;
  background-color: #fafafa;
  flex-wrap: nowrap;
}
.attached-container-audio audio {
  display: none;
  /* background-color: #fafafa; */
}
.attached-container-audio .ant-btn {
  border: 0;
  box-shadow: none;
  width: 30px;
  height: 30px;
  padding: 0;
}
.attached-container-audio .ant-btn span {
  font-size: 30px;
}
.attached-container-audio .ant-btn-ghost:hover, 
.attached-container-audio .ant-btn-ghost:focus,
.attached-container-audio .ant-btn-ghost:active {
  color: #444 !important; 
}

.attached-container-audio .duration-display {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  grid-gap: 5px;
  gap: 5px;
  align-content: center;
  align-items: center;
}
.attached-container-audio .duration-display .outer-display {
  width: 100%;
  min-width: 80px;
  height: 8px;
  border-radius: 8px;
  background: #ccc;
  overflow: hidden;
}
.attached-container-audio .duration-display .inner-display {
  background: var(--primary-color);
  height: 8px;
}
.attached-container-video {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 120px;
  max-width: 150px;
  border-radius: 6px;
  border: 1px solid var(--black-20);
  padding: 5px;
  background-color: #fafafa;
}

.attached-container-video button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  color: var(--mariner-color);
  padding: 0;
  box-shadow: none;
  height: auto;
  max-width: 140px;
  overflow: hidden;
}
.attached-container-video button span {
  margin: 0 auto !important;
  word-wrap: break-word;
}
.attached-container-video button span svg {
  width: 35px;
  height: 35px;
  color: var(--primary-color);
}

.attached-container-video button span svg path {
  padding: 0px;
}

.attached-container-video a {
  background: none;
  box-shadow: none;
  border: none;
  border-radius: 50px;
}


.three-dots-attached {
  width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: -0px;
  margin-top: -5px;
  margin-left: 5px;
}

.entry-request-drawer .bottom-close-btn {
  display: none;
  margin: 0 auto 30px auto;
}
.entry-request-drawer .bottom-close-btn span {
  font-size: 16px;
  line-height: 20px;
  margin: 0 auto;
}
@media (max-width: 992px) {
  .message-card figure {
    width: 70px;
  }
  .message-card figcaption {
    width: 100%;
    padding-bottom: 0;
  }
  .message-card .buttons-container .ant-btn {
    width: 160px;
  }
  /* 
  .attached-container-audio {
    max-width: 320px;
  } 
  */
  .entry-request-drawer .bottom-close-btn {
    display: block;
  }
}
/* Request card styles */

/* ==========================================================================
  Record audio
  ========================================================================== */
.record-audio-modal .ant-modal {
  width: 95% !important;
  max-width: 360px;
}
.record-audio-modal .ant-modal-content {
  border-radius: 10px;
  background-color: #fff !important;
}
.record-audio-modal .ant-modal-body {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.record-audio-modal .ant-modal-content h1 {
  color: var(--primary-color);
  text-align: center;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 10px;
}
.record-audio-modal .ant-modal-content p {
  color: #444;
}
.record-audio-modal .ant-modal-content .ant-btn {
  width: 200px;
  height: 40px;
  margin: 0 auto;
  display: block;
}
.record-audio-modal .ant-modal-content .ant-btn span {
  font-size: 16px;
  font-weight: 600;
}
/* /record audio */




/*----- attachments-popover -----*/
.attachments-popover {
  z-index: 2000;
}
.attachments-popover .ant-popover-inner-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 10px;
}
.attachments-popover .ant-popover-inner {
  padding: 20px;
  overflow: hidden;
  border-radius: 10px;
}
.attachments-popover .ant-popover-inner-content span div span span svg {
  width: 28px;
  height: auto;
}
.attachments-popover .ant-popover-inner-content span svg {
  width: 28px;
  height: auto;
}
/*----- attachments-popover -----*/

/* header popovers */
.entry-request-appointment-popover,
.entry-request-vehicle-popover,
.entry-request-options-popover {
  z-index: 5500;
}
.entry-request-appointment-popover .ant-popover-content .ant-popover-inner,
.entry-request-vehicle-popover .ant-popover-content .ant-popover-inner,
.entry-request-options-popover .ant-popover-content .ant-popover-inner {
  background: var(--light-blue);
  width: 180px;
  /* color: #fff; */
  border-radius: 10px;
  padding: 10px 20px;
}
.entry-request-appointment-popover .ant-popover-content .ant-popover-inner p,
.entry-request-vehicle-popover .ant-popover-content .ant-popover-inner p,
.entry-request-options-popover .ant-popover-content .ant-popover-inner p {
  color: #fff;
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 16px;
}
.entry-request-appointment-popover .ant-popover-content .ant-popover-inner p.title,
.entry-request-vehicle-popover .ant-popover-content .ant-popover-inner p.title,
.entry-request-vehicle-popover .ant-popover-content .ant-popover-inner p.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: var(--mariner-color);
  text-align: center;
}
.entry-request-appointment-popover .ant-popover-arrow-content,
.entry-request-vehicle-popover .ant-popover-arrow-content,
.entry-request-options-popover .ant-popover-arrow-content {
  display: none;
}
.entry-request-appointment-popover .ant-popover-arrow-content::before,
.entry-request-vehicle-popover .ant-popover-arrow-content::before,
.entry-request-options-popover .ant-popover-arrow-content::before {
  background: #fff;
}

/* ==========================================================================
                          Blue light Popover
  ========================================================================== */
.entry-request-appointment-popover,
.entry-request-vehicle-popover,
.entry-request-quotation-popover {
  z-index: 2000;
  max-width: 250px;
}
.entry-request-appointment-popover .ant-popover-content .ant-popover-inner,
.entry-request-vehicle-popover .ant-popover-content .ant-popover-inner,
.entry-request-quotation-popover .ant-popover-content .ant-popover-inner {
  background: var(--light-blue);
  color: #444;
  border-radius: 10px;
  padding: 10px 20px;
}
.entry-request-appointment-popover .ant-popover-content .ant-popover-inner p,
.entry-request-vehicle-popover .ant-popover-content .ant-popover-inner p,
.entry-request-quotation-popover .ant-popover-content .ant-popover-inner p {
  color: #444;
  margin-bottom: 4px;
  font-size: 16px;
  line-height: 20px;
}
.entry-request-appointment-popover .ant-popover-content .ant-popover-inner p > b,
.entry-request-vehicle-popover .ant-popover-content .ant-popover-inner p > b,
.entry-request-quotation-popover .ant-popover-content .ant-popover-inner p > b {
  font-weight: 600;
}
.entry-request-appointment-popover .ant-popover-content .ant-popover-inner p span,
.entry-request-vehicle-popover .ant-popover-content .ant-popover-inner p span,
.entry-request-quotation-popover .ant-popover-content .ant-popover-inner p span {
  color: var(--primary-color);
  font-weight: 600;
}
.entry-request-appointment-popover .ant-popover-content .ant-popover-inner p.title,
.entry-request-vehicle-popover .ant-popover-content .ant-popover-inner p.title,
.entry-request-quotation-popover .ant-popover-content .ant-popover-inner p.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
}
.entry-request-appointment-popover .ant-popover-arrow-content::before,
.entry-request-vehicle-popover .ant-popover-arrow-content::before,
.entry-request-quotation-popover .ant-popover-arrow-content::before {
  background: var(--light-blue);
}
/* ==========================================================================
                          Blue light Popover
  ========================================================================== */

/* ==========================================================================
                          Quotation Banner
  ========================================================================== */
.entry-request-drawer .messages-scroll .quotation-message {
  padding: 20px;
  background: var(--light-blue);
  margin-bottom: 10px;
}
.entry-request-drawer .messages-scroll .quotation-message p {
  color: #444;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 10px;
}
.entry-request-drawer .messages-scroll .quotation-message p > b {
  font-weight: 600;
}
.entry-request-drawer .messages-scroll .quotation-message p > span {
  color: var(--mariner-color);
  font-weight: 600;
}
/* ==========================================================================
                          Quotation Banner
  ========================================================================== */

.entry-request-options-popover .ant-popover-inner-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
}
.entry-request-options-popover .ant-popover-inner-content .ant-btn {
  display: block;
  width: 100%;
  padding: 5px 10px;
  border: 0;
  text-align: left;
  height: 35px;
  transition: all 0.5s ease;
}
.entry-request-options-popover .ant-popover-inner-content .ant-btn span {
  font-weight: 600;
}
/* header popovers */

/* Sending message animation */
.entry-request-drawer .message-card.skeleton .message-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  align-content: flex-end;  
}
.entry-request-drawer .message-card.skeleton .card-message {
  height: 38px;
  width: 200px;
}
.entry-request-drawer .message-card.skeleton .card-message > div {
  width: 10px;
}
.dot-flashing {
  margin: 3px auto;
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #000;
  color: #000;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before, .dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #333333;
  color: #333333;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #333333;
  color: #333333;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #333333;
  }
  50%, 100% {
    background-color: rgba(51, 51, 51, 0.2);
  }
}
/* /Sending message animation */

.confirm-request-answer .ant-modal-content {
  border-radius: 10px;
  overflow: hidden;
}
.confirm-request-answer .ant-modal-header {
  background: #fff !important;
}
.confirm-request-answer .ant-modal-body {
  background: #fff;
}

.report-user-modal .ant-modal-body {
  text-align: center;
}
.report-user-modal .ant-modal-body .ant-form { 
  padding: 0 !important;
}

.report-user-modal .ant-modal-body > .anticon {
  margin: 0 auto;
  display: block;
  width: 30px;
  height: 30px;
  color: var(--secondary-dark-color);
}
.report-user-modal .ant-modal-body > .anticon svg {
  width: 30px;
  height: auto;
}
.report-user-modal .ant-modal-body .ant-form-item-explain { 
  text-align: left;
  color: #444;
  font-weight: 600;
}
.report-user-modal .ant-modal-body p {
  margin-bottom: 10px !important;
}
.report-user-modal .ant-modal-body .wheel-loader {
  background: rgba(0,0,0, 0.3);
}

.confirm-request-answer .ant-modal-title,
.report-user-modal .ant-modal-title { 
  font-weight: 600;
  color: var(--primary-color);
  font-size: 24px;
  text-align: center;
  padding-top: 20px;
  font-family: 'EMPrint';
}
.confirm-request-answer .ant-modal-footer,
.report-user-modal .ant-modal-footer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding-bottom: 30px;
  border: 0;
  gap: 10px;
}
.confirm-request-answer .ant-modal-footer .ant-btn,
.report-user-modal .ant-modal-footer .ant-btn {
  width: 240px;
  height: 40px !important;
}
.confirm-request-answer .ant-modal-footer .ant-btn span,
.report-user-modal .ant-modal-footer .ant-btn span {
  font-size: 16px;
  font-weight: 600;
  font-family: 'EMPrint';
}

.confirm-request-answer .ant-modal-footer .ant-btn:first-child,
.report-user-modal .ant-modal-footer .ant-btn:first-child {
  border: 2px solid var(--primary-color) !important;
}
.confirm-request-answer .ant-modal-footer .ant-btn:first-child span,
.report-user-modal .ant-modal-footer .ant-btn:first-child span {
  color: var(--primary-color);
  text-decoration: none !important; 
}

@media (max-width: 576px) {
  .confirm-request-answer .ant-modal-footer .ant-btn,
  .report-user-modal .ant-modal-footer .ant-btn {
    width: 150px;
    height: 40px !important;
  }
}
