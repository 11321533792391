
/*=============================================================
=            Main Dashboard Graph Appointment Bars            =
=============================================================*/
.md-graph-appointment-bars {
  
}
@media (min-width: 1200px) {
  .md-graph-appointment-bars {
    padding-top: 32px;
    padding-left: 20px;
  }
}

/*=====  End of Main Dashboard Graph Appointment Bars  ======*/

