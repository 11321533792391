.notification-bell .ant-btn {
  border: none;
  box-shadow: none;
}
.notification-bell .ant-btn span {
  color: var(--primary-color);
}
.notification-bell sup {
  background-color: var(--mariner-color);
  margin-top: 7px;
  right: 3px;
}
@media (max-width: 1200px) {
  .notification-bell sup {
    margin-top: 8px;
    right: 6px;
  }
}

.notification-mobile .notif-header,
.notification-responsive-modal .notif-header {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;

  padding: 15px;

  /* height: 40px; */
  width: 100%;
  background-color: white;
  border-radius: 10px;
}
.notification-mobile .anticon,
.notification-responsive-modal .anticon {
  z-index: 2 !important;
}
.notification-mobile .notif-text-header,
.notification-responsive-modal .notif-text-header {
  font-size: 24px;
  font-family: "EMprint-semibold";
  color: #444444;
  margin: 0px;

  text-align: center;
  margin-top: -25px;

  width: 100%;
}
.notification-mobile .notif-options-header,
.notification-responsive-modal .notif-options-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
}
.notification-mobile .notif-options-header .ant-btn,
.notification-responsive-modal .notif-options-header .ant-btn {
  font-size: 16px !important;
  font-family: "EMprint-semibold";
  text-decoration: underline;
  color: var(--primary-color);
  border: none;
  box-shadow: none !important;
}
.notification-mobile .notif-options-header .ant-btn .anticon,
.notification-responsive-modal .notif-options-header .ant-btn .anticon {
  font-size: 16px !important;
}
.notification-mobile .notif-options-header .ant-btn span,
.notification-responsive-modal .notif-options-header .ant-btn span {
  font-size: 16px !important;
  text-decoration: underline;
  margin-left: 2.5px;
}

.notification-mobile
  .ant-drawer-content-wrapper
  .ant-drawer-content
  .ant-drawer-wrapper-body
  .ant-drawer-body {
  display: flex;
  flex-direction: column;
  padding: 0px !important;
}
.notification-mobile
  .ant-drawer-content-wrapper
  .ant-drawer-content
  .ant-drawer-wrapper-body
  .ant-drawer-body
  .scroll-modal {
  flex: 1;
  overflow-y: scroll;
  scroll-margin: 0px !important;
  scroll-padding: 0px !important;
}
.notification-mobile .scroll-modal,
.notification-responsive-modal .scroll-modal {
  padding: 0px !important;
  background-color: white;
}
.notification-mobile .scroll-modal .ant-row,
.notification-responsive-modal .scroll-modal .ant-row {
  /* row-gap: 10px !important; */
  row-gap: 0px !important;
}
.notification-mobile .ant-divider-horizontal,
.notification-responsive-modal .ant-divider-horizontal {
  margin: 0px !important;
  background-color: #eaeffe;
}
.notification-mobile
  .ant-drawer-content-wrapper
  .ant-drawer-content
  .ant-drawer-wrapper-body
  .ant-drawer-body
  .options-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80px;
  border-radius: 10px;
  background-color: white;
}
.notification-mobile
  .ant-drawer-content-wrapper
  .ant-drawer-content
  .ant-drawer-wrapper-body
  .ant-drawer-body
  .options-modal
  .ant-btn-default {
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
}
.notification-mobile
  .ant-drawer-content-wrapper
  .ant-drawer-content
  .ant-drawer-wrapper-body
  .ant-drawer-body
  .options-modal
  .ant-btn-default
  span {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #2f66f2 !important;
  text-decoration: underline !important;
}
.notification-responsive-modal .ant-modal-content {
  border-radius: 10px !important;
}
.notification-responsive-modal
  .ant-modal-content
  .ant-modal-close
  span
  span
  svg {
  display: none;
  margin-top: 50px;
}
.notification-responsive-modal
  .ant-modal-content
  .ant-modal-body
  .scroll-modal {
  height: 520px;
  width: 100%;
  overflow-y: scroll;
  scroll-margin: 0px !important;
  scroll-padding: 0px !important;
}
.notification-responsive-modal
  .ant-modal-content
  .ant-modal-body
  .options-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80px;
  border-radius: 10px;
  background-color: white;
}
.notification-responsive-modal
  .ant-modal-content
  .ant-modal-body
  .options-modal
  .ant-btn-default {
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
}
.notification-responsive-modal
  .ant-modal-content
  .ant-modal-body
  .options-modal
  .ant-btn-default
  span {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #2f66f2 !important;
  text-decoration: underline !important;
}
@media (max-width: 992px) {
  .notification-mobile .notif-options-header .ant-btn .anticon,
  .notification-responsive-modal .notif-options-header .ant-btn .anticon {
    font-size: 14px !important;
  }
  .notification-mobile .notif-options-header .ant-btn span,
  .notification-responsive-modal .notif-options-header .ant-btn span {
    font-size: 14px !important;
  }
}
