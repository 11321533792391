.inventory-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Standard syntax */
}
.inventory-container .inv-sub-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  margin: -10px 0px 20px 0px !important;
}
.inventory-container .inv-sub-container.is-comment {
  /* margin: -10px 0px 20px 0px; */
}
.inventory-container .bold {
  font-size: 16px;
  font-family: "EMprint-Semibold";
  color: #444444;
}
.inventory-container .bold span {
  font-family: "EMprint-Regular" !important;
  font-weight: 400 !important;
}
.inventory-container .regular {
  font-size: 16px;
  font-family: "EMprint-Regular";
  color: #444444;
}
.inventory-container .ant-checkbox-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.inventory-container .ant-checkbox-wrapper span {
  font-size: 14px;
  /* line-height: 18px; */
  font-weight: 400;
}
.inventory-container .special-blue {
  cursor: pointer;
  /* margin-bottom: 20px; */
  color: var(--primary-color);
}
.inventory-container .special-blue span {
  font-family: "EMprint-Semibold";
}
.inventory-container .ant-checkbox {
  margin-top: -5px;
}
.inventory-container .ant-form-item-extra {
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #444444;
}
.inventory-container textarea {
  padding: 0px;
  border: 0px solid #444444 !important;
  border-bottom: 1px solid #444444 !important;

  width: 50%;

  font-size: 16px !important;

  box-shadow: none !important;
}
.inventory-container textarea::placeholder {
  font-size: 16px !important;
  color: #cccccc !important;
}

@media (max-width: 992px) {
  .inventory-container .ant-form-item {
    width: 100%;
  }
  .inventory-container textarea {
    width: 100% !important;
  }
}
