.services-resume {
  margin-bottom: 15px;
}
.services-resume .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.services-resume .header p {
  font-weight: 600;
  margin-bottom: 0;
  line-height: 20px;
  font-size: 16px;
}
.services-resume .header p span {
  background: var(--light-blue);
  color: var(--mariner-color);
  display: inline-block;
  padding: 3px 10px;
  border-radius: 18px;
  line-height: 14px;
  text-align: center;
}
.services-resume .body {
  background-color: #F9F9F9;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 15px 10px 5px 10px;
}

.services-resume .body .service-chip {
  margin-bottom: 10px;
  width: 250px;
  gap: 5px;
  position: relative;
}
.services-resume .body .service-chip .ck-icon {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 25px;
}

.services-resume .body .service-chip p {
  font-size: 14px;
  line-height: 30px;
  margin: 0;
  padding-left: 30px;
}
