.zindex-5002 {
  z-index: 5002;
}
.quotation-header {
  padding: 0;
  margin-bottom: 40px;
}
.quotation-header .drawer-title {
  color: var(--primary-color);
  font-size: 48px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 10px;
}
.quotation-header .drawer-instructions {
  font-size: 16px;
  line-height: 1.5;
}

.quotation-header .field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 10px;
}
.quotation-header .field .description {
  font-size: 13px;
  font-weight: 700;
  margin: 0px 0px 5px 2px;
  color: var(--primary-color);
}
.quotation-header .field .description-gray {
  font-size: 13px !important;
  font-weight: 700;
  margin: 0px 0px 5px 2px;
  color: #a8a8a8;
}
.quotation-header .field .picker-size {
  height: 53px;
  width: 95%;
  max-width: 320px;
  border-radius: 10px;
  /* border: solid 2px #dbdbdb; */
}
.quotation-header .field .picker-size.ant-picker.ant-picker-disabled,
.quotation-header .field .picker-size.ant-input-affix-wrapper.ant-input-affix-wrapper-disabled {
  border: solid 2px #dbdbdb !important;
  background: #fff;
}
.quotation-header .field .picker-size.ant-input-affix-wrapper.ant-input-affix-wrapper-disabled input {
  color: #000;
  font-size: 20px !important;
}
.quotation-header .field .ant-select-selector {
  height: 53px;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}
.quotation-header
  .field
  .ant-select-disabled.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  border: solid 2px #dbdbdb !important;
  background: #fff !important;
  color: #000;
}
.quotation-header .field .ant-picker .ant-picker-input input {
  font-size: 20px !important;
}
.quotation-header
  .field
  .ant-select
  .ant-select-selector
  .ant-select-selection-item {
  font-size: 20px !important;
}
/* 
.quotation-header .field .ant-picker:hover,
.quotation-header .field .ant-picker-focused {
  border-color: var(--primary-color-rgb);
  box-shadow: 0 0 0 4px rgb(var(--primary-color-rgb), 0.3);
} 
*/
.quotation-header .field .orange-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--mariner-color);

  height: 53px;
  width: 100%;
  max-width: 320px;
  border-radius: 10px;
}
.quotation-header .field .orange-container p {
  font-size: 22px;
  color: white;
  font-weight: 700;
  margin: 0px;
  text-align: center;
}
@media (min-width: 993px) and (max-width: 1200px) {
  .quotation-header .field .orange-container p {
    font-size: 22px;
  }
}

@media (max-width: 575px) {
  .quotation-header .drawer-title {
    font-size: 22px;
  }
  .quotation-header .drawer-instructions {
    font-size: 13px;
    line-height: 1.38;
  }
}

/* ---- Vehicle Card ---- */
.quotation-header .vehicle-card,
.quotation-header .user-card {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  flex-wrap: nowrap;
  gap: 10px;
}
.quotation-header .user-card-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  align-content: center;
}
@media (max-width: 992px) {
  .quotation-header .user-card-container {
    display: none;
  }
}
.quotation-header .vehicle-card .ant-image,
.quotation-header .user-card .ant-image {
  width: 46px !important;
  height: 46px !important;
  object-fit: cover;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
}
.quotation-header .vehicle-card .info,
.quotation-header .user-card .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.quotation-header .vehicle-card .info p,
.quotation-header .user-card .info p {
  font-size: 14px;
  line-height: 1.14;
  margin: 2.5px 0px;
}
.quotation-header .vehicle-card .info .semibold,
.quotation-header .user-card .info .semibold {
  font-weight: 600;
}
@media (max-width: 992px) {
  .quotation-header .vehicle-card .info p,
  .quotation-header .user-card .info p {
    font-size: 13px;
    line-height: 16px;
    margin: 2.5px 0px;
  }
}
/* ---- /Vehicle Card ---- */

.quotation-header h5 {
  margin-top: 15px;
}

.quotation-header .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 70px;
}

.quotation-header .header .date-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

@media only screen and (max-width: 992px) {
  .quotation-header .header {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .quotation-header .header .date-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 20px;
  }

  .quotation-header .header .date-container .field {
    width: 310px;
  }

  .quotation-header .header .date-container .field .gray {
    color: #2f66f2;
  }

  .quotation-header .header .date-container .field .picker-size {
    height: 40px;
    width: 100%;
  }

  .quotation-header .header .date-container .field .orange-container {
    height: 40px;
    width: 320px;
  }

  .quotation-header-form .mobile {
    display: block;
  }

  .quotation-header-form .web {
    display: none;
  }

  .quotation-header .header .field-mob {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
  }

  .quotation-header .header .field-mob .gray {
    font-size: 13px;
    font-weight: 700;
    margin: 0px 0px 5px 2px;
    color: #2f66f2;
  }

  .quotation-header .header .field-mob .orange-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f05822;
    height: 40px;
    width: 310px;
    border-radius: 10px;
  }

  .quotation-header .header .field-mob .orange-container p {
    /* font-size: 22px; */
    color: white;
    font-weight: 700;
    margin: 0px;
    text-align: center;
  }

  .quotation-header .header {
    margin-top: 20px;
    gap: 20px;
  }
  .quotation-header .field .description {
    font-size: 14px;
  }
  .quotation-header .field .description-gray {
    font-size: 14px !important;
    color: var(--primary-color);
  }
  .quotation-header .field .ant-picker .ant-picker-input input {
    font-size: 13px !important;
  }
  .quotation-header
    .field
    .ant-select
    .ant-select-selector
    .ant-select-selection-item {
    font-size: 13px !important;
  }
}
