.add-workshop-services.ant-drawer-open {
  z-index: 3100;
}
@media (min-width: 992px) {
  .add-workshop-services.ant-drawer-open .ant-drawer-content-wrapper {
    width: calc(100vw - 61px) !important;
    transform: translateX(0px) !important;
  }
  .expanded-menu.add-workshop-services.ant-drawer-open
    .ant-drawer-content-wrapper {
    width: calc(100vw - 300px) !important;
    transform: translateX(0px) !important;
  }
}
@media (max-width: 992px) {
  .add-workshop-services.ant-drawer-open .ant-drawer-content-wrapper {
    width: calc(100vw) !important;
    top: 0px;
  }
}
.add-workshop-services .ant-drawer-header {
  display: none;
}
.add-workshop-services .ant-drawer-body {
  padding: 25px 0;
}
.add-workshop-services {
}

.add-workshop-services .content-wrapper {
  width: 90%;
  margin: 0 auto;
}

.add-workshop-services .content-wrapper h2 {
  font-weight: 600;
  color: var(--primary-color);
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 20px;
}
.add-workshop-services .content-wrapper .content-header h2 {
  margin-bottom: 0 !important;
}
.add-workshop-services .content-header {
  display: flex;
  border-bottom: 1px solid var(--gray-200);
  align-items: center;
  flex-direction: row;
  gap: 10px;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.add-workshop-services .content-header .ck-button {
  padding: 0 !important;
  width: 20px;
  height: 20px;
  max-width: 20px;
  min-width: 20px;
  margin-left: -30px;
}
.add-workshop-services .content-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
@media (min-width: 992px) {
  .add-workshop-services .content-wrapper h2.responsive-title {
    display: none;
  }
}
@media (max-width: 991px) {
  .add-workshop-services .content-wrapper h2:not(.responsive-title) {
    display: none !important;
  }
  .add-workshop-services .content-wrapper .content-header .ck-button {
    margin: 0;
  }
}
