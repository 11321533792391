.warranty-preview-container {
  display: flex;
  flex-direction: column;
  width: 864px;
}

.warranty-preview-container .preview-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.warranty-preview-container .preview-header .preview-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: 600;
  color: var(--primary-color);
}

.warranty-preview-container .preview-header .preview-see-more {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: 600;
  color: var(--primary-color);
  cursor: pointer;
}

.warranty-preview-container .preview-body {
  display: flex;
  flex-direction: column;
  gap: 15px;
  font-size: 14px;
  font-weight: 600;
  color: var(--primary-color);
  margin-top: 5px;
}

.warranty-preview-container .preview-body .preview-section {
  display: flex;
  flex-direction: column;
}

.warranty-preview-container .preview-warning {
  margin-top: 10px;
}

.warranty-preview-container .preview-body .preview-section .preview-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #444;
  font-size: 14px;
  font-weight: 400;
}

.warranty-preview-container .preview-body .preview-section .preview-content .o {
  width: 35px;
  text-align: center;
}

.warranty-preview-container
  .preview-body
  .preview-section
  .preview-content
  .preview-content-card {
  width: 100%;
  background: var(--light-blue);
  color: #444;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.warranty-preview-container
  .preview-body
  .preview-section
  .preview-content
  .preview-content-card
  .preview-content-info {
  color: var(--mariner-color);
  font-size: 14px;
  font-weight: 600;
  padding: 0px !important;
}
