.not-found-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 10%;
  gap: 40px;
}
.not-found-page h1 {
  color: var(--primary-color);
  text-align: center;
  font-family: var(--font-family);
  font-size: 40px;
  line-height: 48px;
  font-weight: 600;
  margin: 0 auto;
}
.not-found-page img {
  width: 90%;
  max-width: 480px;
  height: auto;
}
.not-found-page .ant-btn span {
  font-size: 16px;
  font-weight: 600;
}
@media (max-width: 768px) {
  .not-found-page h1 {
    font-size: 30px;
    line-height: 38px; 
  }
  .not-found-page img {
    max-width: 360px;
  }
}

@media (max-width: 576px) {
  .not-found-page h1 {
    font-family: 24px;
    line-height: 30px;
  }
  .not-found-page img {
    max-width: 240px;
  }
}

