.filter-item {
    border: 1px solid #CCCCCC;
    display: flex;
    flex-direction: row;
    height: 30px;
    justify-content: center;
    align-content: center;
    width: fit-content;
    margin: 5px;
    border-radius: 6px;
    padding: 3px 10px 0 10px;
}
.filter-item.selected {
    background: #EFF6FF;
    border: 1px solid #EFF6FF;
}