.approval-modal-container {
  width: 95% !important;
  max-width: 720px;
  margin: 0 auto;
}
@media (min-width: 992px) {
}
.approval-modal-container .ant-modal-content {
  border-radius: 10px;
  background-color: #fff !important;
}
.approval-modal-container .ant-modal-body {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 10px;
  margin: 0 auto;
  text-align: center;
  padding-top: 35px;
  max-width: 590px;
}
.approval-modal-container .ant-modal-body .ant-spin-spinning {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: rgba(255,255,255, 0.5);
  z-index: 500;
  border-radius: 10px;
}
.approval-modal-container .ant-modal-content h1 {
  text-align: center;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  margin: 0 auto 10px auto;
  letter-spacing: unset;  
  color: var(--primary-color);
}
.approval-modal-container .ant-modal-content p {
  color: #444;
  margin-bottom: 0;
  text-align: center;
}
@media (max-width: 576px) {
  .approval-modal-container .ant-modal-content h1 {
    max-width: 200px;
  }
  .approval-modal-container .ant-modal-content p {
    text-align: left;
  }
}
.approval-modal-container .ant-modal-content .message {
  color: #444;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 0;
}
.approval-modal-container .ant-modal-content .submessage {
  color: #444;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0;
}
.approval-modal-container .ant-modal-close {
  top: 5px;
  right: 5px;
}
.approval-modal-container .ant-modal-close-x {
  width: 40px;
  height: 40px;
}
.approval-modal-container .ant-modal-close-x .anticon {
  width: 20px;
}
.approval-modal-container .ant-modal-content p {
  color: #444;
}

/* Buttons container */
.approval-modal-container .ant-modal-content .buttons-container .ant-btn {
  width: 240px;
  height: 40px;
  margin: 0 auto;
  display: block;
}
.approval-modal-container .ant-modal-content .buttons-container .ant-btn span {
  font-size: 16px;
  font-weight: 600;
}
.approval-modal-container .ant-modal-content .buttons-container {
  padding-top: 10px;
}

/* Text area input */
.approval-modal-container .text-area {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
}
.approval-modal-container .text-area .label {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}


/* Upload file container */
.approval-modal-container .upload-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.approval-modal-container .upload-container .ant-upload {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  color: var(--primary-color);
  gap: 5px;
} 
.approval-modal-container .upload-container .ant-upload-select-text {
  margin-bottom: 5px;
}
.approval-modal-container .upload-container .ant-upload .anticon {
  font-size: 20px;
}
.approval-modal-container .upload-container .ant-upload p {
  color: var(--primary-color);
  font-size: 16px;
  font-weight: 600;
  text-decoration-line: underline;
}
