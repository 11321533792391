.add-client-drawer .ant-drawer-header {
  border-bottom: none !important;
  margin: 0 25px 0 25px;
  padding: 36px 24px 16px 24px;
}

.add-client-drawer .divider-title {
  border-top: 1px solid #2369bd;
  margin: 12px 0;
}

.tag-card {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;

  border-width: 0px;
  margin: 0px;

  font-weight: 400;
  color: var(--text-color);

  height: 40px;
}
