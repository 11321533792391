.services-container .title {
  color: var(--primary-color);
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
}

.services-container p {
  margin-bottom: 30px !important;
  font-size: 14px;
  color: #444;
}

.services-container {
  transition: all 0.5s ease;
}

.services-container.ant-drawer-open {
  z-index: 3100;
}
@media (min-width: 992px) {
  .services-container.ant-drawer-open .ant-drawer-content-wrapper {
    width: calc(100vw - 61px) !important;
    transform: translateX(0px) !important;
  }
  .expanded-menu.services-container.ant-drawer-open .ant-drawer-content-wrapper {
    width: calc(100vw - 300px) !important;
    transform: translateX(0px) !important;
  }
}

.services-container .actions-container .cancel-style {
  color: var(--primary-color);
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}

.services-container .actions-container .button-style {
  height: 40px;
  font-weight: 600;
  min-width: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.services-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
  width: fit-content;
}

.services-list .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin: 0px;
}

.services-list .ant-checkbox-wrapper {
  font-size: 16px;
}

@media (max-width: 992px) {
  .services-container.ant-drawer-open .ant-drawer-content-wrapper {
    width: calc(100vw) !important;
    top: 65px;
  }
  .services-container .actions-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 35px;
    margin-top: 40px;
  }
}

@media (min-width: 992px) {
  .services-container .actions-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 159px;
    margin-top: 20px;
    padding-right: 120px;
  }
}
