.import-client-list-modal .wheel-loader.cover-container {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  overflow: hidden;
}
.import-client-list-modal .attachment-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.import-client-list-modal .attachment-button-container p {
  font-size: 18px;
  font-weight: 600;
  color: var(--primary-color);
  margin-bottom: 5px;
}

.import-client-list-modal .notice {
  background-color: var(--light-blue);
  display: flex;
  flex-direction: row;
  padding: 10px;
  margin-bottom: 15px;
  gap: 10px;
}
.import-client-list-modal .notice .ck-icon {
  color: var(--mariner-color) !important;
  margin-top: 5px;
}
.import-client-list-modal .notice p {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0;
}
.import-client-list-modal li {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 5px;
}

.import-client-list-modal ::marker {
  color: var(--mariner-color);
  font-weight: 600;
}
