
/*======================================================
=            Main Dashboard Income In Lines            =
======================================================*/
.md-graph-income-in-lines {
  border-bottom: 1px dashed #cacaca;
  padding-bottom: 30px;
}
.md-graph-income-in-lines h2 {
  color: var(--text-color);
  font-size: 24px !important;
  line-height: 30px;
  font-weight: 600;
}
/*=====  End of Main Dashboard Income In Lines  ======*/

