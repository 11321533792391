.physical-inspection-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.physical-inspection-container .title {
  font-size: 16px !important;
}
.physical-inspection-container .bold {
  font-size: 14px;
  font-family: "EMprint-Semibold";
  color: #444444;
}
.physical-inspection-container .bold span {
  font-family: "EMprint-Regular" !important;
  font-weight: 400 !important;
}
.physical-inspection-container .regular {
  font-size: 14px;
  font-family: "EMprint-Regular";
  color: #444444;
}
.physical-inspection-container .class-formatType {
  padding-bottom: 15px;
}
.physical-inspection-container .ant-form-item {
  width: 100%;
}
.physical-inspection-container .class-formatType .ant-form-item-extra {
  font-size: 14px;
  font-weight: 400;
  color: #444444;
  margin-bottom: 10px;
}
.physical-inspection-container .class-formatType .ant-radio-group {
  display: flex;
  flex-direction: row;
  gap: 10px;

  width: 100%;
}
.physical-inspection-container .class-formatType .ant-radio-button-wrapper {
  flex: 1;
  display: flex;

  justify-content: center;
  align-items: center;

  border: 1px solid var(--primary-color);

  height: 40px;
  min-height: 40px;

  border-radius: 10px;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Standard syntax */
}
.physical-inspection-container
  .class-formatType
  .ant-radio-button-wrapper
  span {
  font-size: 14px;
  font-weight: 600;
  color: #444444;
}
.physical-inspection-container
  .class-formatType
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  background-color: var(--primary-color) !important;
  box-shadow: none !important;
}
.physical-inspection-container
  .class-formatType
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked
  span {
  color: white;
}
.physical-inspection-container
  .class-formatType
  .ant-radio-button-wrapper::before {
  background-color: transparent;
}
.physical-inspection-container .reception-once-document {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.physical-inspection-container .reception-once-document .ant-image {
  width: 100%;
  height: 100%;
  max-width: 340px;
  max-height: 300px;
}
.physical-inspection-container .upload-document {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.physical-inspection-container .upload-botton {
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 280px;
  height: 37px !important;

  border-radius: 10px;
  border: 2px solid var(--primary-color);

  font-family: "EMprint-Semibold";
  color: var(--primary-color);
}
.physical-inspection-container .upload-botton span {
  font-size: 14px;
}
.physical-inspection-container .upload-botton .anticon {
  display: flex;
  font-size: 22px;
  width: 22px;
  height: 22px;
}
.physical-inspection-container .reception-document-preview {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-height: 300px;
}
.physical-inspection-container .reception-document-preview .ant-image {
  width: 100%;
  height: 100%;
  max-height: 300px;
}
.physical-inspection-container .reception-document-preview img {
  min-width: 100%;
  min-height: 100%;
  max-height: 300px;

  border-radius: 10px;
  object-fit: cover;
}
.physical-inspection-container .reception-document-preview .ant-image-mask {
  border-radius: 10px;
}
.physical-inspection-container .reception-document-preview .trashico-white {
  z-index: 1;
  display: flex;
  flex-direction: row;

  padding: 5px;
  margin-left: -31px;
  border-radius: 8px;

  background-color: rgba(255, 255, 255, 0.851);
  border: 0.5px solid #444444;
}
.physical-inspection-container .document-view-pdf {
  height: 300px !important;
}
.physical-inspection-container .class-formatDoc {
  padding-bottom: 15px;
}
.physical-inspection-container .class-formatDoc.resize {
  max-height: 1px;
  padding: 0px;
}
.physical-inspection-container .example-board {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;

  width: 100%;
}
.physical-inspection-container .example-board p {
  margin: 0px;
}
.physical-inspection-container .class-formatCarkerDoc {
  overflow-x: auto;

  margin-top: 20px;
  width: 100%;
}
.physical-inspection-container .class-formatCarkerDoc .upload-botton {
  min-width: 225px;
}
.physical-inspection-container .reception-multiple-document {
  display: flex;
  flex-direction: row;
  align-self: center;
  margin-top: 10px;
  gap: 10px;

  border-radius: 10px;

  max-height: 120px;
  max-width: 100%;

  overflow-x: auto;

  scrollbar-width: thin;
  scrollbar-color: #44444493 #44444411;
}
.physical-inspection-container .reception-multiple-document::-webkit-scrollbar {
  display: none !important;
  height: 8px;
}
.physical-inspection-container
  .reception-multiple-document::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: transparent;
}
.physical-inspection-container
  .reception-multiple-document::-webkit-scrollbar-track {
  border-radius: 6px;
  background-color: transparent;
}
.physical-inspection-container
  .reception-multiple-document:hover::-webkit-scrollbar-thumb {
  background-color: #4444442a;
}
.physical-inspection-container
  .reception-multiple-document:hover::-webkit-scrollbar-track {
  background-color: #44444411;
}
.physical-inspection-container
  .reception-multiple-document
  .reception-document-preview {
  margin: 0px;
  height: 80px !important;
  width: 180px !important;
  min-width: 180px !important;
}
.physical-inspection-container
  .reception-multiple-document
  .reception-document-preview
  .ant-image {
  width: 100%;
  height: 100%;
  max-height: 300px;
}
.physical-inspection-container
  .reception-multiple-document
  .reception-document-preview
  .ant-image
  img {
  object-fit: cover !important;
}
.physical-inspection-container .class-field input {
  font-size: 16px !important;
}
.physical-inspection-container .class-field input::placeholder {
  font-size: 16px !important;
}
.physical-inspection-container .class-field .ant-form-item-extra {
  font-size: 14px;
  font-family: "EMprint-Semibold";
  color: #444444;
}
.physical-inspection-container .class-field .ant-input-group-addon {
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #444444;
}
.physical-inspection-container .class-field .ant-input-group-addon p {
  font-size: 16px;
  font-family: "EMprint-Semibold";
  color: #444444;
  margin: 0px;
}
/* FUEL */
.physical-inspection-container
  .class-field.read-only
  .fuel-container
  .fuel-background {
  background-color: #4444441a !important;
}
.physical-inspection-container .fuel-container .fuel-background {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--mariner-color);
  height: 70px !important;
}
.physical-inspection-container .fuel-container .fuel-lines {
  margin-top: -70px !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  height: 70px !important;
}
.physical-inspection-container .fuel-container .fuel-lines .divider-father {
  margin: 0px !important;
  height: 70px !important;
  border-color: black !important;
}
.physical-inspection-container .fuel-container .fuel-lines .divider-son {
  margin: 0px !important;
  height: 50px !important;
  border-color: black !important;
}
.physical-inspection-container .fuel-container .fuel-slider {
  margin-top: -65px !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* background-color: red !important; */
  height: 70px !important;
}
.physical-inspection-container .fuel-container .fuel-slider .ant-slider-rail {
  display: none;
  background-color: #96b2f8;
}
.physical-inspection-container .fuel-container .fuel-slider .ant-slider-track {
  display: none;
  background-color: #96b2f8;
}
.physical-inspection-container
  .fuel-container
  .fuel-slider
  .ant-slider-step
  .ant-slider-dot {
  display: none;
}
.physical-inspection-container .fuel-container .fuel-slider .ant-tooltip-open {
  display: none !important;
}
.physical-inspection-container .fuel-container .fuel-slider .ant-slider-mark {
  z-index: 1;
  font-size: 12px;
  font-family: "EMprint-Semibold";
  margin-top: 60px !important;
}
/* END FUEL */

@media (max-width: 992px) {
  .physical-inspection-container .regular {
    font-size: 14px;
  }
  .physical-inspection-container .class-formatType .ant-radio-group {
    flex-direction: column;
  }
  .physical-inspection-container .class-formatType .ant-form-item-extra {
    font-size: 14px;
  }
  .physical-inspection-container
    .class-formatType
    .ant-radio-button-wrapper
    span {
    font-size: 14px;
  }
  .physical-inspection-container .upload-botton {
    max-width: 240px;
  }
  .physical-inspection-container .upload-botton span {
    font-size: 14px;
  }
  .physical-inspection-container .example-board {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .physical-inspection-container .example-board p {
    width: 100%;
  }
}
