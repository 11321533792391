
.banner-main-title {
    font-weight: 600;
    font-size: 32px;
    color: #FFFFFF;
    text-align: center;
}
.banner-main-subtitle {
    font-weight: 400;
    font-size: 24px;
    color: #FFFFFF;
    text-align: center;
}
.banner-main-signature {
    font-weight: 600;
    font-size: 32px;
    color: #FFFFFF;
    text-align: center;
}
.banner-commitment-icon-container {
    display: flex;
    flex-direction: column;
    width: 97px;
}
.banner-commitment-icon {
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    opacity: 0.8;
}
@media (max-width: 992px) {
    .landing-page-banner-icon svg {
        width: 70px;
        height: auto;
    }
    .bottom-icon-container {
        align-content: flex-end;
    }
    .banner-main-title {
        font-size: 20px;
    }
    .banner-main-subtitle {
        font-size: 16px;
    }
    .banner-main-signature {
        font-size: 32px;
    }
    .banner-commitment-icon-container {
        justify-items: center;
        align-items: center;
    }
    .banner-commitment-icon{
        width: 60px;
    }
}
