.ws-profile-container
  .ant-input:not(#workshop_setup_about, #workshop_setup_warranty) {
  border: none !important;
  border-bottom: 1px solid #444444 !important;
  border-radius: 0 !important;
  padding: 0 !important;
  font-size: 16px;
}

.workshop-identificator-form-item {
  margin-bottom: 0;
}

.ws-profile-container .ant-input-number {
  border: none !important;
  border-radius: 0px !important;
  padding: 0px !important;
}

.ws-profile-container .ant-input-number-input {
  border: none !important;
  border-bottom: 1px solid #444444 !important;
  border-radius: 0px !important;
  padding: 0px !important;
}

.ws-profile-container .ant-input-affix-wrapper {
  border: none !important;
  border-radius: 0px !important;
  padding: 0px !important;
}

.ws-profile-container .ant-input-suffix {
  border: none !important;
  border-bottom: 1px solid #444444 !important;
  border-radius: 0px !important;
  padding: 0px 0px 0px 5px !important;
  margin: 0px !important;
}

.ws-profile-container .ant-picker {
  border: none !important;
  border-bottom: 1px solid #444444 !important;
  border-radius: 0px !important;
  padding: 0px !important;
}

.ws-profile-container .ant-picker .ant-picker-suffix {
  color: #444444 !important;
}

.ws-profile-container .ant-picker .ant-picker-clear {
  color: #444444 !important;
}

.ws-profile-container .ant-radio .ant-radio-inner {
  border: 2px solid #2f66f2 !important;
}

.ws-profile-container .ant-form-item-control-input-content,
.ant-form-item-control-input-content input,
.ant-select-selector,
.ant-input-number,
textarea.ant-input,
.ant-input-password {
  border-radius: 0px !important;
}

.ws-profile-container
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 0px !important;
  border: none !important;
  border-bottom: 1px solid #444444 !important;
  padding: 0px !important;
}

.ws-profile-container .ant-form-item-extra {
  color: #444444 !important;
  font-size: 16px;
  line-height: 20px;
  font-family: "Emprint-semibold";
}

.ws-profile-container .ant-upload.ant-upload-drag .ant-upload {
  padding: 0px !important;
}

.ws-profile-container .ant-upload.ant-upload-drag {
  margin: 0 auto;
}

.ws-profile-container .ws-images-flex {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  gap: 20px !important;
  margin-top: 10px;

  width: 100%;
}
.ws-profile-container .ws-images-flex .ws-image-wrapped {
  width: 180px;
  height: 180px;
}

@media (max-width: 992px) {
  .ws-profile-container {
    display: flex;
    flex-direction: column;
  }

  .ws-profile-container .ws-logo-container {
    height: 180px;
    width: 180px;
    margin: auto;
  }

  .ws-profile-container .ws-img-render-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    max-width: 100% !important;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 5px !important;
  }

  .ws-profile-container .back-button {
    padding: 0px !important;
    color: var(--primary-color) !important;
    margin-bottom: 10px;
  }

  .ws-profile-container .back-button span {
    font-size: 16px !important;
    line-height: 16px !important;
    text-decoration: underline;
    font-family: "Emprint-semibold";
    margin: 0px 5px 0px 0px !important;
  }

  .ws-profile-container .actions-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 10px 0px;
  }

  /* .ws-profile-container .empty-img-container {
    width: 165px !important;
    height: 165px !important;
  }

  .ws-profile-container .ant-upload.ant-upload-drag {
    height: 165px !important;
    width: 165px !important;
  } */
}
@media (max-width: 820px) {
  .ws-profile-container .ws-img-render-container {
    max-width: 375px !important;
    gap: 15px !important;
  }
}

@media (max-width: 576px) {
  .ws-profile-container .ws-img-render-container {
    max-width: 100% !important;
    gap: 10px !important;
  }
  .ws-profile-container .ws-images-flex {
    justify-content: center;
    gap: 10px !important;
  }
  .ws-profile-container .ws-images-flex .ws-image-wrapped {
    width: 100px !important;
    height: 100px !important;
  }
  .ws-profile-container
    .ws-images-flex
    .ws-image-wrapped
    .ws-profile-img-component {
    width: 100% !important;
    height: 100% !important;
  }
  .ws-profile-container .ws-images-flex .ws-image-wrapped img {
    width: 100% !important;
    height: 100% !important;
  }
  .ws-profile-container .ws-images-flex .ws-image-wrapped .empty-img-container {
    width: 100px !important;
    height: 100px !important;
  }
  .ws-profile-container .ws-images-flex .ws-image-wrapped .ant-upload {
    width: 100px !important;
    height: 100px !important;
  }
  .ws-profile-container
    .ws-images-flex
    .ws-image-wrapped
    .empty-img-container
    span {
    width: 100px !important;
    height: 100px !important;
  }
  .ws-profile-container
    .ws-images-flex
    .ws-image-wrapped
    .empty-img-container
    div {
    width: 100px !important;
    height: 100px !important;
  }
  .ws-profile-container
    .ws-images-flex
    .ws-image-wrapped
    .empty-img-container
    p {
    font-size: 14px !important;
  }
}
@media (min-width: 576px) and (max-width: 992px) {
  .ws-profile-container .actions-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin: 10px auto;
    max-width: 576px;
  }
  .ws-profile-container .actions-container .ant-btn {
    width: 100%;
  }
}

.ws-profile-container .ant-upload.ant-upload-drag {
  height: 180px;
  width: 180px;
}

.ws-profile-container {
  display: flex;
  flex-direction: column;
}

.ws-profile-container .logo-container {
  height: 180px;
  width: 180px;
}

.ws-profile-container .form-container {
  width: 100%;
}

.ws-profile-container .form-title-ws-profile {
  color: var(--primary-color);
  margin: 0;
  font-size: 24px;
  font-weight: 600;
}

.ws-profile-container .form-button {
  height: 40px;
  font-weight: 600;
  min-width: 200px;
}

.ws-profile-container .ws-img-render-container {
  display: flex;
  gap: 15px;
  margin-top: 10px;
  flex-direction: row;
  flex-wrap: wrap;
}
@media (max-width: 1366px) {
  .ws-profile-container .ws-img-render-container {
    max-width: 400px;
    margin: 10px auto 5px auto;
  }
  /* 
  .ws-profile-container .img-container {
    height: 160px;
    width: 160px;
  }
  */
}

.ws-profile-container .empty-img-container {
  width: 180px;
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  margin: auto;
}

.ws-profile-container .client-link-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;
}
.ws-profile-container .client-link-container .ant-image {
  width: 20px;
}

.ws-profile-container .client-link-text {
  cursor: pointer;
  font-weight: 600;
  color: var(--primary-color);
  font-size: 16px;
  text-decoration-line: underline;
  margin-right: 30px;
}

.ws-profile-container
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 26.5px !important;
}

.ws-profile-container
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 1.5715;
}

.ws-profile-container .logo-space-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.packages-container {
  display: flex;
  justify-content: flex-end;
}

.packages-container .form-button {
  height: 40px;
  font-weight: 600;
  min-width: 200px;
}
/* ==========================================================================
  Gallery Images
  ========================================================================== */
.ws-profile-img-component {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.ws-profile-img-component > .ant-image {
  display: block;
  margin: 0 auto;
}
.ws-profile-img-component .icon-btn {
  width: 40px !important;
  height: 40px !important;
  border: none !important;
  background: rgba(0, 0, 0, 0.1);
  z-index: 2;
  position: absolute;
  right: 5px;
  top: 5px;
}
div:not(.logo-container) .ws-profile-img-component {
  height: 180px;
  width: 180px;
  margin: 0 auto;
}
div:not(.logo-container) .ws-profile-img-component .icon-btn {
  right: 5px;
}

.ws-profile-img-component .icon-btn span {
  font-size: 27px !important;
  color: white;
  filter: drop-shadow(0px 2px 8px rgba(0, 40, 141, 0.6));
}

.ws-profile-container .img-container {
  height: 180px;
  width: 180px;
  border-radius: 6px;
  object-fit: cover;
}

@media (max-width: 576px) {
  div:not(.logo-container) .ws-profile-img-component {
    height: auto;
    width: 100%;
    margin: 0 auto;
  }
  .ws-profile-container .img-container {
    width: 100% !important;
    height: auto !important;
    aspect-ratio: 1/1;
  }
}

/* ==========================================================================
  /Gallery Images
  ========================================================================== */

/* 
@media (max-width: 1100px) {
  .ws-profile-img-component .icon-btn {
    top: -163px;
    right: 3px;
  }
} 
*/

.ws-profile-container .image-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 11px;
}

.pop-over-container {
  width: 180px;
  height: fit-content;
  padding: 17px 12px 1px 12px;
}

.pop-over-container .label {
  display: flex;
  gap: 11px;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 11px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}
.extra-small-font {
  font-size: 12px;
}
.small-font {
  font-size: 14px;
}
.medium-font {
  font-size: 16px;
}
.large-font {
  font-size: 24px;
}
.primary-color {
  color: var(--primary-color);
}
.gray-color {
  color: var(--gray-color);
}
.zero-margin {
  margin: 0;
}
.simple-lightblue-highlight {
  background-color: #eff6ff;
  padding: 0.1em 0.2em;
}


.ws-profile-container .section-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  padding: 0 0 24px 0;
  margin-bottom: 24px;
}
.ws-profile-container .section-container {

}
.ws-profile-container .section-container.services .cta {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 10px;
}
.ws-profile-container .section-container h2 {
  margin-bottom: 5px;
  line-height: 30px;
}
.ws-profile-container .ck-add-button {
  width: 100% !important;
  max-width: unset;
}
