.reception-data-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Standard syntax */
}
.reception-data-container .regular {
  font-size: 14px;
  font-family: "EMprint-Regular";
  color: #000000;
  margin: 0px;
}
.reception-data-container .blue {
  font-size: 14px;
  font-family: "EMprint-Semibold";
  color: var(--primary-color);
  margin: 0px;
}
.reception-data-container .bold {
  font-size: 14px;
  font-family: "EMprint-Semibold";
  color: #000000;
  margin: 0px;
}
.reception-data-container .date-info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
.reception-data-container .date-info-container .col-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.reception-data-container .staff-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 10px 10px 20px 10px;
  margin: 20px 0px;
  border-radius: 6px;
  background-color: #f8f8f8;
  width: 100%;

  gap: 10px;
}
.reception-data-container .staff-container .bold {
  width: 100%;
  margin: 0px 0px 10px 0px;
}
.reception-data-container .staff-container .staff-card.read-only {
  cursor: not-allowed !important;
}
.reception-data-container .staff-container .staff-card.active {
  background-color: var(--mariner-color);
}
.reception-data-container .staff-container .staff-card {
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 10px;
  border-radius: 10px;
  gap: 10px;

  width: calc(50% - 20px);
  border: 2px solid var(--mariner-color);
  min-width: 150px;
  background-color: transparent;
}
.reception-data-container .staff-container .staff-card .ant-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
  background-color: rgba(189, 185, 185, 0.363);
}
.reception-data-container .staff-container .staff-card .ant-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.reception-data-container .staff-container .staff-card.active span {
  color: white;
}
.reception-data-container .staff-container .staff-card span {
  font-size: 14px;
  font-family: "EMprint-Semibold";
  color: var(--primary-color);
}
.reception-data-container .staff-container .staff-card .name-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
}
.reception-data-container .staff-container .staff-card .name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.reception-data-container .staff-container .staff-card.active .anticon {
  display: flex !important;

  width: 20px;
  height: 20px;

  font-size: 12px;
  color: var(--mariner-color);
  background-color: white;
  border-radius: 50px;
  padding: 3px;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
}
.reception-data-container .staff-container .staff-card .anticon {
  display: none;
}

@media (max-width: 992px) {
  .reception-data-container .date-info-container {
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
  }
}
