.ws-contact-form .ant-input {
  border: none !important;
  border-bottom: 1px solid #444444 !important;
  border-radius: 0px !important;
  padding: 0px !important;
}

.ws-contact-form .ant-form-item {
  margin-bottom: 20px !important;
}

.ws-contact-form .ant-input-number {
  border: none !important;
  border-radius: 0px !important;
  padding: 0px !important;
}

.ws-contact-form .ant-input-number-input {
  border: none !important;
  border-bottom: 1px solid #444444 !important;
  border-radius: 0px !important;
  padding: 0px !important;
}

.ws-contact-form .ant-input-affix-wrapper {
  border: none !important;
  border-radius: 0px !important;
  padding: 0px !important;
}

.ws-contact-form .ant-input-suffix {
  border: none !important;
  border-bottom: 1px solid #444444 !important;
  border-radius: 0px !important;
  padding: 0px 0px 0px 5px !important;
  margin: 0px !important;
}

.ws-contact-form .ant-picker {
  border: none !important;
  border-bottom: 1px solid #444444 !important;
  border-radius: 0px !important;
  padding: 0px !important;
}

.ws-contact-form .ant-picker .ant-picker-suffix {
  color: #444444 !important;
}

.ws-contact-form .ant-picker .ant-picker-clear {
  color: #444444 !important;
}

.ws-contact-form .ant-radio .ant-radio-inner {
  border: 2px solid #2f66f2 !important;
}

.ws-contact-form .ant-form-item-control-input-content,
.ant-form-item-control-input-content input,
.ant-select-selector,
.ant-input-number,
textarea.ant-input,
.ant-input-password {
  border-radius: 0px !important;
}

.ws-contact-form
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 0px !important;
  border: none !important;
  border-bottom: 1px solid #444444 !important;
  padding: 0px !important;
}

.ws-contact-form .ant-form-item-extra {
  color: #444444 !important;
  font-size: 16px;
  line-height: 20px;
  font-family: "Emprint-semibold";
}

.ws-contact-form .ant-upload.ant-upload-drag .ant-upload {
  padding: 0px !important;
}

.ws-contact-form .ant-upload.ant-upload-drag {
  border: none !important;
}

.ws-contact-form .logo-container {
  height: 180px;
  width: 180px;
}

.ws-contact-form .form-container {
  width: 100%;
}

.ws-contact-form .form-title {
  color: var(--primary-color);
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 17px;
}
