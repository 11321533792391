.social-reason-form-item {
    margin-bottom: 0;
}

.fiscal-data-form-items .ant-input {
    border: none !important;
    border-bottom: 1px solid #444444 !important;
    border-radius: 0px !important;
    padding: 0px !important;
}

.fiscal-data-form-items .ant-form-item-extra {
    color: #444444 !important;
    font-size: 16px;
    line-height: 20px;
    font-family: "Emprint-semibold";
}
