/* ----- Light ----- */
@font-face {
  font-family: "EMPrint";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(em-print-light.eot);
  src: local("EMPrint Light"),
      local("EMPrint-Light"),
      url(em-print-light.eot?#iefix) format("embedded-opentype"),
      url(em-print-light.woff2) format("woff2"),
      url(em-print-light.woff) format("woff"),
      url(em-print-light.ttf) format("truetype"),
      url(em-print-light.svg#EMPrint) format("svg")
}
/* Legacy declaration */
@font-face {
  font-family: "Emprint-light";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(em-print-light.eot);
  src: local("EMPrint Light"),
      local("EMPrint-Light"),
      url(em-print-light.eot?#iefix) format("embedded-opentype"),
      url(em-print-light.woff2) format("woff2"),
      url(em-print-light.woff) format("woff"),
      url(em-print-light.ttf) format("truetype"),
      url(em-print-light.svg#EMPrint) format("svg")
}

/* ----- Light Italic ----- */
@font-face {
  font-family: "EMPrint";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(em-print-light-italic.eot);
  src: local("EMPrint Light Italic"),
      local("EMPrint-LightItalic"),
      url(em-print-light-italic.eot?#iefix) format("embedded-opentype"),
      url(em-print-light-italic.woff2) format("woff2"),
      url(em-print-light-italic.woff) format("woff"),
      url(em-print-light-italic.ttf) format("truetype"),
      url(em-print-light-italic.svg#EMPrint) format("svg")
}

/* ----- Regular ----- */
@font-face {
  font-family: "EMPrint";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(em-print-regular.eot);
  src: local("EMPrint Regular"),
      local("EMPrint-Regular"),
      url(em-print-regular.eot?#iefix) format("embedded-opentype"),
      url(em-print-regular.woff2) format("woff2"),
      url(em-print-regular.woff) format("woff"),
      url(em-print-regular.ttf) format("truetype"),
      url(em-print-regular.svg#EMPrint) format("svg")
}
/* Legacy declaration */
@font-face {
  font-family: "Emprint-regular";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(em-print-regular.eot);
  src: local("EMPrint Regular"),
      local("EMPrint-Regular"),
      url(em-print-regular.eot?#iefix) format("embedded-opentype"),
      url(em-print-regular.woff2) format("woff2"),
      url(em-print-regular.woff) format("woff"),
      url(em-print-regular.ttf) format("truetype"),
      url(em-print-regular.svg#EMPrint) format("svg")
}

/* ----- Regular Italic ----- */
@font-face {
  font-family: "EMPrint";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(em-print-regular-italic.eot);
  src: local("EMPrint Italic"),
      local("EMPrint-Italic"),
      url(em-print-regular-italic.eot?#iefix) format("embedded-opentype"),
      url(em-print-regular-italic.woff2) format("woff2"),
      url(em-print-regular-italic.woff) format("woff"),
      url(em-print-regular-italic.ttf) format("truetype"),
      url(em-print-regular-italic.svg#EMPrint) format("svg")
}

/* ----- Semibold ----- */
@font-face {
  font-family: "EMPrint";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(em-print-semibold.eot);
  src: local("EMPrint SemiBold"),
      local("EMPrint-SemiBold"),
      url(em-print-semibold.eot?#iefix) format("embedded-opentype"),
      url(em-print-semibold.woff2) format("woff2"),
      url(em-print-semibold.woff) format("woff"),
      url(em-print-semibold.ttf) format("truetype"),
      url(em-print-semibold.svg#EMPrint) format("svg")
}
@font-face {
  font-family: "Emprint-semibold";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(em-print-semibold.eot);
  src: local("EMPrint SemiBold"),
      local("EMPrint-SemiBold"),
      url(em-print-semibold.eot?#iefix) format("embedded-opentype"),
      url(em-print-semibold.woff2) format("woff2"),
      url(em-print-semibold.woff) format("woff"),
      url(em-print-semibold.ttf) format("truetype"),
      url(em-print-semibold.svg#EMPrint) format("svg")
}

/* ----- Semibold Italic ----- */
@font-face {
  font-family: "EMPrint";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(em-print-semibold-italic.eot);
  src: local("EMPrint SemiBold Italic"),
      local("EMPrint-SemiBoldItalic"),
      url(em-print-semibold-italic.eot?#iefix) format("embedded-opentype"),
      url(em-print-semibold-italic.woff2) format("woff2"),
      url(em-print-semibold-italic.woff) format("woff"),
      url(em-print-semibold-italic.ttf) format("truetype"),
      url(em-print-semibold-italic.svg#EMPrint) format("svg")
}

/* ----- Bold ----- */
@font-face {
  font-family: "EMPrint";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(em-print-bold.eot);
  src: local("EMPrint Bold"),
      local("EMPrint-Bold"),
      url(em-print-bold.eot?#iefix) format("embedded-opentype"),
      url(em-print-bold.woff2) format("woff2"),
      url(em-print-bold.woff) format("woff"),
      url(em-print-bold.ttf) format("truetype"),
      url(em-print-bold.svg#EMPrint) format("svg")
}
/* Legacy declaration */
@font-face {
  font-family: "Emprint-bold";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(em-print-bold.eot);
  src: local("EMPrint Bold"),
      local("EMPrint-Bold"),
      url(em-print-bold.eot?#iefix) format("embedded-opentype"),
      url(em-print-bold.woff2) format("woff2"),
      url(em-print-bold.woff) format("woff"),
      url(em-print-bold.ttf) format("truetype"),
      url(em-print-bold.svg#EMPrint) format("svg")
}


/* ----- Bold Italic ----- */
@font-face {
  font-family: "EMPrint";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(em-print-bold-italic.eot);
  src: local("EMPrint Bold Italic"),
      local("EMPrint-BoldItalic"),
      url(em-print-bold-italic.eot?#iefix) format("embedded-opentype"),
      url(em-print-bold-italic.woff2) format("woff2"),
      url(em-print-bold-italic.woff) format("woff"),
      url(em-print-bold-italic.ttf) format("truetype"),
      url(em-print-bold-italic.svg#EMPrint) format("svg")
}
