.tc-content {
    display: flex;
    flex-direction: column;
    padding: 3%;
}
.tc-content a {
    text-decoration: underline;
    font-weight: 600;
    font-size: 20px;
}
.tc-content .tc-title {
    color: var(--mariner-color);
    font-size: 40px;
}
.tc-content .tc-subtitle {
    font-size: 20px;
}
.tc-content .tc-extra-text {
    font-size: 16px;
    margin: 0;
}
.tc-content p {
    font-size: 20px;
}
.tc-content .main-tc-collapse .ant-collapse-content-box{
    padding: 12px 12px 12px 0;
}
.tc-content .ant-collapse-header,
.tc-content .secondary-panel-container .ant-collapse-header
{
    padding: 12px 40px 12px 0 !important;
}
.main-tc-collapse .ant-collapse-header-text {
    font-size: 24px;
}
.main-tc-collapse .ant-collapse-item-active .ant-collapse-header-text {
    color: var(--mariner-color);
}
.main-tc-collapse .tc-item-number {
    color: var(--mariner-color);
}
.main-tc-collapse .ant-collapse-item.ant-collapse-item-active .ant-collapse-arrow svg {
    transform: rotate(180deg) !important;
}
.secondary-collapse-panel .ant-collapse-content-box {
    padding: 12px 0 12px 12px !important;
}
.secondary-tc-collapse {
    padding-left: 25px;
}
.secondary-tc-collapse .ant-collapse-item.ant-collapse-item-active .ant-collapse-arrow svg {
    transform: rotate(180deg) !important;
}
.secondary-tc-collapse .ant-collapse-header-text {
    color: #001450;
    font-size: 20px;
    font-weight: 600;
}
.secondary-tc-collapse li::marker {
    color:  var(--mariner-color);
    font-weight: 600;
}
.secondary-tc-collapse li {
    font-size: 20px;
}
.gray-background {
    background-color: #F5F3F3;
    padding: 5px;
}

@media (max-width: 992px) {
    .tc-content a {
        font-size: 16px;
    }
    .tc-content .tc-title {
        font-size: 24px;
    }
    .tc-content .tc-subtitle {
        font-size: 16px;
    }
    .tc-content p {
        font-size: 16px;
    }
    .tc-content .tc-extra-text {
        font-size: 14px;
    }
    .main-tc-collapse .ant-collapse-header-text {
        font-size: 20px;
    }
    .secondary-tc-collapse .ant-collapse-header-text {
        font-size: 16px;
    }
    .secondary-tc-collapse li {
        font-size: 16px;
    }
}
