section.packages-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.packages-container .title {
  color: var(--primary-color);
  margin: 0;
  font-size: 24px;
  font-weight: 600;
}

.packages-container p {
  font-size: 14px;
  color: #444;
}

.packages-container ul {
  margin-bottom: 15px !important;
  font-size: 14px;
  color: #444;
  padding-left: 17px;
}

.packages-container {
  transition: all 0.5s ease;
}

.packages-container.ant-drawer-open {
  z-index: 3100;
}
@media (min-width: 992px) {
  .packages-container.ant-drawer-open .ant-drawer-content-wrapper {
    width: calc(100vw - 61px) !important;
    transform: translateX(0px) !important;
  }
  .expanded-menu.packages-container.ant-drawer-open
    .ant-drawer-content-wrapper {
    width: calc(100vw - 300px) !important;
    transform: translateX(0px) !important;
  }
}

.packages-container .cards-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.packages-container .cancel-style {
  color: var(--primary-color);
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}

.packages-container .actions-container .button-style {
  height: 40px;
  font-weight: 600;
  min-width: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.packages-container .button-style {
  height: 40px;
  font-weight: 600;
  min-width: 200px;
}

.package-card-container .selected-type-code {
  background: var(--mariner-color) !important;
  color: white !important;
}

.package-card-container .type-code-saved {
  background: white !important;
  color: var(--mariner-color) !important;
}

@media (max-width: 992px) {
  .packages-container.ant-drawer-open .ant-drawer-content-wrapper {
    width: calc(100vw) !important;
    top: 65px;
  }

  .package-card-container {
    width: 100%;
  }

  .package-card-container .type-code {
    width: 95px;
    height: 40px;
    border-radius: 10px;
    border: 2px solid var(--mariner-color);
    background: white;
    color: #aaa;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .packages-container .actions-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 35px;
    margin-top: 20px;
    align-self: flex-end;
  }

  .packages-container .ant-input-number-input {
    height: 60px;
    border: none !important;
    font-size: 40px;
    font-weight: 700;
    color: var(--primary-color) !important;
  }
}

@media (min-width: 992px) {
  .package-card-container {
    width: 100%;
  }

  .package-card-container .type-code {
    width: 180px;
    height: 40px;
    border-radius: 10px;
    border: 2px solid var(--mariner-color);
    background: white;
    color: #aaa;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .packages-container .actions-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 159px;
    margin-top: 20px;
    padding-right: 120px;
    align-self: flex-end;
  }

  .packages-container .ant-input-number-input {
    width: 145px;
    height: 60px;
    border: none !important;
    font-size: 40px;
    font-weight: 700;
    color: var(--primary-color) !important;
  }
}

.package-card-container .ant-collapse > .ant-collapse-item:last-child,
.ant-collapse:not(.package-selection-collapse, .service-selection-collapse, .main-tc-collapse, .secondary-tc-collapse)
  > .ant-collapse-item:last-child
  > .ant-collapse-header {
  padding: 0px !important;
}
.package-card-container .panel-header {
  display: flex;
  gap: 12px;
  padding: 10px;
}
.package-card-container .panel-header .added-checkmark {
  color: var(--primary-color);
}
.package-card-container .ant-collapse-item .ant-collapse-expand-icon .anticon {
  transition: all 0.5s ease;
}
.package-card-container
  .ant-collapse-item.ant-collapse-item-active
  .ant-collapse-expand-icon
  .anticon {
  transform: rotate(-180deg) translate(0, 5px);
}

.package-card-container .title {
  color: var(--carker-blue);
  font-size: 16px;
  font-weight: 600;
}

.package-card-container .card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.package-card-container .check {
  margin-right: 10px;
  stroke: white;
  stroke-width: 120px;
}

.package-card-container .check-mariner-color {
  stroke: var(--mariner-color);
}

.packages-container .card-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.packages-container .price-input-container {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: flex-end;
}

.packages-container .price-input-container .number-input {
  width: 276px;
  height: 60px;
}

.packages-container .ant-input-number {
  border: 1px solid #cccccc !important;
  border-radius: 6px !important;
  color: #2f66f2 !important;
  width: 145px;
}

.packages-container
  .ant-input-number-input-wrap
  > input.ant-input-number-input {
  margin: 2px !important;
}

.packages-container .ant-input.--custom,
.ant-input-number.--custom,
.ant-select.--custom .ant-select-selector,
.ant-picker.--custom {
  border: 1px solid #cccccc !important;
  resize: none;
}

.packages-container .price-input-container span {
  font-size: 16px;
  font-weight: 600;
  color: #444;
}

.packages-container .comment-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #444;
}

.packages-container .warranty-preview-packages-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
