.tag-item {
  border: 1px solid #cccccc;
  display: flex;
  flex-direction: row;
  height: 30px;
  justify-content: center;
  align-content: center;
  width: fit-content;
  margin: 5px;
  border-radius: 6px;
  padding: 0 10px 0 10px;
}
.tag-item:hover {
  cursor: pointer;
}
.tag-item.selected {
  background: #eff6ff;
  border: 1px solid #001450;
}
