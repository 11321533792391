.ant-image-preview-mask {
  z-index: 1200;
}
.ant-image-preview-operations-wrapper {
  z-index: 1210;
}
.ant-image-preview-wrap {
  z-index: 1205;
}
.request-dashboard {
  /* border: 1px solid #CCCCCC; */
  width: 100%;
  overflow: hidden;
  height: calc(100vh - 90px);
  display: flex;
  justify-content: center;
  background: #fff;
}
.entries-list-container {
  border: 1px solid #CCCCCC;
  height: calc(100vh - 90px);
}
@media (max-width: 990px) {
  .request-dashboard,
  .entries-list-container {
    height: calc(100vh - 117px);
  }
}

.entries-list-container .filters-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  border-bottom: 1px dashed #ccc;
  background: #fff;
  height: 74px;
  padding: 0 15px;
}
.entries-list-container .filters-container .buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 10px;
}

.entries-list-container .filters-container .ant-btn {
  color: var(--primary-color);
  width: 40px;
  height: 40px;
}
.entries-list-container .filters-container .ant-btn svg {
  width: 25px;
  height: auto;
}

.entries-list-container .filters-container .filter-select {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-end;
  align-items: flex-start;
}
.entries-list-container .filters-container p {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: black;
  text-align: start;
  margin: 0;
  padding-right: 5px;
}
.entries-list-container .filters-container .filter-filled-select {
  width: 140px;
}

/* Range date picker */
.filter-range-date-picker {
  z-index: 2100;
}

/* Request popover */
.request-popover-filter {
  z-index: 2000;
}
.request-popover-filter .ant-popover-inner {
  border-radius: 10px;
}
.request-popover-filter-render {
  width: 310px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.request-popover-filter-render p {
  color: var(--mariner-color);
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
  margin-bottom: 0;
  /* text-align: center; */
}
.request-popover-filter-render .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  border: 0;
  margin: 0;
}
.request-popover-filter-render .close-button svg {
  width: 20px;
  height: 20px;
}

.request-popover-filter-render .ant-btn:not(.close-button) {
  width: 200px;
  border-width: 2px;
}
.request-popover-filter-render .ant-btn:not(.close-button) span {
  font-weight: 600;
  font-size: 16px !important;
}
.request-popover-filter-render .filter-group {
  width: 100%;
  color: #444;
  border-top: 1px #ccc dashed;
  padding: 10px 0;
}
.request-popover-filter-render .filter-group:first-of-type {
  border-top: 0;
}
.request-popover-filter-render .filter-group p {
  color: #444;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 5px;
}
.request-popover-filter-render .filter-group .ant-radio-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 10px;
}
.request-popover-filter-render .filter-group .ant-radio-wrapper {
  width: 128px;
  font-size: 16px;
  margin-right: 0;
}
.request-popover-filter-render .filter-group .ant-radio-wrapper.range-option {
  width: 100%;
}
.request-popover-filter-render .filter-group .ant-picker.ant-picker-range {
  margin-bottom: 5px;
}
.request-popover-filter-render .filter-group .ant-picker.ant-picker-range .ant-picker-range-separator {
  padding: 0px 16px 6px 8px;
  color: #555 !important;
}
.request-popover-filter-render .filter-group .ant-picker.ant-picker-range input {
  font-size: 14px !important
}

/* Request popover */
