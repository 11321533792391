.workshop-images-flex {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 14px !important;
    margin-top: 10px;
    width: 100%;
}

.workshop-form-gallery .empty-image-dragger {
    width: 220px;
    height: 220px;
}

@media only screen and (max-width: 992px) {
  .workshop-form-gallery .empty-image-dragger {
    width: 200px;
    height: 200px;
  }
}

.mobile-add-image {
    display: flex;
    align-items: center;
    justify-content: center;
}
