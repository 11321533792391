
/*=============================================
=             Main Dashboad Actions            =
=============================================*/
.md-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
}

.md-actions h1 {
  color: var(--primary-color);
  font-size: 24px !important;
  font-weight: 600;
  line-height: 30px !important;
  margin: 0;
}
@media (max-width: 575px) {
  .md-actions {
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
  }
}
/*=====  End of Main Dashboad Actions  ======*/

