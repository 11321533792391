.location-container .map-container {
  height: 400px;
  width: 500px;
}

.location-container {
  display: flex;
  flex-direction: column;
  padding-left: 25px;
}

@media (max-width: 992px) {
  .location-container {
    padding-left: 0;
  }
  .location-container .map-container {
    height: 400px;
    width: 100%;
  }
}
