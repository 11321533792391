.edit-workshop-services.ant-drawer-open {
  z-index: 3100;
}
@media (min-width: 992px) {
  .edit-workshop-services.ant-drawer-open .ant-drawer-content-wrapper {
    width: calc(100vw - 61px) !important;
    transform: translateX(0px) !important;
  }
  .expanded-menu.edit-workshop-services.ant-drawer-open
    .ant-drawer-content-wrapper {
    width: calc(100vw - 300px) !important;
    transform: translateX(0px) !important;
  }
}
@media (max-width: 992px) {
  .edit-workshop-services.ant-drawer-open .ant-drawer-content-wrapper {
    width: calc(100vw) !important;
    top: 0px;
  }
}
.edit-workshop-services .ant-drawer-header {
  display: none;
}
.edit-workshop-services .ant-drawer-body {
  padding: 25px 0;
}
.edit-workshop-services .content-wrapper {
  width: 90%;
  margin: 0 auto;
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 15px;
}
.edit-workshop-services .content-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid var(--gray-200);
  padding-bottom: 10px;
  position: relative;
  top: 0;
  left: 0;
}
.edit-workshop-services .content-header h2 {
  font-weight: 600;
  color: var(--primary-color);
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 0px;
}
.edit-workshop-services .content-header .ck-button.ant-btn-link {
  padding: 0 !important;
  width: 20px;
  height: 20px;
  max-width: 20px;
  min-width: 20px;
  position: absolute;
  top: 3px;
  left: -25px;
}
.edit-workshop-services .content-header .ck-button.ant-btn-text {
  height: 30px !important;
}
.edit-workshop-services
  .content-header
  .ck-button.ant-btn-text
  span:not(.anticon) {
  font-weight: 400 !important;
  margin-top: -5px;
}
@media (min-width: 992px) {
  .edit-workshop-services .content-header {
    
  }
  .edit-workshop-services .content-header h2 {
    
  }
  .edit-workshop-services .content-header .ck-button.ant-btn-link {
    
  }
  .edit-workshop-services .content-header .ck-button.ant-btn-text {
    
  }
}
@media (max-width: 991px) {
  .edit-workshop-services .content-header {
    margin-bottom: 40px;
    height: 40px;
  }
  .edit-workshop-services .content-header h2 {
    margin-bottom: -100px;
  }
  .edit-workshop-services .content-header .ck-button.ant-btn-link {
    left: 0;
  }
  .edit-workshop-services .content-header .ck-button.ant-btn-text {
    margin-bottom: -100px;
  }
}
.edit-workshop-services .content-wrapper .resume {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.edit-workshop-services .content-wrapper .resume p {
  margin-bottom: 0;
  font-weight: 600;
}

.edit-workshop-services .content-wrapper .resume p span {
  background: var(--light-blue);
  color: var(--mariner-color);
  display: inline-block;
  padding: 3px 10px;
  border-radius: 18px;
  line-height: 14px;
  text-align: center;
}

@media (min-width: 992px) {
  .edit-workshop-services .content-wrapper .resume .ant-btn span.ck-icon {
    display: none;
  }
}
@media(max-width: 991px) {
  .edit-workshop-services .content-wrapper .resume .ant-btn {
    width: 40px;
    max-width: 40px;
    min-width: 40px;
    border-radius: 50% !important;
  }
  .edit-workshop-services .content-wrapper .resume .ant-btn span:not(.ck-icon){
    display: none;
  }
}
.edit-workshop-services .content-wrapper .ck-add-button {
  max-width: unset;
  width: 100%;
}
