/*=====================================================
=            Main Dashboard General Status            =
=====================================================*/
.md-general-stats {
  padding: 4px;
  display: flex;
  gap: 8px;
}
.md-general-stats .stats-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  background-color: var(--mariner-color);
  border-radius: 10px;
  font-weight: 600;
  text-align: center;
  width: 100%;
  min-height: 120px;
  height: auto;
  object-fit: contain;
  border: 1px solid #dddddd;
  padding: 10px 0;
  transition: background-color 0.5s ease;
}
.md-general-stats .stats-box {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.md-general-stats .stats-box.pressable:hover {
  cursor: pointer;
  background-color: rgba(var(--mariner-color-rgb), 0.8);
}
.md-general-stats .stats-box .ant-skeleton-title {
  height: 58px;
  margin: 10px auto;
}
.md-general-stats .stats-box .value {
  line-height: 70px;
  font-size: 64px !important;
  font-weight: 700 !important;
  margin: 0;
}
.md-general-stats .stats-box .label {
  font-size: 20px !important;
  line-height: 24px;
  font-weight: 600 !important;
  margin: 0;
}
@media (min-width: 1100px) and (max-width: 1200px) {
  .md-general-stats .stats-box .label {
    font-size: 16px !important;
  }
}
@media (min-width: 993px) and (max-width: 1100px) {
  .md-general-stats .stats-box .label {
    font-size: 14px !important;
  }
}

/*=====  End of Main Dashboard General Status  ======*/
