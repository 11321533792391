.physical-details-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Standard syntax */
}
.physical-details-container .anticon-left,
.physical-details-container .anticon-right {
  justify-content: center;
}
.physical-details-container .dt-sub-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  border-radius: 6px;
  padding: 25px;

  margin-bottom: 20px;

  background-color: #f8f8f8;
}
.physical-details-container .no-color {
  padding: 0px !important;
  background-color: transparent !important;
}
.physical-details-container .dt-sub-container.is-comment {
  /* margin: -10px 0px 20px 0px; */
}
.physical-details-container .title {
  font-size: 16px !important;
}
.physical-details-container .bold {
  font-size: 12px;
  font-family: "EMprint-Semibold";
  color: #444444;
}
.physical-details-container .bold span {
  font-family: "EMprint-Regular" !important;
  font-weight: 400 !important;
}
.physical-details-container .regular {
  font-size: 12px;
  font-family: "EMprint-Regular";
  color: #444444;
}
.physical-details-container .regular strong {
  font-family: "EMprint-Semibold";
  font-weight: 900 !important;
}
.physical-details-container .car-part-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.physical-details-container .car-part-col .blue-title {
  font-size: 15px;
  line-height: 18px;
  font-family: "EMprint-Regular";
  letter-spacing: 0em;
  text-align: center;
  color: var(--primary-color);

  margin-bottom: 5px;
}
.physical-details-container .car-part {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  gap: 10px;

  width: 100%;
}
.physical-details-container .car-part .anticon {
  width: 20px;
  height: 20px;
  color: var(--primary-color) !important;
}
.physical-details-container .car-radio-part {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
.physical-details-container .car-radio-part .ant-radio-button-wrapper {
  width: 40.71px;
  height: 32px;
}
.physical-details-container .car-radio-part .ant-radio-button-wrapper img {
  width: 38.71px;
  height: 30px;
  margin-left: -15px;
}
.physical-details-container .car-radio-part .ant-radio-button-wrapper-checked {
  border-color: var(--primary-color) !important;
  box-shadow: none;
}
.physical-details-container .hide-form {
  position: absolute;
  height: 0px;
  margin: 0px;
  padding: 0px;
}
.physical-details-container .class-formatCarkerDoc {
  overflow-x: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 10px;
  width: 100%;
}
.physical-details-container .class-formatCarkerDoc .upload-botton {
  min-width: 225px;
}
.physical-details-container .reception-multiple-document {
  display: flex;
  flex-direction: row;
  align-self: center;
  margin: 10px 0px;
  gap: 10px;

  border-radius: 10px;

  max-height: 120px;
  max-width: 100%;

  overflow-x: auto;

  scrollbar-width: thin;
  scrollbar-color: #44444493 #44444411;
}
.physical-details-container .reception-multiple-document::-webkit-scrollbar {
  display: none !important;
  height: 8px;
}
.physical-details-container
  .reception-multiple-document::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: transparent;
}
.physical-details-container
  .reception-multiple-document::-webkit-scrollbar-track {
  border-radius: 6px;
  background-color: transparent;
}
.physical-details-container
  .reception-multiple-document:hover::-webkit-scrollbar-thumb {
  background-color: #4444442a;
}
.physical-details-container
  .reception-multiple-document:hover::-webkit-scrollbar-track {
  background-color: #44444411;
}
.physical-details-container
  .reception-multiple-document
  .reception-document-preview {
  margin: 0px;
  height: 80px !important;
  width: 180px !important;
  min-width: 180px !important;
}
.physical-details-container
  .reception-multiple-document
  .reception-document-preview
  .ant-image {
  height: 100%;
  width: 100%;
}
.physical-details-container
  .reception-multiple-document
  .reception-document-preview
  .ant-image
  img {
  min-height: 100% !important;
  min-width: 100% !important;
  object-fit: cover !important;
}
.physical-details-container .reception-document-preview {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}
.physical-details-container .reception-document-preview img {
  border-radius: 10px;
  object-fit: cover;
}
.physical-details-container .reception-document-preview .ant-image-mask {
  border-radius: 10px;
}
.physical-details-container .reception-document-preview .trashico-white {
  z-index: 1;
  display: flex;
  flex-direction: row;

  padding: 5px;
  margin-left: -31px;
  border-radius: 8px;

  background-color: rgba(255, 255, 255, 0.851);
  border: 0.5px solid #444444;
}
.physical-details-container .upload-botton {
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 280px;
  height: 37px !important;

  border-radius: 10px;
  border: 2px solid var(--primary-color);

  font-family: "EMprint-Semibold";
  color: var(--primary-color);
}
.physical-details-container .upload-botton span {
  font-size: 14px;
}
.physical-details-container .upload-botton .anticon {
  display: flex;
  font-size: 22px;
  width: 22px;
  height: 22px;
}
.physical-details-container .images-rules {
  align-self: center;
}
.physical-details-container .vehicle-issues-card {
  width: 230px;
  height: 177px;
  border-radius: 12px;
  border: solid 1px #707070;
  margin: 0 auto;
  transition-duration: 400ms;
  padding: 0;
  overflow: hidden;
  margin-top: 10px;
}
.physical-details-container .vehicle-issues {
  max-width: 230px;
  width: 100%;
  height: 177px;
  max-height: 177px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.physical-details-container .vehicle-issues-card .ant-card-body {
  padding: 0 !important;
}
.physical-details-container .switch-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 10px 0px;

  width: 100%;
}
.physical-details-container .switch-container .switch-sub-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 252px;
  height: 32px;

  border-radius: 10px;

  border: 1px solid var(--primary-color);

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Standard syntax */

  cursor: pointer;
}
.physical-details-container
  .switch-container
  .switch-sub-container
  #add-element {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-size: 12px;
  font-weight: 600;
  color: var(--primary-color);

  gap: 4px;
}
.physical-details-container
  .switch-container
  .switch-sub-container
  #delete-element {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-size: 12px;
  font-weight: 600;
  color: var(--primary-color);

  gap: 4px;
}
.physical-details-container
  .switch-container
  .switch-sub-container
  .active-element {
  color: white !important;
  border-radius: 8px;
  height: 100%;
  background-color: var(--mariner-color);
}
.physical-details-container .canva-group-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.physical-details-container .canva-group-container .canva-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 90px;
}
.physical-details-container .canva-group-container .canva-container p {
  font-size: 12px;
  font-family: "EMprint-Semibold";
  margin: 0px;
  color: #808080;
}
.physical-details-container .canva-group-container .canva-container .ant-card {
  width: 70px;
  height: 70px;

  border: none;
  border-radius: 50px;

  background-color: transparent;
}
.physical-details-container .canva-group-container .canva-container .disabled {
  color: var(--primary-color);
  border: 4px solid transparent;
  border-radius: 50px;
}
.physical-details-container .canva-group-container .canva-container .active {
  color: var(--primary-color);
  border: 4px solid var(--mariner-color);
  border-radius: 50px;
}
.physical-details-container
  .canva-group-container
  .canva-container
  .ant-card
  .ant-card-body {
  padding: 0px;
}
.physical-details-container .class-formatType {
  width: 100%;
  padding-bottom: 15px;
}
.physical-details-container .class-formatType .ant-form-item-extra {
  font-size: 14px;
  font-family: "EMprint-Regular";
  color: #444444;
  margin-bottom: 10px;
}
.physical-details-container .class-formatType .ant-radio-group {
  display: flex;
  flex-direction: row;
  gap: 10px;

  width: 100%;
}
.physical-details-container .class-formatType .ant-radio-button-wrapper {
  flex: 1;
  display: flex;

  justify-content: center;
  align-items: center;

  border: 1px solid var(--primary-color);

  height: 40px;
  min-height: 40px;

  border-radius: 10px;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Standard syntax */
}
.physical-details-container .class-formatType .ant-radio-button-wrapper span {
  font-size: 14px;
  font-family: "EMprint-Semibold";
  color: #444444;
}
.physical-details-container
  .class-formatType
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  background-color: var(--primary-color) !important;
  box-shadow: none !important;
}
.physical-details-container
  .class-formatType
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked
  span {
  color: white;
}
.physical-details-container
  .class-formatType
  .ant-radio-button-wrapper::before {
  background-color: transparent;
}

@media (max-width: 992px) {
}
