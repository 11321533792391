.approval-modal-container .custom-attachment-preview {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
  width: 100%;
}

.approval-modal-container .custom-attachment-preview .file-preview-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  grid-gap: 5px;
  gap: 5px;
  margin-top: 5px;
  background-color: var(--light-blue);
  padding: 4px;
}
.approval-modal-container .custom-attachment-preview .file-preview-label p {
  color: #000;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  margin-bottom: 0;
}
.approval-modal-container .custom-attachment-preview .file-preview-label .ant-btn {
  border: none;
  border-radius: 50%;
  padding: 0;
  height: 20px;
  width: 20px;
}
.approval-modal-container .custom-attachment-preview .file-preview-label .ant-btn {
  border: none;
  border-radius: 50%;
  padding: 0;
  height: 20px;
  width: 20px;
}

.approval-modal-container .custom-attachment-preview .file-preview-label {
  width: 100%;
  display: flex;
  flex-direction: row;
  grid-gap: 5px;
  gap: 5px;
  margin-top: 10px;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
}


.approval-modal-container .custom-attachment-preview .file-preview {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  width: calc(50% - 5px);
  max-width: 300px;
  margin-bottom: 0;
}

.approval-modal-container .custom-attachment-preview .file-preview .ant-image {
  height: auto;
  border-radius: 10px;
}

.custom-attachment-file-preview {
  z-index: 5010;
}
