.my-workshops-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100% !important;
  background-color: white !important;
  min-height: 94vh;
  padding: 20px 20px !important;
}
.my-workshops-container .list-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  /* gap: 20px; */
  width: 100%;
  max-width: 960px;
}
.my-workshops-container .add-workshop-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 960px;
  height: 154px;

  padding: 0px 15px;
  border-radius: 10px;
  margin-top: 20px;
  border: 1px solid #cccccc;
}

.my-workshops-container .add-workshop-btn {
  margin-top: 15px;
}
