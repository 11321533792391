/* ==========================================================================
  Drawer
  ========================================================================== */
.side-menu-drawer {
}
.side-menu-drawer .ant-drawer-content-wrapper {
  width: 100%;
  max-width: 300px;
  overflow-x: hidden;
}
.side-menu-drawer .ant-drawer-header {
  display: none;
}
.side-menu-drawer .ant-drawer-body {
  padding: 0;
}
/* ----- /Drawer ----- */

/* ==========================================================================
  Sider
  ========================================================================== */
.side-menu-sider {
  min-width: 61px !important;
  max-width: 61px !important;
  width: 300px !important;
  background-color: #888;
  flex: unset !important;
  transition: all 0.5s ease;
  z-index: 2200 !important;
}
.side-menu-sider.expanded {
  max-width: 300px !important;
  width: 300px !important;
}
.side-menu-sider > .ant-layout-sider-children > .toogle-menu-button {
  position: absolute;
  left: 70px;
  top: 28px;
}
/* ----- /Sider ----- */

/* ==========================================================================
  Section
  ========================================================================== */
.side-menu-content {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 300px;
  overflow: hidden;
}
.side-menu-content .side-menu-content-header {
  height: 91px;
  text-align: center;
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  background-color: #f2f2f2;
}
.side-menu-content-header figure {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 91px;
  width: 61px;
  min-width: 61px;
  margin: 0;
  background-color: #fff;
}
.side-menu-content-header figure img {
  height: 48px;
  width: auto;
}
.side-menu-content-header div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 91px;
  padding: 0 20px;
}
.side-menu-content-header div p {
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
  color: var(--primary-color);
}
.side-menu-content .side-menu-content-separator {
  height: 100%;
  width: 61px;
  position: fixed;
  top: 0;
  bottom: 0;
  background-color: rgb(68, 68, 68);
}
.side-menu-content .side-menu-content-body {
  height: auto;
  min-height: calc(100vh - 91px);
  background-color: #888;
}
.side-menu-content .side-menu-content-body .ant-menu {
  background-color: transparent;
  width: 100%;
  border: 0;
}
.side-menu-content .side-menu-content-body .ant-menu > li.ant-menu-item {
  margin-top: 10px !important;
  margin-bottom: 5px !important;
}
.side-menu-content .side-menu-content-body .ant-menu > li.ant-menu-item {
  margin: 2px 0 !important;
  padding-left: 8px !important;
}
.side-menu-content .side-menu-content-body .ant-menu > li:first-of-type {
  margin-top: 12px !important;
}
.side-menu-content .side-menu-content-body .ant-menu > li.ant-menu-item {
  padding: 24px 0px 24px 8px !important;
}
.side-menu-content .side-menu-content-body .side-menu-dashboard.ant-menu-item {
  color: #fff;
  font-size: 20px;
  line-height: 25px;
  /* font-weight: 600; */
}
.side-menu-content .side-menu-dashboard .ant-menu-title-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  font-size: 20px;
  line-height: 25px;
  /* font-weight: 600; */
  color: #fff !important;
}
.side-menu-content .side-menu-dashboard .side-menu-icon {
  width: 24px;
  height: 24px;
  color: #fff !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.side-menu-content .side-menu-dashboard .ant-spin-dot-item {
  background-color: #fff;
}
/* Contact menu */
.side-menu-content .contact-menu {
  width: 100%;
  padding: 0 10px 0 71px;
  height: 0;
  overflow: hidden;
  transition: all 0.5s ease;
}
.side-menu-content .contact-menu.expanded {
  height: 130px; /* Change this to fit the content of your div */
  transition: all 0.5s ease;
}
.side-menu-content .contact-menu a {
  margin: 10px 0;
  width: 200px;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
}
/* ----- /Sider ----- */




/* .ant-menu:not(.ant-menu-horizontal) .side-menu-dashboard.ant-menu-item-selected,
.ant-menu:not(.ant-menu-horizontal)
  .side-menu-dashboard.ant-menu-item-selected:hover,
.side-menu-dashboard.ant-menu-item-active {
  color: #fff !important;
  background-color: rgba(255, 255, 255, 0.2) !important;
} */



.side-menu-load {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
}

.side-menu-load .ant-spin-dot-item {
  background-color: white !important;
}

.force-white .ant-menu-title-content {
  color: white !important;
}

.side-submenu-item {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.side-submenu-item > span {
  margin-left: 10px;
  margin-right: 40px;
}

.side-submenu-item:hover {
  background-color: rgba(160, 160, 160, 0.65) !important;
}

.ant-menu-item-selected {
  border: none !important;
}

.ant-menu-item .ant-menu-item-icon + span,
.ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu-item .anticon + span,
.ant-menu-submenu-title .anticon + span {
  margin-left: 30px;
}

.ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  margin-left: 8px;
}

.ant-menu-inline-collapsed-tooltip {
  display: none;
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-item
  .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-submenu
  > .ant-menu-submenu-title
  .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-submenu
  > .ant-menu-submenu-title
  .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-item
  .anticon,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-submenu
  > .ant-menu-submenu-title
  .anticon,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-submenu
  > .ant-menu-submenu-title
  .anticon {
  margin: 9px;
}

.ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  min-width: 24px;
}

/* ==========================================================================
  Side menu
  ========================================================================== */
.side-menu-dashboard.ant-menu-item {
  color: white;
  font-size: var(--font-size-base);
  display: flex;
  align-items: center !important;
  padding-top: 0 !important;
  margin-top: 0 !important;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
  margin-left: 0 !important;
  padding-left: 0 !important;
}

.ant-menu:not(.ant-menu-horizontal)
  .side-menu-dashboard.ant-menu-item-selected {
  background-color: rgba(255, 255, 255, 0.2) !important;
  color: var(--primary-color);
  /* font-weight: 600; */
}

.ant-menu:not(.ant-menu-horizontal)
  .side-menu-dashboard.ant-menu-item-selected:hover {
  background-color: rgba(255, 255, 255, 0.596) !important;
}

.side-menu-dashboard.ant-menu-item:hover {
  background-color: rgba(255, 255, 255, 0.596) !important;
}
.side-menu-dashboard.ant-menu-item-active {
  color: var(--primary-color) !important;
}

.side-menu-dashboard .ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: none !important;
}
/* ---------- /Side menu ---------- */
