
/*==============================================================
=            Main Dashboard Graph Income In Circles            =
==============================================================*/
.md-graph-income-in-circles {

}
.md-graph-income-in-circles .graphic-container {

}
@media (min-width: 1200px) {
  .md-graph-income-in-circles {
    padding-right: 20px;
    border-right: 1px dashed #cacaca;
  }
}
@media (max-width: 1199px) {
  .md-graph-income-in-circles {
    padding-bottom: 20px;
    border-bottom: 1px dashed #cacaca;
  }
}
/*=====  End of Main Dashboard Graph Income In Circles  ======*/
