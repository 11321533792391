.workshop-form-checkbox-cont .ant-checkbox-inner {
    background-color: transparent;
    border-color: #444;
    border-radius: 5px;
    border-width: 2px;
    height: 20px;
    width: 20px
}

.workshop-form-checkbox-cont .ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: var(--orange);
}

.package-card-line .ant-input-number-disabled .ant-input-number-input {
    color: #CCCCCC !important;
}
