.login-form input::placeholder {
  text-align: left !important;
}
.login-form {
  width: 100%;
}
.login-box-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (min-width: 992px) {
  .login-box-container {
    justify-content: flex-start;
  }
}
@media (max-width: 575px) {
  .login-box-container {
    align-items: flex-start;
    width: 100%;
  }
}
.login-box {
  background-color: #ffffff;
  padding: 30px 80px;
  width: 90%;
  max-width: 680px;
  border-radius: 10px;
  z-index: 10;
}
@media (max-width: 575px) {
  .login-box {
    padding: 40px 20px 20px 20px;
    width: 100%;
    border-radius: 0;
  }
}

.login-box > img {
  margin: 0 auto;
  display: block;
  width: 150px;
}

.login-box .buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  gap: 10px;
}
.login-box .buttons-container.centered {
  justify-content: center;
}
.login-box .buttons-container .ant-btn.ant-btn-default {
  background: var(--primary-color);
  border: 0;
}
.login-box .cta-login {
  margin-top: 10px;
}
.login-box .terms-container {
  display: flex;
  align-items: center;
  margin: 15px;
  justify-content: center;
}
.login-box .terms-container a {
  margin-left: 4px;
  color: var(--primary-color);
}
.login-box .terms-container .mobile {
  display: none;
}
.login-box .terms-container .desktop {
  display: inline-block;
}
.login-box .terms-container .ant-checkbox-wrapper {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.login-box .terms-container .ant-checkbox-wrapper span.ant-checkbox {
  margin-bottom: 5px;
}
.login-box .terms-container .ant-checkbox-wrapper span:not(.ant-checkbox) {
  font-weight: 600;
  font-size: 14px;
  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 992px) {
  .login-box .terms-container .mobile {
    display: inline-block;
  }
  .login-box .terms-container .desktop {
    display: none;
  }
  .login-box .buttons-container {
    flex-direction: column;
  }
  .login-box-container {
    flex-direction: column;
    justify-content: flex-start;
  }
}

.login-box .input-bottom-border,
.login-box .input-bottom-border input,
.login-box .ant-input {
  outline: 0 !important;
  border-width: 1px !important;
  border-color: var(--gray-200) !important;
  border-radius: 0 !important;
  font-size: 16px;
}
.login-box .ant-input-password {
  padding: 0;
}
.login-box .ant-input-password > input {
  border-radius: 0 !important;
  padding: 4px 11px;
}

.login-text {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
}

.login-title-text {
  color: var(--primary-color);
  font-size: 24px;
  font-weight: 600;
  padding: 0 0 10px;
}

.login-input-text .ant-form-item-extra {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #444444;
}

.login-link-text {
  padding-top: 10px;
  height: 100%;
}

.login-link-text span {
  font-weight: 400;
  text-decoration-line: underline;
  color: var(--primary-color);
  font-size: 16px;
  line-height: 20px;
  font-family: "Emprint-semibold";
}

.digits-input-container {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.digits-input {
  text-align: center;
  width: 36px;
  height: 40px;
  margin-right: 0.4rem;
  padding: 0;
  border-color: var(--black-70);
}

.verify-email-buttons {
  display: flex;
  place-items: center;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin: 20px auto 0px auto;
}

.register-password-input {
  margin-bottom: 0 !important;
}
.register-password-input input {
  padding: 4px 11px 4px 0px !important;
}
.register-password-input span {
  border-color: #4444441c !important;
}

.bottom-spaced {
  padding: 0 0 25px;
}

.vertical-margin {
  padding: 20px 0;
}
.info-text {
  color: var(--gray-450);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  margin-bottom: 10px;
}
.info-text .anticon {
  color: var(--primary-color);
}
.info-text p {
  font-size: 14px;
  line-height: 18px;
  margin: 0;
}

.email-verification-box .action-message {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}
