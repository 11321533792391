.quotation-form-drawer.ant-drawer-open {
  width: 100% !important;
  width: calc(100% - 225px) !important;
  right: 0 !important;
  left: unset;
}

.quotation-form-drawer .saving-spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  z-index: 5000;
  background: rgba(255, 255, 255, 0.3);
}
.quotation-form-drawer .saving-spinner.filled-background {
  background: rgba(255, 255, 255, 1);
}
.quotation-form-drawer .ant-drawer-content {
  background: #fcfcfc;
}
.quotation-form-drawer .ant-drawer-body {
  padding: 0px 30px 40px 30px;
  background: #fcfcfc;
}
.quotation-form-drawer .ant-drawer-header-title {
  justify-content: flex-end;
}
.quotation-form-drawer .ant-drawer-close .desktop-close-button {
  display: block;
}
.quotation-form-drawer .ant-drawer-close .mobile-close-button {
  display: none;
  color: var(--primary-color);
  font-size: 19px;
  font-weight: 400;
}
.quotation-form-drawer .ant-drawer-header {
  padding-left: 30px;
  padding-right: 30px;
  background: #fcfcfc;
  border: none;
}
.quotation-form-drawer .ant-drawer-mask {
  left: -225px;
}
@media (min-width: 992px) {
  .quotation-form-drawer.ant-drawer-open .ant-drawer-content-wrapper {
    width: calc(100vw - 300px) !important;
  }
}
@media (max-width: 992px) {
  .quotation-form-drawer.ant-drawer-open .ant-drawer-content-wrapper {
    width: 100% !important;
  }
  .quotation-form-drawer.ant-drawer-open {
    width: 100% !important;
    top: 65px;
  }
  .quotation-form-drawer .ant-drawer-header {
    padding-top: 40px;
  }
  .quotation-form-drawer .ant-drawer-header-title {
    justify-content: flex-start;
  }
  .quotation-form-drawer .ant-drawer-close {
    padding: 0;
  }
  .quotation-form-drawer .ant-drawer-mask {
    top: -65px;
  }
  .quotation-form-drawer .ant-drawer-close .desktop-close-button {
    display: none;
  }
  .quotation-form-drawer .ant-drawer-close .mobile-close-button {
    display: block;
  }
}

@media (max-width: 575px) {
  .quotation-form-drawer .ant-drawer-body {
    padding: 0 15px 40px 15px;
  }
}
