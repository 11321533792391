
/*=============================================
=                 Main Header                 =
=============================================*/
.main-header {
  width: 100%;
  height: 91px;
  background: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

@media (max-width: 992px) {
  .main-header {
    background: #F8F8F8;
    height: 126px;
  }
}


/*=============================================
=                Desktop View                 =
=============================================*/
.main-header .desktop-view,
.main-header .desktop-view .header-options,
.main-header .desktop-view .navigation-helper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.main-header .desktop-view {
  width: 90%;
  height: 91px;
  border-bottom: 1px solid var(--mariner-color);
  margin: 0 auto;
  justify-content: space-between;
  gap: 15px;
}

@media (max-width: 992px) {
  .main-header .desktop-view {
    display: none;
  }
}

.main-header .desktop-view .header-options {
  justify-content: flex-end;
  gap: 15px;
}

.main-header .desktop-view .navigation-helper {
  justify-content: flex-start;
  gap: 15px;
  width: 100%;
}


/*=============================================
=                 Mobile View                 =
=============================================*/
.main-header .mobile-view {
  background: #fff;
  flex-flow: column;
  justify-content: space-between;
  width: 100%;
  height: 126px;
  padding: 0px 15px;
  
}

.main-header .mobile-view .header-options,
.main-header .mobile-view .navigation-helper {
  width: 100%;
}
.main-header .mobile-view .header-options {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 0;
  left: 0;
  height: 66px;
}
.main-header .mobile-view .navigation-helper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  height: 60px;
}
.main-header .mobile-view .header-options .left-side,
.main-header .mobile-view .header-options .center-side,
.main-header .mobile-view .header-options .right-side {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 66px;
  width: auto;
}
.main-header .mobile-view .header-options .left-side,
.main-header .mobile-view .header-options .right-side {
  position: absolute;
  top: 0;
}
.main-header .mobile-view .header-options .left-side {
  left: 0;
}
.main-header .mobile-view .header-options .right-side {
  right: 0;
}
.main-header .mobile-view .header-options .left-side img {
  cursor: pointer;
  width: 24px;
  height: 24px;
}
.main-header .mobile-view .header-options .center-side img {
  width: 90px;
  height: auto;
  cursor: pointer;
}
.main-header .mobile-view .header-options .right-side {
  gap: 10px;
}


/*=============================================
=              Navigation Helper              =
=============================================*/


/*=============================================
=               current section               =
=============================================*/
.main-header .current-section {
  border: none;
  width: 100%;
}
.main-header .current-section p {
  font-size: 24px;
  line-height: 30px;
  color: var(--mariner-color);
  font-weight: 400;
  margin: 0;
}
@media (max-width: 991px) {
  .main-header .current-section p {
    border-bottom: 2px solid var(--mariner-color);
    padding-bottom: 5px;
    margin-bottom: 5px;
  }
}
/*=====  End of current section  ======*/
/*=============================================
=                   Ratings                   =
=============================================*/
.main-header .rate-display-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 10px;
}
.main-header .rate-display-container .ant-rate {
  color: var(--mariner-color);
  margin-top: -5px;
}
.main-header .rate-display-container p {
  margin-bottom: 0;
  line-height: 30px;
  font-size: 16px;
  font-weight: 600;
  color: #444444;
}
.main-header .rate-display-container .ant-rate .ant-rate-star-zero .ant-rate-star-first,
.main-header .rate-display-container .ant-rate .ant-rate-star-zero .ant-rate-star-second {
  color: #ccc;
}
.main-header .rate-display-container .workshop-name {
  color: var(--mariner-color);
  font-size: 24px;
  line-height: 28px;
  max-width: calc(100% - 310px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.main-header .rate-display-container .ant-btn {
  border-left: 1px solid #444;
  border-radius: 0 !important;
  padding: 0 0 0 10px;
  height: 25px;
}
.main-header .rate-display-container .ant-btn > span {
  line-height: 16px;
  font-size: 16px;
}
.main-header .rate-display-container .rate-display,
.main-header .rate-display-container .action-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.main-header .rate-display-container .rate-display {
  height: 30px;
  width: 300px;
}
@media (max-width: 991px) {
  .main-header .rate-display-container {
    border-bottom: 2px solid var(--mariner-color);
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
  }
  .main-header .rate-display-container .workshop-name {
    display: block;
    width: 100%;
    max-width: 100%;
  }
  .main-header .rate-display-container .rate-display {
    width: 100%;
    justify-content: space-between !important;
    padding-bottom: 5px;
  }
}

/*=====  End of Ratings  ======*/










/*=============================================
=                 Quiz buttons                =
=============================================*/
.main-header .header-options .quiz-button {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: 280px !important;
  height: 50px;
  border-radius: 50px;
  opacity: 1;
}
.main-header .header-options .quiz-button span:not(.anticon) {
  font-size: 16px !important;
  height: 30px;
}
.main-header .desktop-view.dsrStatusCode-3 .quiz-button {
  display: none;
}

@media (max-width: 1290px) {
  .main-header .header-options .quiz-button {
    width: 70px !important;
    justify-content: center;
  }
  .main-header .header-options .quiz-button span:not(.anticon) {
    display: none;
  }
}

.main-header .desktop-view:not(.dsrStatusCode-3) .navigation-helper {
  max-width: calc(100% - 652px) !important;
}
@media (max-width: 1290px) {
  .main-header .desktop-view:not(.dsrStatusCode-3) .navigation-helper {
    max-width: calc(100% - 462px) !important;
  }
}
@media (max-width: 1199px) {
  .main-header .desktop-view:not(.dsrStatusCode-3) .navigation-helper {
    max-width: calc(100% - 228px) !important;
  }
}


/*=============================================
=      dsrStatusCode = 1 -> New Workshop      =
=============================================*/

/*=====  End of dsrStatusCode = 1 -> New Workshop  ======*/

/*=============================================
=       dsrStatusCode = 2 -> In process       =
=============================================*/

/*=====  End of dsrStatusCode = 2 -> In process  ======*/

/*=============================================
=       dsrStatusCode = 3 -> Accepted       =
=============================================*/

/*=====  End of dsrStatusCode = 3 -> Accepted  ======*/

/*=============================================
=       dsrStatusCode = 4 -> Rejected       =
=============================================*/

/*=====  End of dsrStatusCode = 4 -> Rejected  ======*/

/*=============================================
=       dsrStatusCode = 5 -> Blacklist       =
=============================================*/

/*=====  End of dsrStatusCode = 5 -> Blacklist  ======*/




/*=============================================
=            Show rating modal            =
=============================================*/
/* .show-rating-modal {
  width: 95vw !important;
  max-width: 720px;
  top: 92px;
}
.show-rating-modal .ant-modal-content {
  border-radius: 10px;
  background-color: #fff !important;
}
.show-rating-modal .ant-modal-body {
  padding: 40px;
}
@media (max-width: 992px) {
  .show-rating-modal {
    width: 95vw !important;
    max-width: 320px;
    top: 55px;
  }
  .show-rating-modal .ant-modal-body {
    padding: 35px 10px;
  }
} */
/* .show-rating-modal.ck-modal .ant-modal .modal-title {
  color: var(--primary-color);
} */

/*----------  total display  ----------*/
.show-rating-modal .ant-modal-content .total-container {
  width: 98%;
  max-width: 370px;
  margin: 0 auto 15px auto;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  background: var(--light-blue);
}
.show-rating-modal .ant-modal-content .total-container > div {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.show-rating-modal .ant-modal-content .total-container p {
  font-weight: 600;
  color: #444;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 0;
}
.show-rating-modal .ant-modal-content .value {
  margin-left: 10px;
}
@media (max-width: 992px) {
  .show-rating-modal .ant-modal-content .total-container {
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    gap: 0;
  }
}

/*----------  rate  ----------*/
.show-rating-modal .ant-modal-content .ant-rate {
  color: var(--mariner-color);
  margin-top: -5px;
}
.show-rating-modal .ant-modal-content .ant-rate .ant-rate-star-zero .ant-rate-star-first,
.show-rating-modal .ant-modal-content .ant-rate .ant-rate-star-zero .ant-rate-star-second {
  color: #ccc;
}


/*----------  average display  ----------*/
.show-rating-modal .ant-modal-content .averages-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 15px;
  max-width: 450px;
  margin: 0 auto 0 35px;
}
.show-rating-modal .ant-modal-content .average,
.show-rating-modal .ant-modal-content .average > div {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.show-rating-modal .ant-modal-content .average p {
  font-size: 16px;
  margin-bottom: 0;
}
.show-rating-modal .ant-modal-content .label {
  font-weight: 600;
}
@media (max-width: 992px) {
  .show-rating-modal .ant-modal-content .averages-container {
    width: 100%;
    margin: 0 10px;
    align-items: flex-start;
  }

  .show-rating-modal .ant-modal-content .average {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
}
/*=====  End of Show rating modal  ======*/

