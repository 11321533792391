.terms-button-landing-footer span {
    color: #FFFFFF !important;
}
.terms-button-landing-footer span:focus,
.terms-button-landing-footer span::selection,
.terms-button-landing-footer span:hover {
    color: #FFFFFF !important;
    text-decoration: underline;
}
.carker-logo-bw-subtitle {
    font-size: 12px;
    font-weight: 600;
}
.landing-footer-first-container {
    background-color: #444444;
    color: #FFF;
    padding: 15px 5% 15px 5%;
    z-index: 10000000;
}
@media only screen and (max-width: 992px) {
    .carker-logo-bw-subtitle {
        text-align: center;
    }
    .bw-logo-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .landing-footer-first-container {
        align-items: center;
        justify-content: center;
    }
}
