.workshop-service-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.workshop-service-form .form-section {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
}

.workshop-service-form .form-section h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 5px;
}
.workshop-service-form .form-section h3 span {
  background-color: var(--mariner-color);
  color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: inline-block;
  line-height: 22px;
  text-align: center;
  font-size: 14px;
  margin-right: 5px;
}
.workshop-service-form .form-section sup {
  color: var(--mariner-color);
}
.workshop-service-form.new-service h3 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.workshop-service-form.edit-service h3 span,
.workshop-service-form.edit-service h3 sup {
  display: none;
}
.workshop-service-form .form-section .info-container {
  display: flex;
  margin-bottom: 10px;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
}
.workshop-service-form .form-section .info-container p {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 4px;
}
.workshop-service-form .form-section .info-container p b {
  font-weight: 600;
}
.workshop-service-form .form-section .info-container .ck-icon {
  width: 18px;
  height: 18px;
  color: var(--mariner-color);
}
.workshop-service-form .form-section .ant-form-item-explain > div {
  font-size: 12px !important;
  line-height: 15px !important;
}
.workshop-service-form .form-section .ant-form-item {
  margin-bottom: 0;
}
.workshop-service-form .form-section .ant-input-affix-wrapper {
  border-radius: 4px;
  border: 1px solid var(--gray-300);
}
.workshop-service-form .form-section .ant-radio {
  display: none;
}

/* ==========================================================================
  Specific for service name
  ========================================================================== */
/*
.workshop-service-form .form-section.service-name {
  margin-bottom: 30px;
}
*/

/* ==========================================================================
  Specific for service type
  ========================================================================== */
.workshop-service-form .form-section.service-type .info-container {
  margin-bottom: 0;
}
.workshop-service-form .form-section.service-type .ant-radio-wrapper {
  border: 1px solid var(--black-15);
  margin: 0;
  width: 124px;
  text-align: center;
  height: 32px;
  line-height: 30px;
}
.workshop-service-form
  .form-section.service-type
  .ant-radio-wrapper
  span:not(.ant-radio) {
  margin: 0 auto;
  color: #000;
}
.workshop-service-form .form-section.service-type .ant-form-item {
  margin: 10px 0 5px 0;
}

.workshop-service-form
  .form-section.service-type
  .ant-radio-group
  .ant-radio-wrapper:first-child {
  border-radius: 2px 0 0 2px;
}
.workshop-service-form
  .form-section.service-type
  .ant-radio-group
  .ant-radio-wrapper:last-child {
  border-radius: 0 2px 2px 0;
}
.workshop-service-form
  .form-section.service-type
  .ant-radio-wrapper.ant-radio-wrapper-checked {
  background-color: var(--mariner-color);
  border-color: var(--mariner-color);
}
.workshop-service-form
  .form-section.service-type
  .ant-radio-wrapper.ant-radio-wrapper-checked
  span:not(.ant-radio) {
  color: #fff;
}
/* 2px, 0px, 0px, 2px */

/* ==========================================================================
  Specific for service category
  ========================================================================== */
.workshop-service-form .form-section.service-category .ant-radio-wrapper {
  margin: 0 10px 10px 0;
}
.workshop-service-form .form-section.service-category .hidden {
  display: none;
}
.workshop-service-form .form-section .category-service-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 126px;
  height: 92px;
  border: 1px solid var(--gray-200);
  padding: 10px;
  gap: 5px;
  border-radius: 4px;
  transition: all 0.5s ease;
}
.workshop-service-form .form-section .category-service-card .ck-icon {
  color: var(--primary-color);
}
.workshop-service-form .form-section .category-service-card p {
  font-size: 16px;
  line-height: 20px;
  color: var(--text-color);
  margin-bottom: 0;
}
.workshop-service-form .form-section .selector-service-category {
  display: flex;
  gap: 10px;
  margin-bottom: 5px;
}
.workshop-service-form .form-section.service-category .ant-radio-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.workshop-service-form
  .form-section.service-category
  .ant-radio-wrapper.ant-radio-wrapper-checked
  .category-service-card,
.workshop-service-form
  .form-section.service-category
  .ant-radio-wrapper
  .category-service-card:hover {
  background-color: var(--mariner-color);
}
.workshop-service-form
  .form-section.service-category
  .ant-radio-wrapper.ant-radio-wrapper-checked
  .category-service-card
  .ck-icon,
.workshop-service-form
  .form-section.service-category
  .ant-radio-wrapper.ant-radio-wrapper-checked
  .category-service-card,
.workshop-service-form
  .form-section.service-category
  .ant-radio-wrapper
  .category-service-card:hover
  .ck-icon,
.workshop-service-form
  .form-section.service-category
  .ant-radio-wrapper
  .category-service-card:hover
  p {
  color: #fff !important;
}
.workshop-service-form
  .form-section
  .ant-radio-wrapper.ant-radio-wrapper-checked
  .category-service-card
  p {
  color: #fff !important;
}
.workshop-service-form .form-section.service-category .vehicle-zones p {
  margin-bottom: 5px;
}
.workshop-service-form
  .form-section.service-category
  .vehicle-zones
  .ant-checkbox-wrapper {
  border: 1px solid var(--gray-300);
  border-radius: 6px;
  margin: 0 10px 10px 0;
  transition: all 0.5s ease;
}
.workshop-service-form
  .form-section.service-category
  .vehicle-zones
  .ant-checkbox-wrapper
  .ant-checkbox {
  display: none;
}
.workshop-service-form
  .form-section.service-category
  .vehicle-zones
  .ant-checkbox-wrapper
  span:not(.ant-checkbox) {
  height: 36px;
  padding: 10px;
  line-height: 16px;
}
.workshop-service-form
  .form-section.service-category
  .vehicle-zones
  .ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
  background-color: var(--light-blue);
}
.workshop-service-form
  .form-section.service-category
  .vehicle-zones
  .ant-checkbox-wrapper.ant-checkbox-wrapper-checked
  span:after {
  content: url("data:image/svg+xml,%3Csvg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1 1.5L13 13.5L1 1.5Z' fill='%23001450'/%3E%3Cpath d='M1 1.5L13 13.5' stroke='%23001450' stroke-width='2'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1 13.5L7 7.5L13 1.5' fill='%23001450'/%3E%3Cpath d='M1 13.5L7 7.5L13 1.5' stroke='%23001450' stroke-width='2'/%3E%3C/svg%3E%0A");
  display: inline-block; /* Align properly with the text */
  width: 14px; /* Width of the SVG icon */
  height: 15px; /* Height of the SVG icon */
  margin-left: 8px; /* Space between the text and the SVG icon */
}

/* ==========================================================================
  Specific for maintenance and repair
  ========================================================================== */
.workshop-service-form .form-section .cta {
  margin-bottom: 15px;
  font-style: italic;
  font-size: 16px;
  line-height: 20px;
}
.workshop-service-form .form-section .tier-container {
  margin-bottom: 15px;
}
.workshop-service-form .form-section .tier-container .ant-checkbox-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  height: 22px;
}
.workshop-service-form .form-section .tier-container .tier-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  margin-bottom: 5px;
}
.workshop-service-form
  .form-section
  .tier-container
  .tier-title-container
  .ant-checkbox-wrapper {
  width: 20px;
}
.workshop-service-form
  .form-section
  .tier-container.optional
  .tier-title-container {
  margin-bottom: 8px;
}
.workshop-service-form .form-section .tier-container .tier-title-container p {
  line-height: 21px;
  font-size: 16px;
  margin-bottom: 0;
}
.workshop-service-form
  .form-section
  .tier-container
  .tier-title-container
  p
  span {
  color: var(--mariner-color);
  font-weight: 600;
}
.workshop-service-form
  .form-section
  .tier-container
  .tier-title-container
  .ant-form-item-control-input {
  min-height: 20px;
  height: 20px;
}
.workshop-service-form
  .form-section
  .tier-container
  .tier-title-container
  .ck-icon,
.workshop-service-form
  .form-section
  .tier-container
  .tier-title-container
  .anticon {
  color: var(--mariner-color) !important;
}
.workshop-service-form .form-section .tier-container .tier-tooltip {
  z-index: 6000 !important;
}
.workshop-service-form
  .form-section
  .tier-container
  .tier-tooltip
  .ant-tooltip-inner {
  background-color: #404040;
  border-radius: 2px;
}
.workshop-service-form .form-section .tier-container .tier-fields-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.workshop-service-form .form-section .tier-container .tier-field-label {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  margin-bottom: 10px !important;
  color: var(--primary-color);
  width: 100%;
  border-bottom: 1px solid var(--gray-300);
  padding-bottom: 5px;
  margin-top: 0 !important;
}
.workshop-service-form .form-section .tier-container .tier-field-label sup {
  font-weight: 600;
  color: var(--mariner-color);
}
.workshop-service-form .form-section .tier-container .tier-field-label span {
  color: var(--text-color);
  font-weight: 400;
  font-style: italic;
}
.workshop-service-form
  .form-section
  .tier-container
  .tier-fields-container
  .price-container
  p {
  margin-bottom: 5px;
}
.workshop-service-form
  .form-section
  .tier-container
  .tier-fields-container
  .price-container
  p:not(.info) {
  margin-top: 5px;
  margin-bottom: 0px;
  font-size: 14px;
  line-height: 18px;
  color: var(--primary-color);
}
.workshop-service-form
  .form-section
  .tier-container
  .tier-fields-container
  .price-container
  p.info {
  margin-bottom: 0px;
  font-size: 12px;
  line-height: 16px;
  margin-top: 5px;
  max-width: 165px;
}
.workshop-service-form
  .form-section
  .tier-container
  .tier-fields-container
  .ant-form-item
  .ant-input-number
  input {
  height: 40px !important;
  font-size: 32px;
  line-height: 38px;
  width: 150px;
  font-weight: 600;
  color: var(--text-color);
}
.workshop-service-form
  .form-section
  .tier-container
  .tier-fields-container
  .ant-form-item
  .ant-input-number
  input::placeholder {
  color: var(--gray-300);
}
.workshop-service-form
  .form-section
  .tier-container
  .tier-fields-container
  .ant-form-item
  .ant-input-number {
  border-radius: 4px !important;
  border: 1px solid var(--gray-300);
  width: auto;
}
.workshop-service-form
  .form-section
  .tier-container
  .tier-fields-container
  .use-cases-container
  .ant-checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: -5px;
}
.workshop-service-form
  .form-section
  .tier-container
  .tier-fields-container
  .use-cases-container
  .add-cases-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
  margin-top: 12px;
}
.workshop-service-form
  .form-section
  .tier-container
  .tier-fields-container
  .use-cases-container
  .add-cases-container
  div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  justify-content: flex-start;
}
.workshop-service-form
  .form-section
  .tier-container
  .tier-fields-container
  .use-cases-container
  .add-cases-container
  .ck-button {
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
}
.workshop-service-form
  .form-section
  .tier-container
  .tier-fields-container
  .use-cases-container
  .add-cases-container
  .ck-button
  span {
  color: var(--mariner-color);
}
.workshop-service-form
  .form-section
  .tier-container
  .tier-fields-container
  .use-cases-container
  .add-cases-container
  .ck-button.remove-button {
  background-color: #cccccc !important;
  border: 1px solid #ccc;
  width: 24px;
  min-width: 24px;
  height: 24px;
  min-height: 24px;
  padding: 0;
  margin-left: -2px;
}
.workshop-service-form
  .form-section
  .tier-container
  .tier-fields-container
  .use-cases-container
  .add-cases-container
  .ck-button.remove-button
  span {
  color: #fff;
}
.workshop-service-form
  .form-section
  .tier-container
  .tier-fields-container
  .use-cases-container
  .add-cases-container
  .ck-button.remove-button
  svg {
  width: 12px !important;
}
.workshop-service-form
  .form-section
  .tier-container
  .tier-fields-container
  .use-cases-container
  .add-cases-container
  p {
  margin: 0 0 0 4px;
  font-size: 14px;
  line-height: 18px;
}
.workshop-service-form
  .form-section
  .tier-container
  .tier-fields-container
  .use-cases-container
  .add-cases-container
  input {
  height: 40px;
  padding: 10px;
  border-radius: 4px !important;
  font-size: 14px;
  color: var(--primary-color);
}
.workshop-service-form
  .form-section
  .tier-container
  .tier-fields-container
  .use-cases-container
  .add-cases-container
  input::placeholder {
  color: var(--gray-400);
}
.workshop-service-form
  .form-section
  .tier-container
  .tier-fields-container
  > div {
  width: 100%;
}
.workshop-service-form
  .form-section.service-rep
  .tier-container
  .price-container {
  max-width: 200px;
}
.workshop-service-form .form-section .tier-container textarea {
  width: 90%;
}
.workshop-service-form
  .form-section.service-rep
  .tier-container
  .use-cases-container {
}
.workshop-service-form
  .form-section.service-rep
  .tier-container
  .comments-container {
}
@media (max-width: 1920px) {
  .workshop-service-form
    .form-section.service-rep
    .tier-container
    .price-container {
    max-width: 300px;
  }
}
@media (max-width: 992px) {
  .workshop-service-form .form-section .tier-container .tier-fields-container {
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 20px;
    border-top: 1px solid var(--gray-300);
    padding: 10px 0px;
    margin-top: 10px;
  }
  .workshop-service-form .form-section .tier-container .tier-field-label {
    border-bottom: unset;
    padding-bottom: 0px;
    margin-bottom: 5px;
  }
  /* 
  ==========================================================================
                              Specific for repair
  ========================================================================== 
  */
  .workshop-service-form
    .form-section.service-rep
    .tier-container
    .use-cases-container {
    order: 1;
  }
  .workshop-service-form
    .form-section.service-rep
    .tier-container
    .price-container {
    order: 2;
  }
  .workshop-service-form
    .form-section.service-rep
    .tier-container
    .comments-container {
    order: 3;
  }
}
