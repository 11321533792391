
.quiz-drawer.ant-drawer-open .ant-drawer-content-wrapper {
  width: calc(100vw - 61px) !important;
} 
.expanded-menu .quiz-drawer.ant-drawer-open .ant-drawer-content-wrapper {
  width: calc(100vw - 300px) !important;
}
@media (max-width: 992px) {
  .quiz-drawer.ant-drawer-open .ant-drawer-content-wrapper {
    width: 100vw !important;
  }
}
.quiz-drawer .ant-drawer-header-title {
  justify-content: center;
  flex-direction: row-reverse;
}
.quiz-drawer .ant-drawer-header {
  border: none !important;
  padding: 50px 5% 0px 5%;
}
.quiz-drawer .ant-drawer-header h3 {
  margin-bottom: 0 !important;
  color: var(--mariner-color);
  font-size: 24px;
  line-height: 30px;
  width: 100%;
  border-bottom: 2px solid var(--mariner-color);
  padding-bottom: 15px;
  font-weight: 400;
}
.quiz-drawer .display-position,
.quiz-container .display-position {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}
.quiz-drawer .display-position > div,
.quiz-container .display-position > div {
  width: 18%;
  background: #ccc;
  height: 6px;
  border-radius: 6px;
  max-width: 190px;
  transition: all 0.5s ease;
}
.quiz-drawer .display-position > div.active,
.quiz-container .display-position > div.active {
  background: var(--mariner-color);
}

.quiz-drawer .ant-drawer-body {
  padding: 30px 5% 0 5%;
}
.quiz-drawer .ant-drawer-body h6,
.quiz-container h6 {
  color: var(--primary-color);
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 16px;
}
.quiz-drawer .ant-drawer-body .ant-checkbox-wrapper span:not(.ant-checkbox),
.quiz-drawer .ant-drawer-body p,
.quiz-container .ant-checkbox-wrapper span:not(.ant-checkbox),
.quiz-container p {
  color: #444;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}
.quiz-drawer .ant-drawer-body .ant-radio-wrapper,
.quiz-container .ant-radio-wrapper {
  margin-bottom: 10px !important;
}
.quiz-drawer .ant-drawer-body .ant-radio-wrapper span:not(.ant-radio),
.quiz-container .ant-radio-wrapper span:not(.ant-radio) {
  color: #444;
  font-size: 16px;
  line-height: 20px;
}
.quiz-drawer .ant-drawer-body .question-container,
.quiz-container .question-container {
  margin-bottom: 15px;
}
.quiz-drawer .ant-drawer-body .question-container p,
.quiz-container .question-container p {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 5px;
}


/*=============================================
=                   Footer                    =
=============================================*/
.quiz-drawer .ant-drawer-footer {
  border: 0;
  padding: 0;
}
.quiz-drawer .quiz-footer {
  padding: 10px 5% 20px 5%;
}

/*=============  End of Footer  ==============*/




.quiz-drawer .buttons-container,
.quiz-container .buttons-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
  gap: 120px;
}

.quiz-drawer .buttons-container .ant-btn.ant-btn-default,
.quiz-container .buttons-container .ant-btn.ant-btn-default {
  width: 240px;
}

.quiz-drawer
  .buttons-container
  .ant-btn.ant-btn-default.ant-btn-loading,
.quiz-container
  .buttons-container
  .ant-btn.ant-btn-default.ant-btn-loading {
  width: 280px;
}
.quiz-drawer .buttons-container .ant-btn.ant-btn-link, 
.quiz-container .buttons-container .buttons-container .ant-btn.ant-btn-link {
  width: auto;
  height: 30px;
  padding: 0;
}
.quiz-drawer
  .buttons-container
  .ant-btn.ant-btn-link
  > span,
.quiz-container
  .buttons-container
  .ant-btn.ant-btn-link
  > span {
  color: var(--primary-color);
  text-decoration: underline;
}
.quiz-drawer .buttons-container .ant-btn > span,
.quiz-container .buttons-container .ant-btn > span {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.quiz-drawer .quiz-footer h3,
.quiz-container .quiz-footer h3 {
  color: var(--mariner-color);
  margin-top: 15px;
  text-align: center;
  transition: all 0.5s ease;
  font-size: 16px !important;
  line-height: 20px !important;
  height: auto;
}
.quiz-drawer .quiz-footer h3.hide,
.quiz-container .quiz-footer h3.hide {
  opacity: 0;
  display: none;
}

@media (max-width: 576px) {
  .quiz-drawer .quiz-footer {
    padding: 10px 5%;
  }
  .quiz-drawer .quiz-footer .buttons-container {
    flex-direction: column;
    gap: 10px;
  }
}

.quiz-drawer .carousel-container,
.quiz-container .carousel-container {
  width: 100%;
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

.quiz-drawer .carousel-container .slick-slider.slick-initialized.quiz-carousel > .slick-list > .slick-track > .slick-slide > div,
.quiz-container .carousel-container .slick-slider.slick-initialized.quiz-carousel > .slick-list > .slick-track > .slick-slide > div {
  display: block !important;
  margin: 0 auto !important;
  width: 98% !important;
  padding: 0 10px !important;
}

/* .quiz-drawer .ant-carousel,
.quiz-container .ant-carousel {
  width: 95%;
  display: block;
  margin: 5px auto;
  height: auto;
} */

/* 
.quiz-drawer .ant-carousel .slick-list .slick-slide > div > div,
.quiz-container .ant-carousel .slick-list .slick-slide > div > div {
  width: 95% !important;
  margin: 0 auto;
  display: block !important;
  padding: 10px 20px;
}
*/




/*================================================
=            Quiz confirmation modal             =
================================================*/
.quiz-confirmation-modal {
  width: 720px !important;
  top: 91px;
}
.quiz-confirmation-modal .ant-modal-content {
  background-color: #fff !important;
  border-radius: 10px;
  overflow: hidden;
}
@media (max-width: 992px) {
  .quiz-confirmation-modal {
    top: 113px;
    width: 95% !important;
    min-width: 320px !important;
  }
}
.quiz-confirmation-modal .ant-modal-body {
  padding: 40px;
}
.quiz-confirmation-modal .ant-drawer-header-title {
  justify-content: flex-end;
}
.quiz-confirmation-modal .ant-modal-body .anticon {
  color: var(--primary-color);
  text-align: center;
  margin: 0 auto 20px auto;
  display: block;
  font-size: 3rem;
}
.quiz-confirmation-modal .ant-modal-body h3 {
  text-align: center;
  font-size: 24px !important;
  line-height: 30px !important;
  font-weight: 600;
  margin-bottom: 15px;
  color: var(--primary-color);
}
.quiz-confirmation-modal .ant-modal-body p {
  color: #444 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  font-weight: 600;
  max-width: 600px;
  margin: 0 auto 10px auto !important;
  text-align: center;
}
.quiz-confirmation-modal .ant-modal-body p > a {
  color: var(--primary-color);
}
.quiz-confirmation-modal .ant-modal-close {
  top: 20px;
  right: 20px;
  height: 20px;
  width: 20px;
}
.quiz-confirmation-modal .ant-modal-close .ant-modal-close-x,
.quiz-confirmation-modal .ant-modal-close svg {
  width: 20px;
  height: 20px;
  line-height: 20px;
}


/*==_===  End of Quiz confirmation modal  =_=====*/

