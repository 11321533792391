/*=========================================================
=            Main Dashboard Appointment Status            =
=========================================================*/
.md-appointment-stats {
  margin: 20px auto;
}
.md-appointment-stats h2 {
  color: var(--primary-color);
  font-size: 24px !important;
  line-height: 28px !important;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}
.md-appointment-stats h2 span {
  color: var(--primary-color);
  cursor: pointer;
  font-size: 20px;
}

.md-appointment-stats .boxes-container {
  display: flex;
  /* gap: 8px; */
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
.md-appointment-stats .stats-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  color: #fff;
  background-color: var(--mariner-color);
  border-radius: 10px;
  font-weight: 600;
  text-align: center;
  width: calc(25% - 4px);
  min-height: 100px;
  height: auto;
  object-fit: contain;
  padding: 10px 5px;
}
.md-appointment-stats .stats-box .ant-skeleton-title {
  height: 58px;
  margin: 10px auto;
}
.md-appointment-stats .stats-box .value {
  line-height: 70px;
  font-size: 64px !important;
  font-weight: 700 !important;
  margin: 0;
  text-align: center;
}
.md-appointment-stats .stats-box .value.medium {
  line-height: 64px;
  font-size: 48px !important;
}
.md-appointment-stats .stats-box .label {
  font-size: 20px !important;
  line-height: 24px;
  font-weight: 600 !important;
  margin: 0;
  height: 48px;
  text-align: center;
}
@media (max-width: 1200px) {
  .md-appointment-stats .stats-box {
    width: calc(50% - 4px);
    margin-bottom: 8px;
  }
}
/*=====  End of Main Dashboard Appointment Status  ======*/

.element { hex: #001450 }
.element { hex: #2369BD }
.element { hex: #F40672 }
.element { hex: #F702C9 }
.element { hex: #9747FF }
.element { hex: #069C8A }
.element { hex: #047DA3 }
.element { hex: #013A7D }
.element { hex: #B7016E }
