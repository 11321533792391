
/*======================================================
=            Main Dashboard Unread Messages            =
======================================================*/
@media (min-width: 1200px) {
  .dashboard-box.md-unread-messages {
    min-height: 328px !important;
    padding: 30px 22px !important;
  }
  .dashboard-box.md-unread-messages .box-footer .ant-btn {
    margin-top: 45px !important;
  }
}
/*=====  End of Main Dashboard Unread Messages  ======*/

