.screenshot-main-video #clickable-btn-link-video-b:hover {
    animation: grow 8s ease-in infinite;
    fill: rgb(0, 20, 80);
}
@keyframes grow {
    0% {
        font-size: 3em;
    }
    50% {
        font-size: 8em;
    }
    100% {
        font-size: 3em;
    }
}
@media (min-width: 911px) {
    min-height: 480px !important;
}
