.benefit-item-container {
    height: 189px;
    max-width: 412px;
    background-color: #EFF6FF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 10px 20px 0;
    border-radius: 8px;
    padding: 15px;
}
.benefit-item-text {
    font-size: 20px;
    text-align: center;
}
.benefit-section-main-title {
    font-size: 32px;
    color: #2369BD;
}
.benefits-main-container {
    padding: 20px;
    margin: 0 5% 0 5%;
}
@media (max-width: 992px) {
    .benefit-item-container {
        height: 200px;
        max-width: 162px;
        margin: 0 5px 20px 0;
    }
    .benefit-item-text {
        font-size: 16px;
    }
    .benefit-item-icon svg {
        max-width: 55px;
        height: auto;
    }
    .benefit-section-main-title {
        font-size: 24px;
    }
    .benefits-main-container {
        margin: 0;
    }
}
