.name-field {
  margin: 0px !important;
}
.delete-table-action {
  fill: red !important;
}

.client-table-actions {
  padding: 50px 0px 10px 0px;
}
.client-table-actions .search-container {
  flex-wrap: wrap !important;
  justify-content: flex-start !important;
  gap: 10px;
  padding-left: 8px;
}
.client-table-actions .search-container .search-input-client-list {
  flex: 1 !important;
  max-width: 240px;
  min-width: 140px;
  margin: 0px !important;
}
.client-table-actions .search-container .filter {
  flex: 1 !important;
  max-width: 103px;
  margin-left: 10px;
}
.client-table-actions .search-container .filter p {
  font-size: 14px;
  font-weight: 400;
  color: #001450;
  margin: 0px;
}
.client-table-actions .search-container .filter svg {
  max-width: 20px;
  max-height: 20px;
}
.client-table-actions .search-container .order {
  flex: 1 !important;
  max-width: 119px;
}
.client-table-actions .search-container .order p {
  font-size: 14px;
  font-weight: 400;
  color: #001450;
  margin: 0px;
}
.client-table-actions .search-container .order svg {
  max-width: 20px;
  max-height: 20px;
}
.client-list-table-row .ant-checkbox-wrapper-disabled {
  display: none;
}
.client-list-filter-modal .actions-container {
  flex-direction: column;
}
.search-input-client-list {
  border: none;
  border-radius: 0;
}
.client-list-table .ant-table-summary {
  background-color: #e6f7ff;
}

@media only screen and (max-width: 992px) {
  .client-table-actions {
    display: flex;
    flex-direction: column-reverse !important;
    width: 100%;
    gap: 10px;
  }
  .client-table-actions .sub-search {
    min-width: 100%;
  }
  .client-table-actions .search-container .filter {
    margin-left: auto !important;
  }
  .client-table-actions .sub-actions {
    min-width: 100%;
  }
}
