.my-workshops-container .list-container .workshop-card-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  border: 0px solid #cccccc;
  border-bottom: 1px solid #cccccc !important;
  border-style: dashed !important;
  width: 100%;
  height: auto !important;
  min-height: 225px !important;
  padding: 20px 0px !important;
}
.my-workshops-container .for-web {
  display: flex !important;
}
.my-workshops-container .for-mob {
  display: none !important;
}
.my-workshops-container
  .list-container
  .workshop-card-container
  .ws-card-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 50px !important;
  border-radius: 6px !important;
  margin-bottom: 5px !important;
  padding: 5px 10px !important;
  background-color: rgba(47, 102, 242, 0.1);
}
.my-workshops-container
  .list-container
  .workshop-card-container
  .ws-card-header
  .switch-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 10px;
  height: 100%;
}
.my-workshops-container
  .list-container
  .workshop-card-container
  .ws-card-header
  .switch-container
  p {
  font-size: 16px;
  line-height: 16px;
  font-family: "Emprint-semibold";
  color: #444444;
  margin: 0px;
}
.my-workshops-container
  .list-container
  .workshop-card-container
  .ws-card-header
  .switch-container
  .ant-switch {
  border-radius: 10px !important;
}
.my-workshops-container
  .list-container
  .workshop-card-container
  .ws-card-divisor {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
.my-workshops-container
  .list-container
  .workshop-card-container
  .ws-card-divisor
  .ws-info {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  width: 100%;
  gap: 20px;
}
.my-workshops-container
  .list-container
  .workshop-card-container
  .ws-card-divisor
  .ws-info
  .ws-logo {
  width: 180px;
  height: 180px;
  border-radius: 6px;
  border: 1px solid #cccccc;
  background-color: white;
}
.my-workshops-container
  .list-container
  .workshop-card-container
  .ws-card-divisor
  .ws-info
  .ws-logo.logo-no-borde {
  border: none !important;
}
.my-workshops-container
  .list-container
  .workshop-card-container
  .ws-card-divisor
  .ws-info
  .name-more {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  width: 100%;
}
.my-workshops-container
  .list-container
  .workshop-card-container
  .ws-card-divisor
  .ws-info
  .ws-name {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;
}
.my-workshops-container
  .list-container
  .workshop-card-container
  .ws-card-divisor
  .ws-info
  .ws-name
  .name {
  flex: 1;
  font-size: 24px;
  line-height: 29px;
  font-family: "Emprint-semibold";
  color: var(--primary-color);
  margin: 0px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ws-pr-rate {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  gap: 5px;
}
.ws-pr-text-rate {
  font-size: 14px;
  font-family: "Emprint-Semibold";
  text-align: start;
  text-decoration: underline;
  color: var(--primary-color);
  margin: 0px;
}
.carker-rate {
  color: var(--mariner-color);
}
.my-workshops-container
  .list-container
  .workshop-card-container
  .ws-card-divisor
  .ws-info
  .ws-name
  .desc {
  font-size: 16px;
  line-height: 20px;
  font-family: "Emprint-regular";
  color: #444444;
  margin-bottom: 5px;
}
.my-workshops-container .list-container .workshop-card-container .ws-options {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  gap: 10px;

  margin-top: 10px;
}
.my-workshops-container
  .list-container
  .workshop-card-container
  .ws-card-divisor
  .ws-status {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
}
.my-workshops-container
  .list-container
  .workshop-card-container
  .ws-card-divisor
  .ws-status
  p {
  font-size: 16px;
  line-height: 16px;
  font-family: "Emprint-semibold";
  color: #444444;
  margin: 0px;
}
.my-workshops-container
  .list-container
  .workshop-card-container
  .ws-card-divisor
  .ws-status
  .ant-progress {
  font-size: 16px;
  line-height: 20px;
  font-family: "Emprint-semibold";
  margin: 0px !important;
}
.my-workshops-container
  .list-container
  .workshop-card-container
  .ws-card-divisor
  .ws-status
  .ant-progress
  .ant-progress-outer
  .ant-progress-inner {
  height: 13px !important;
  border-radius: 0px !important;
}
.my-workshops-container
  .list-container
  .workshop-card-container
  .ws-card-divisor
  .ws-status
  .ant-progress
  .ant-progress-outer
  .ant-progress-inner
  .ant-progress-bg {
  height: 13px !important;
  border-radius: 0px !important;
}
.my-workshops-container
  .list-container
  .workshop-card-container
  .ws-options
  .ws-buttons {
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.my-workshops-container
  .list-container
  .workshop-card-container
  .ws-options
  .ws-buttons
  .borded-btn {
  flex: 1;
  height: 40px !important;
  border: 2px solid #2f66f2 !important;
  background-color: white !important;
}
.my-workshops-container
  .list-container
  .workshop-card-container
  .ws-options
  .ws-buttons
  .borded-btn
  span {
  font-size: 16px;
  font-family: "Emprint-semibold";
  color: var(--primary-color);
}
.my-workshops-container
  .list-container
  .workshop-card-container
  .ws-options
  .ws-buttons
  .blue-btn {
  flex: 1;
  height: 40px !important;
  border: 2px solid #2f66f2 !important;
  background-color: #2f66f2 !important;
}
.my-workshops-container
  .list-container
  .workshop-card-container
  .ws-options
  .ws-buttons
  .blue-btn
  span {
  font-size: 16px;
  font-family: "Emprint-semibold";
  color: white;
}
.my-workshops-container .list-container .workshop-card-container .icon-btn {
  align-items: center;
  justify-content: center;
  border: none;
  margin: 0px;
  background-color: transparent;
}
.my-workshops-container
  .list-container
  .workshop-card-container
  .icon-btn
  span {
  font-size: 25px !important;
  color: var(--primary-color);
}
.ws-popover-content {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 10px !important;
  padding: 10px 10px 10px 10px !important;
  border-radius: 10px !important;
}
.ws-popover-content .icon-btn {
  font-size: 16px !important;
  font-family: "Emprint-semibold";
  text-align: start !important;
  color: var(--primary-color) !important;

  border: none !important;
  box-shadow: none !important;

  padding: 5px 5px !important;
  width: 100%;
}
.ws-pending {
  width: 100%;
  min-height: 40px;
}
.ws-pending-scroll {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  overflow-x: auto !important;
  /* scrollbar-width: none;
  -ms-overflow-style: none; */

  width: 100%;
  max-width: calc(960px - 180px - 20px);

  gap: 5px;
}
/* .ws-pending-scroll::-webkit-scrollbar {
  display: none;
} */

.ws-pending-scroll .pending-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  min-width: 480px;
  max-width: 480px;
  padding: 5px 10px 5px 10px;
  border: 1px solid #ccc;
  gap: 10px;
}
.ws-pending-scroll .pending-item p {
  font-size: 16px;
  color: #444444;
  font-family: "EMprint-Semibold";
  margin: 0px;
}
.ws-pending-scroll .pending-item .anticon {
  color: var(--primary-color);
  font-size: 22px !important;
}

@media only screen and (max-width: 1320px) {
  .ws-pending-scroll {
    max-width: calc(100vw - 300px - 90px - 40px - 120px);
  }
}

@media only screen and (max-width: 1100px) {
  .my-workshops-container .for-web {
    display: none !important;
  }
  .my-workshops-container .for-mob {
    display: flex !important;
  }
  .my-workshops-container
    .list-container
    .workshop-card-container
    .ws-card-divisor
    .ws-info
    .ws-logo {
    width: 70px;
    height: 70px;
  }
  .my-workshops-container
    .list-container
    .workshop-card-container
    .ws-card-divisor
    .ws-info
    .name-more {
    width: calc(100vw - 300px - 90px - 40px) !important;
  }
  .ws-pr-rate {
    display: none;
  }
  .my-workshops-container .list-container .workshop-card-container .ws-options {
    flex-direction: column-reverse;
  }
  .my-workshops-container
    .list-container
    .workshop-card-container
    .ws-options
    .ws-status {
    width: 100%;
  }
  .my-workshops-container
    .list-container
    .workshop-card-container
    .ws-options
    .ws-status {
    width: 100%;
  }
  .my-workshops-container
    .list-container
    .workshop-card-container
    .ws-options
    .ws-buttons {
    width: 100%;
  }
  .ws-pending-scroll {
    max-width: calc(100vw - 40px);
  }
  .ws-pending-scroll .pending-item {
    min-width: 340px;
    max-width: 340px;
  }
}

@media only screen and (max-width: 992px) {
  .my-workshops-container
    .list-container
    .workshop-card-container
    .ws-card-divisor
    .ws-info
    .name-more {
    width: calc(100vw - 90px - 40px) !important;
  }
  .ws-pending-scroll .pending-item {
    min-width: 45%;
    max-width: 45%;
  }
}
@media only screen and (max-width: 762px) {
  .ws-pending-scroll .pending-item {
    min-width: 286px;
    max-width: 286px;
  }
}
