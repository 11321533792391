.workshop-switch {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  object-fit: contain;
  width: 100%;
}
@media (max-width: 991px) {
  .workshop-switch {
    /* padding-left: 15px; */
  }
}
.workshop-switch .ant-select {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}
.workshop-switch .workshop-select {
  gap: 20px !important;
  width: auto;
}
.workshop-switch .ant-select.--custom.workshop-select .ant-select-selector {
  font-size: 24px !important;
  line-height: 29.45px !important;
  color: var(--mariner-color) !important;
  font-weight: 600 !important;

  padding: 0px 20px 0px 0px !important;
  border: none !important;
}
.workshop-switch .workshop-select .ant-select-arrow {
  font-size: 14px !important;
  color: var(--mariner-color) !important;
}

@media (max-width: 991px) {
  .workshop-switch {
    width: 100% !important;
    max-width: 100% !important;
    min-height: 41.67px;
    max-height: 41.67px;;
  }
  .workshop-switch .ant-select {
    width: 100% !important;
    border-bottom: 2px solid var(--mariner-color) !important;
  }
}

.workshop-switch-popover {
  z-index: 2200;
  width: 300px !important;
}
