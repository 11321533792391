.tool-section-title {
    font-size: 32px;
    font-weight: 600;
}
.tool-section-text {
    font-size: 24px;
}
.white-text {
    color: #FFFFFF;
}
.gray-text {
    color: #F9F9F9;
}
.blue-text {
    color: #001450;
}
.screenshot-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.tool-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10%;
}
.tool-section-main-title {
    font-size: 32px;
    color: #2369BD;
}
.tool-section-main-subtitle {
    font-size: 20px;
}
.ws-tools-main-container {
    padding: 20px;
    margin: 0 5% 0 5%;
}
@media (max-width: 992px) {
    .landing-screenshot-1,
    .landing-screenshot-2,
    .landing-screenshot-3,
    .landing-screenshot-4 {
        width: 90%;
        margin-bottom: 15px;
    }
    .landing-screenshot-1 svg,
    .landing-screenshot-2 svg,
    .landing-screenshot-3 svg,
    .landing-screenshot-4 svg {
        height: auto;
    }
    .reverse-order-container {
        display: flex;
        flex-direction: column-reverse;
    }
    .tool-section-title {
        font-size: 20px;
    }
    .tool-section-text {
        font-size: 16px;
    }
    .tool-text-container {
        padding: 10% 7% 0 7%;
    }
    .tool-section-main-title {
        font-size: 24px;
    }
    .tool-section-main-subtitle {
        font-size: 16px;
    }
    .ws-tools-main-container {
        margin: 0;
    }
}
