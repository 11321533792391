.quotation-info-modal-container .ant-modal-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 20px;
  margin: 0 auto;
  text-align: left;
  padding-top: 35px;
  max-width: 580px;
}
.quotation-info-modal-container .ant-modal-content p {
  color: #444;
  margin-bottom: 0;
  font-size: 16px;
  line-height: 20px;
}
.quotation-info-modal-container .confirmation-label {
  font-weight: 600;
  color: var(--primary-color) !important;
}

/* .quotation-info-modal-container p.confirmation-used {
  font-weight: 600;
} */

.quotation-info-modal-container .ant-image,
.ant-image-img:not(.ws-profile-image) {
  max-width: 100px;
  max-height: 100px;
}

@media (max-width: 992px) {
  .quotation-info-modal-container .ant-image,
  .ant-image-img {
    align-self: center;
  }
}
