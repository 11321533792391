/*=============================================
=               Main Dashboard                =
=============================================*/
.main-dashboard {
  display: block;
  margin: 0px auto;
  padding: 20px 0;
  width: 90%;
}

.main-dashboard .dashboard-box {
  border-radius: 10px;
  border: 1px solid #ddd;
  background: #fff;
  width: 100%;
  width: calc(100% - 8px);
  min-height: 224px;
  margin: 4px auto 8px auto;
  padding: 15px 22px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.main-dashboard .dashboard-box h2 {
  color: var(--text-color);
  font-size: 24px !important;
  line-height: 30px !important;
  font-weight: 600;
  margin: 0;
}
.main-dashboard .dashboard-box .box-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
}
.main-dashboard .dashboard-box .box-footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  gap: 5px;
}
.main-dashboard .dashboard-box .box-header .ant-btn {
  padding: 0;
  border-radius: 2px !important;
}
.main-dashboard .dashboard-box .box-footer .ant-btn {
  padding: 0;
  margin-top: 5px;
  border-radius: 2px !important;
}

.main-dashboard .dashboard-box .box-header .ant-btn span,
.main-dashboard .dashboard-box .box-footer .ant-btn span {
  font-size: 16px !important;
  line-height: 20px !important;
  font-weight: 600;
}
.main-dashboard .dashboard-box .ant-btn.--desktop {
  display: block;
}
.main-dashboard .dashboard-box .ant-btn.--mobile {
  display: none;
}
@media (max-width: 992px) {
  .main-dashboard .dashboard-box .ant-btn.--desktop {
    display: none;
  }
  .main-dashboard .dashboard-box .ant-btn.--mobile {
    display: block;
  }
}

/*----------  tables  ----------*/
.dashboard-box-table {
  width: 100%;
  border: 0;
}
.dashboard-box-table .ant-table-thead .ant-table-cell {
  padding: 5px 10px 5px 0px;
  color: #444;
  font-size: 16px !important;
  line-height: 1 !important;
  font-weight: 600;
  border: 0 !important;
  border-radius: 0 !important;
  background: #fff;
}
.dashboard-box-table
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  background-color: #fff;
}
.dashboard-box-table .ant-table-thead .ant-table-cell.alert-column {
  width: 15px;
}
.dashboard-box-table .ant-table-tbody .ant-table-cell {
  padding: 0px 10px 0px 0px;
  border: 0;
}
.dashboard-box-table .ant-table-tbody .ant-table-cell p {
  color: #444;
  font-size: 16px !important;
  line-height: 1.2 !important;
  margin: 0;
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
}
.dashboard-box-table .ant-table-tbody .ant-table-cell a {
  color: var(--primary-color);
  font-size: 16px !important;
  line-height: 1.2 !important;
  text-decoration: underline;
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
}
.dashboard-box-table .ant-table-tbody .ant-table-cell .anticon {
  color: var(--warning-color);
  display: block;
  margin: 0 auto;
}

.dashboard-box-table.hide-pagiantion .ant-pagination {
  display: none;
}
.dashboard-modal .dashboard-box-table .ant-table-tbody .ant-table-cell p {
  font-size: 14px !important;
  line-height: 18px;
}
.dashboard-modal .dashboard-box-table .ant-table-tbody .ant-table-cell a {
  font-size: 14px !important;
  line-height: 18px;
}

/*----------  Filter dropdown  ----------*/
.dashboard-modal .ant-table-filter-dropdown {
  border-radius: 10px;
  overflow: hidden;
  width: 240px;
  padding: 20px;
}
.dashboard-box-table-dropdown { 
  color: #444 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.dashboard-box-table-dropdown .dropdown-header {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
}
.dashboard-box-table-dropdown .dropdown-header p {
  color: var(--mariner-color);
  margin: 0;
  font-weight: 600;
  font-size: 16px !important;
  text-align: center;
}
.dashboard-box-table-dropdown .dropdown-header .ant-btn {
  position: absolute;
  top: -5px;
  right: 0px;
  width: 20px;
  height: 20px;
  border: 0;
  margin: 0;
}
.dashboard-box-table-dropdown .dropdown-header .ant-btn svg {
  width: 20px;
  height: 20px;
}
.dashboard-box-table-dropdown .dropdown-actions,
.dashboard-box-table-dropdown .dropdown-options {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 10px;
}
.dashboard-box-table-dropdown .dropdown-actions .ant-btn {
  width: 200px !important;
  border-width: 2px;
  margin: 0 auto;
}
.dashboard-box-table-dropdown .dropdown-options .ant-checkbox-wrapper {
  margin: 0 !important;
  font-size: 16px !important;
}
.dashboard-box-table-dropdown .dropdown-options {
  /* padding-bottom: 10px; */
}


.dashboard-modal .ant-table-filter-dropdown .ant-table-filter-dropdown-btns {
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px 20px 20px;
  gap: 10px;
  border: 0;
}
.dashboard-modal
  .ant-table-filter-dropdown
  .ant-table-filter-dropdown-btns
  .ant-btn {
  margin: 0;
}
.dashboard-modal .ant-table-filter-dropdown .ant-dropdown-menu {
  padding: 20px 10px 20px 10px;
}

.dashboard-modal
  .ant-table-filter-dropdown
  .ant-checkbox-wrapper
  .ant-checkbox
  .ant-checkbox-inner,
.dashboard-modal
  .ant-table-filter-dropdown
  .ant-checkbox-wrapper
  .ant-checkbox.ant-checkbox-disabled
  .ant-checkbox-inner {
  width: 20px;
  height: 20px;
  background-color: transparent;
  border-color: #444;
  border-radius: 5px;
  border-width: 2px;
}
.dashboard-modal
  .ant-table-filter-dropdown
  .ant-checkbox-wrapper
  .ant-checkbox.ant-checkbox-disabled
  .ant-checkbox-inner {
  background-color: #cccccc;
}
.dashboard-modal
  .ant-table-filter-dropdown
  .ant-checkbox-wrapper:hover
  .ant-checkbox-inner,
.dashboard-modal
  .ant-table-filter-dropdown
  .ant-checkbox-wrapper
  .ant-checkbox:hover
  .ant-checkbox-inner,
.dashboard-modal
  .ant-table-filter-dropdown
  .ant-checkbox-wrapper
  .ant-checkbox-input:focus
  + .ant-checkbox-inner {
  border-color: #444;
}
.dashboard-modal
  .ant-table-filter-dropdown
  .ant-checkbox-wrapper
  .ant-checkbox-checked
  .ant-checkbox-inner::after {
  transform: rotate(45deg) scale(1.2) translate(-35%, -50%);
}
.dashboard-modal
  .ant-table-filter-dropdown
  .ant-checkbox-wrapper
  .ant-checkbox-checked
  .ant-checkbox-inner::after {
  border-color: var(--mariner-color);
}
.dashboard-modal .ant-table-filter-dropdown .ant-dropdown-menu-item-selected,
.dashboard-modal
  .ant-table-filter-dropdown
  .ant-dropdown-menu-submenu-title-selected {
  background: #fff !important;
  color: #444 !important;
  font-weight: 600;
}
.dashboard-modal .ant-table-filter-dropdown .ant-btn.ant-btn-primary {
  /* background: var(--primary-color); */
  width: 200px;
}
.dashboard-modal .ant-table-filter-dropdown .ant-btn.ant-btn-primary span {
  font-size: 16px;
  border-radius: 10px !important;
}
.dashboard-modal .ant-table-filter-dropdown .ant-btn.ant-btn-link {
  /* border: 2px solid var(--primary-color); */
  width: 200px;
}
.dashboard-modal
  .ant-table-filter-dropdown
  .ant-btn.ant-btn-link:not(:disabled)
  span {
  color: var(--primary-color);
}

/*----------  Modals  ----------*/
.dashboard-modal {
  top: 91px;
  width: 720px !important;
}
.dashboard-modal .ant-modal-content {
  border-radius: 10px;
  background: #fff;
}
@media (max-width: 992px) {
  .dashboard-modal {
    top: 113px;
    width: 95% !important;
    min-width: 320px !important;
  }
}
.dashboard-modal .ant-modal-body {
  padding: 30px;
  border-radius: 10px;
  background: #fff;
}
.dashboard-modal h1 {
  color: var(--mariner-color);
  text-align: center;
  font-size: 24px;
  font-weight: 600;
}
.dashboard-modal .ant-modal-close {
  top: 20px;
  right: 20px;
  height: 20px;
  width: 20px;
}
.dashboard-modal .ant-modal-close .ant-modal-close-x,
.dashboard-modal .ant-modal-close svg {
  width: 20px;
  height: 20px;
  line-height: 20px;
  color: #000;
}
.dashboard-modal .ant-btn {
  width: 240px;
  height: 40px;
  margin: 10px auto;
  display: block;
  padding: 0;
}
.dashboard-modal .ant-btn span {
  font-weight: 600;
  font-size: 16px !important;
  line-height: 40px;
}

/*----------  Graphic box  ----------*/
.main-dashboard .graphic-box {
  margin-bottom: 30px;
  position: relative;
  top: 0;
  left: 0;
}
.main-dashboard .graphic-box .graphic-container {
}
.main-dashboard .graphic-box .graphic-container canvas {
  /* height: 400px !important;
  width: auto !important; */
}
.main-dashboard .graphic-box .graphic-options {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  /* position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 20px); */
}
.main-dashboard .graphic-box .graphic-options .select-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
}
.main-dashboard .graphic-box .graphic-options .select-container label {
  color: #444;
  font-size: 14px !important;
  font-weight: 600;
  /* line-height: 30px; */
}
.main-dashboard .graphic-box .graphic-options .select-container .ant-select {
  width: 180px;
}
.main-dashboard
  .graphic-box
  .graphic-options
  .select-container
  .ant-select-selector {
  padding: 0 22px;
}

.main-dashboard .ck-button .whatsapp-outline,
.main-dashboard .ck-button .phone,
.main-dashboard .ck-button .envelop {
  margin-top: 4px;
  margin-right: 2px;
  width: 24px;
  height: 24px;
}

.dashboard-box .dashboard-whatsapp-button span:not(.ck-icon){
  margin-right: 17px;
  margin-top: 3px;
}

.dashboard-box .dashboard-call-button span:not(.ck-icon){
  margin-right: 10px;
  margin-top: 3px;
}

.dashboard-box .dashboard-email-button span:not(.ck-icon){
  margin-top: 3px;
}

.dashboard-box .dashboard-call-button,
.dashboard-box .dashboard-whatsapp-button,
.dashboard-box .dashboard-email-button {
  margin-bottom: 10px;
}

/*=========  End of Main Dashboard  ==========*/
