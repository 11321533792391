.export-client-list-modal {
}

.export-client-list-modal .buttons-container {
  display: flex;
  gap: 10px;
  justify-content: center;
}
.export-client-list-modal .ant-btn {
  height: 80px;
  max-width: 290px;
  width: 45%;
}
.export-client-list-modal .ant-btn p {
  font-size: 14px;
  margin: 0;
}
.export-client-list-modal .ant-btn p:last-child {
  font-size: 10px;
  margin: 0;
}
.export-client-list-modal p {
  text-align: center;
}
.export-client-list-modal .wheel-loader.cover-container {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  overflow: hidden;
}

@media (max-width: 768px) {
  .export-client-list-modal .buttons-container {
    flex-direction: column;
  }
}
