/*-------------------------------------*
*                General               |
*--------------------------------------*/
.quotation-form-drawer .quotation-forms {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  gap: 10px;
  position: relative;
  margin: 0px -15px;
}
.quotation-form-drawer .quotation-forms .content-wrapper {
  border-radius: 10px;
  border: solid 1px #e7e7e7;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  align-items: flex-end;
  justify-content: space-between;
  gap: 5px;
  width: 100%;
  padding: 40px 10px;
}

/*-------------------------------------*
*              Nav Buttons             |
*--------------------------------------*/
.quotation-form-drawer .quotation-forms > .ant-btn {
  border-radius: 50%;
  width: 45px;
  min-width: 45px;
  height: 45px;
  min-height: 45px;
  background: var(--primary-color);
  color: #fff;
  text-align: center;
  padding: 0;
}
.quotation-form-drawer .quotation-forms > .ant-btn span {
  font-size: 25px;
  line-height: 20px;
}

/*-------------------------------------*
*              Total Box               |
*--------------------------------------*/
.quotation-form-drawer .quotation-forms table.total-box {
  border-spacing: 0;
  border-collapse: separate;
  border-radius: 10px;
  border: 1px solid #dbdbdb;
}

/* Apply a border to the right of all but the last column */
.quotation-form-drawer .quotation-forms table.total-box th:not(:last-child),
.quotation-form-drawer .quotation-forms table.total-box td:not(:last-child) {
  border-right: 1px solid #dbdbdb;
}

/* Apply a border to the bottom of all but the last row */
.quotation-form-drawer .quotation-forms table.total-box>thead>tr:not(:last-child)>th,
.quotation-form-drawer .quotation-forms table.total-box>thead>tr:not(:last-child)>td,
.quotation-form-drawer .quotation-forms table.total-box>tbody>tr:not(:last-child)>th,
.quotation-form-drawer .quotation-forms table.total-box>tbody>tr:not(:last-child)>td,
.quotation-form-drawer .quotation-forms table.total-box>tfoot>tr:not(:last-child)>th,
.quotation-form-drawer .quotation-forms table.total-box>tfoot>tr:not(:last-child)>td,
.quotation-form-drawer .quotation-forms table.total-box>tr:not(:last-child)>td,
.quotation-form-drawer .quotation-forms table.total-box>tr:not(:last-child)>th,
.quotation-form-drawer .quotation-forms table.total-box>thead:not(:last-child),
.quotation-form-drawer .quotation-forms table.total-box>tbody:not(:last-child),
.quotation-form-drawer .quotation-forms table.total-box>tfoot:not(:last-child) {
  border-bottom: 1px solid #dbdbdb;
}
.quotation-form-drawer .quotation-forms table.total-box td {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.1px;
  text-align: center;
  color: #282828;
  padding: 10px 30px;
}
.quotation-form-drawer .quotation-forms table.total-box tr td:first-child {
  text-align: right;
}
.quotation-form-drawer .quotation-forms table.total-box tr td:last-child {
  text-align: left;
}
.quotation-form-drawer .quotation-forms table.total-box tr:last-child td:last-child {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.12px;
  text-align: left;
  color: #282828;
}

/*-------------------------------------*
*             Subtotal Box             |
*--------------------------------------*/
.quotation-form-drawer .quotation-forms table.subtotal-box {
  border-spacing: 0;
  border-collapse: separate;
  border-radius: 10px;
  border: 1px solid #dbdbdb;
  /* width: 100%; */
  margin-bottom: 10px;
}

/* Apply a border to the right of all but the last column */
.quotation-form-drawer .quotation-forms table.subtotal-box th:not(:last-child),
.quotation-form-drawer .quotation-forms table.subtotal-box td:not(:last-child) {
  border-right: 1px solid #dbdbdb;
}

/* Apply a border to the bottom of all but the last row */
.quotation-form-drawer .quotation-forms table.subtotal-box>thead>tr>th,
.quotation-form-drawer .quotation-forms table.subtotal-box>thead>tr:not(:last-child)>td,
.quotation-form-drawer .quotation-forms table.subtotal-box>tbody>tr>th,
.quotation-form-drawer .quotation-forms table.subtotal-box>tbody>tr:not(:last-child)>td,
.quotation-form-drawer .quotation-forms table.subtotal-box>tfoot>tr>th,
.quotation-form-drawer .quotation-forms table.subtotal-box>tfoot>tr:not(:last-child)>td,
.quotation-form-drawer .quotation-forms table.subtotal-box>tr:not(:last-child)>td,
.quotation-form-drawer .quotation-forms table.subtotal-box>tr>th,
.quotation-form-drawer .quotation-forms table.subtotal-box>thead:not(:last-child),
.quotation-form-drawer .quotation-forms table.subtotal-box>tbody:not(:last-child),
.quotation-form-drawer .quotation-forms table.subtotal-box>tfoot:not(:last-child) {
  border-bottom: 1px solid #dbdbdb;
}
.quotation-form-drawer .quotation-forms table.subtotal-box>thead>tr>th {
  padding: 15px 10px 10px 10px;
  text-align: left;
  font-size: 13px;
  font-weight: 600;
  color: var(--primary-color);
}
.quotation-form-drawer .quotation-forms table.subtotal-box>tbody>tr>td {
  padding: 10px;
  vertical-align: middle;
  font-size: 13px;
  letter-spacing: 0.1px;
  text-align: left;
  color: #282828;
}


/*-------------------------------------*
*               Add Line               |
*--------------------------------------*/
.quotation-form-drawer .quotation-forms .add-line-btn {
  padding: 0;
}
.quotation-form-drawer .quotation-forms .add-line-btn span {
  font-size: 13px;
  font-weight: normal;
  letter-spacing: 0.1px;
  color: #282828;
}


/*-------------------------------------*
*               Carousel               |
*--------------------------------------*/
.quotation-form-drawer .quotation-forms .ant-carousel {
  width: calc(100vw - 460px);
  margin: 0 auto;
}
.quotation-form-drawer .quotation-forms .ant-carousel .slick-initialized .slick-slide {
  padding: 0 1px;
}


/*---------------------------------------------------------------------------------*
|                                                                                  |
|                                    Item Desktop Card                             |
|                                                                                  |
*----------------------------------------------------------------------------------*/
.quotation-form-drawer .quotation-forms .quotation-item-card {
  width: 100%;
}
.quotation-form-drawer .quotation-forms .quotation-item-card .card-pagination-label {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: #808080;
}
.quotation-form-drawer .quotation-forms .quotation-item-card .card-title-label {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: #2f66f2;
}

.quotation-form-drawer .quotation-forms .quotation-item-card table {
  border-spacing: 0;
  border-collapse: separate;
  border-radius: 10px;
  border: 1px solid #dbdbdb;
  width: 100%;
  margin-bottom: 10px;
}

/* Apply a border to the right of all but the last column */
.quotation-form-drawer .quotation-forms .quotation-item-card table th:not(:last-child),
.quotation-form-drawer .quotation-forms .quotation-item-card table td:not(:last-child) {
  border-right: 1px solid #dbdbdb;
}

/* Apply a border to the bottom of all but the last row */
.quotation-form-drawer .quotation-forms .quotation-item-card table>thead>tr>th,
.quotation-form-drawer .quotation-forms .quotation-item-card table>thead>tr:not(:last-child)>td,
.quotation-form-drawer .quotation-forms .quotation-item-card table>tbody>tr>th,
.quotation-form-drawer .quotation-forms .quotation-item-card table>tbody>tr:not(:last-child)>td,
.quotation-form-drawer .quotation-forms .quotation-item-card table>tfoot>tr>th,
.quotation-form-drawer .quotation-forms .quotation-item-card table>tfoot>tr:not(:last-child)>td,
.quotation-form-drawer .quotation-forms .quotation-item-card table>tr:not(:last-child)>td,
.quotation-form-drawer .quotation-forms .quotation-item-card table>tr>th,
.quotation-form-drawer .quotation-forms .quotation-item-card table>thead:not(:last-child),
.quotation-form-drawer .quotation-forms .quotation-item-card table>tbody:not(:last-child),
.quotation-form-drawer .quotation-forms .quotation-item-card table>tfoot:not(:last-child) {
  border-bottom: 1px solid #dbdbdb;
}
/*-------------------------------------*
*            Table headers             |
*--------------------------------------*/
.quotation-form-drawer .quotation-forms .quotation-item-card table>thead>tr>th {
  padding: 15px 10px 10px 10px;
  text-align: left;
  font-size: 13px;
  font-weight: 600;
  color: var(--primary-color);
}
.quotation-form-drawer .quotation-forms .quotation-item-card table>thead>tr>th.label {
  min-width: 200px;
}
.quotation-form-drawer .quotation-forms .quotation-item-card table>thead>tr>th.description {
  min-width: 200px;
}
.quotation-form-drawer .quotation-forms .quotation-item-card table>thead>tr>th.price-per-unit {
  width: 122px;
}
.quotation-form-drawer .quotation-forms .quotation-item-card table>thead>tr>th.discount {
  width: 165px;
}
.quotation-form-drawer .quotation-forms .quotation-item-card table>thead>tr>th.number-of-units {
  width: 70px;
}
.quotation-form-drawer .quotation-forms .quotation-item-card table>thead>tr>th.line-total {
  width: 80px;
}
.quotation-form-drawer .quotation-forms .quotation-item-card table>thead>tr>th.actions {
  width: 30px;
}
/*-------------------------------------*
*              Table rows              |
*--------------------------------------*/
.quotation-form-drawer .quotation-forms .quotation-item-card table>tbody>tr>td {
  padding: 10px;
  vertical-align: middle;
  font-size: 13px;
  letter-spacing: 0.1px;
  text-align: left;
  color: #282828;
}
.quotation-form-drawer .quotation-forms .quotation-item-card table>tbody>tr>td textarea {
  font-size: 13px;
  letter-spacing: 0.1px;
  text-align: left;
  color: #282828;
  padding: 10px 15px;
}
.quotation-form-drawer .quotation-forms .quotation-item-card table>tbody>tr>td input {
  padding: 10px;
  font-size: 13px;
  letter-spacing: 0.1px;
  text-align: left;
  color: #282828;
}
.quotation-form-drawer .quotation-forms .quotation-item-card table>tbody>tr>td>div {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.quotation-form-drawer .quotation-forms .quotation-item-card table>tbody>tr>td.price-per-unit .ant-input-number {
  width: 70px;
  border-radius: 0 10px 10px 0 !important;
}
.quotation-form-drawer .quotation-forms .quotation-item-card table>tbody>tr>td.price-per-unit .ant-input-number-group-addon {
  border-radius: 10px 0 0 10px !important;
}
.quotation-form-drawer .quotation-forms .quotation-item-card table>tbody>tr>td.discount .ant-input-number {
  width: 45px;
  border-radius: 10px 0 0 10px !important;
}
.quotation-form-drawer .quotation-forms .quotation-item-card table>tbody>tr>td.discount .ant-input-number-group-addon {
  border-radius: 0 10px 10px 0 !important;
}
.quotation-form-drawer .quotation-forms .quotation-item-card table>tbody>tr>td.number-of-units {
  text-align: center;
}
.quotation-form-drawer .quotation-forms .quotation-item-card table>tbody>tr>td.number-of-units .ant-input-number {
  width: 52px;
}
.quotation-form-drawer .quotation-forms .quotation-item-card table>tbody>tr>td.actions {
  text-align: center;
}
.quotation-form-drawer .quotation-forms .quotation-item-card table>tbody>tr>td.actions .ant-btn {
  border: 0;
  box-shadow: none;
}
.quotation-form-drawer .quotation-forms .quotation-item-card table>tbody>tr>td.actions .ant-btn span {
  font-size: 20px;
}


/*---------------------------------------------------------------------------------*
|                                                                                  |
|                                   Item Mobile Card                               |
|                                                                                  |
*----------------------------------------------------------------------------------*/
.quotation-form-drawer .quotation-forms .quotation-item-card .card-form {
  border-radius: 10px;
  border: solid 1px #dbdbdb;
  width: 100%;
  margin: 0 auto 10px auto;
}
.quotation-form-drawer .quotation-forms .quotation-item-card .card-form .card-line {
  width: 100%;
  display: flex;
  flex-flow: column;
}
.quotation-form-drawer .quotation-forms .quotation-item-card .card-form .card-line:not(:last-child) {
  border: 0 solid #f7f7f7;
  border-bottom-width: 10px;
}
.quotation-form-drawer .quotation-forms .quotation-item-card .card-form .card-line > div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  flex-direction: row;
}
.quotation-form-drawer .quotation-forms .quotation-item-card .card-form .card-line .upper-line {
  border-bottom: 1px solid #dbdbdb;
}
.quotation-form-drawer .quotation-forms .quotation-item-card .card-form .card-line .upper-line .line-field:not(:last-child) {
  border-bottom: 1px solid #dbdbdb;
}
.quotation-form-drawer .quotation-forms .quotation-item-card .card-form .card-line .identification {
  border-right: 1px solid #dbdbdb;
  width: 100%;
}
.quotation-form-drawer .quotation-forms .quotation-item-card .card-form .card-line .actions {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 50px;
}
.quotation-form-drawer .quotation-forms .quotation-item-card .card-form .card-line .lower-line {
  flex-wrap: wrap;
}
.quotation-form-drawer .quotation-forms .quotation-item-card .card-form .card-line .lower-line .line-field {
  width: 50%;
}
.quotation-form-drawer .quotation-forms .quotation-item-card .card-form .card-line .lower-line .line-field:nth-child(odd) {
  border-right: 1px solid #dbdbdb;
}
.quotation-form-drawer .quotation-forms .quotation-item-card .card-form .card-line .lower-line .line-field:nth-child(1),
.quotation-form-drawer .quotation-forms .quotation-item-card .card-form .card-line .lower-line .line-field:nth-child(2) {
  border-bottom: 1px solid #dbdbdb;
}
.quotation-form-drawer .quotation-forms .quotation-item-card .card-form .card-line .line-field {
  font-size: 13px;
  letter-spacing: 0.1px;
  text-align: left;
  color: #282828;
  padding: 15px 10px;
  width: 100%;
  height: 100%;
  min-height: 92px;
}
.quotation-form-drawer .quotation-forms .quotation-item-card .card-form .card-line .line-field > p {
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.1px;
  text-align: left;
  color: var(--primary-color);
}
.quotation-form-drawer .quotation-forms .quotation-item-card .card-form .card-line .line-field.price-per-unit .ant-input-number {
  width: 70px;
  border-radius: 0 10px 10px 0 !important;
}
.quotation-form-drawer .quotation-forms .quotation-item-card .card-form .card-line .line-field.price-per-unit .ant-input-number-group-addon {
  border-radius: 10px 0 0 10px !important;
}
.quotation-form-drawer .quotation-forms .quotation-item-card .card-form .card-line .line-field.discount .ant-input-number {
  width: 45px;
  border-radius: 10px 0 0 10px !important;
}
.quotation-form-drawer .quotation-forms .quotation-item-card .card-form .card-line .line-field.discount .ant-input-number-group-addon {
  border-radius: 0 10px 10px 0 !important;
}
.quotation-form-drawer .quotation-forms .quotation-item-card .card-form .card-line .line-field.discount > div {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.quotation-form-drawer .quotation-forms .quotation-item-card .card-form .card-line .line-field textarea {
  font-size: 13px;
  letter-spacing: 0.1px;
  text-align: left;
  color: #282828;
  padding: 10px 15px;
}
.quotation-form-drawer .quotation-forms .quotation-item-card .card-form .card-line .line-field input {
  padding: 10px;
  font-size: 13px;
  letter-spacing: 0.1px;
  text-align: left;
  color: #282828;
}



@media (max-width: 992px) {
  .quotation-form-drawer .quotation-forms .ant-carousel {
    width: calc(100vw - 225px);
  }
}

@media (max-width: 576px) {
  .quotation-form-drawer .quotation-forms .content-wrapper {
    padding: 0;
    border: 0;
    background: unset;
  }
  .quotation-form-drawer .quotation-forms .ant-carousel {
    width: 100%;
  }
  .quotation-form-drawer .quotation-forms > .ant-btn {
    width: 30px;
    min-width: 30px;
    height: 30px;
    min-height: 30px;
  }
  .quotation-form-drawer .quotation-forms > .ant-btn span {
    
    font-size: 20px;
    line-height: 35px;
  }
  .quotation-form-drawer .quotation-forms > .ant-btn {
    position: absolute;
    top: 0;
  }
  .quotation-form-drawer .quotation-forms > .ant-btn.left {
    right: 50px;
  }
  .quotation-form-drawer .quotation-forms > .ant-btn.right {
    right: 0px;
  }
  .quotation-form-drawer .quotation-forms .quotation-item-card .card-form,
  .quotation-form-drawer .quotation-forms .subtotal-box,
  .quotation-form-drawer .quotation-forms .total-box {
    background: #fff;
    box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.25);
  }
  .quotation-form-drawer .quotation-forms .ant-carousel .slick-initialized .slick-slide {
    padding: 0 3px;
  }
}
