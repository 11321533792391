.workshop-form-checkbox-group {
    display: flex;
    flex-direction: column;
}

.workshop-form-checkbox-group .ant-checkbox-inner {
    background-color: transparent;
    border-color: #444;
    border-radius: 5px;
    border-width: 2px;
    height: 20px;
    width: 20px
}

.workshop-form-checkbox-group .ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: var(--orange);
}
