.quotation-footer { 
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px;
}

.quotation-footer .notice-text {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.09px;
  text-align: center;
  color: #282828;
}

.quotation-footer .ant-btn {
  width: 300px;
  height: 40px;
  padding: 5px 15px;
}
.quotation-footer .ant-btn span {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  letter-spacing: 0.15px;
  text-align: center;
}

.quotation-footer .ant-btn.send-button,
.quotation-footer .ant-btn.approve-button {
  border: 2px solid var(--primary-color) !important
}
.quotation-footer .ant-btn.send-button span, 
.quotation-footer .ant-btn.approve-button span {
  color: var(--primary-color);
}
.quotation-footer .ant-btn.send-button[disabled],
.quotation-footer .ant-btn.approve-button[disabled] {
  border: 2px solid var(--black-40) !important;
}
.quotation-footer .ant-btn.send-button[disabled] span,
.quotation-footer .ant-btn.approve-button[disabled] span {
  color: var(--black-40);
}

.quotation-footer .ant-btn.reminder-button {
  height: 30px;
  padding: 0;
  width: 260px;
}
@media (max-width: 576px) {
  .quotation-footer .ant-btn {
    width: 280px;
    height: 46px;
    padding: 10px 15px;
  }
  .quotation-footer .ant-btn span {
    font-size: 16px;
  }
}
