@keyframes backgroundFiltersColorPalette {
  0% {
    background: var(--blue);
  }
  25% {
    background: var(--black-20);
  }
  50% {
    background: var(--blue);
  }
  75% {
    background: var(--black-20);
  }
  100% {
    background: var(--blue);
  }
}

.filter-animation {
  animation-name: backgroundFiltersColorPalette;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  height: auto;
  padding: 0px !important;
}
.filter-no-animation {
  height: auto;
  padding: 0px !important;
}

.filter-no-animation-dark {
  height: auto;
  padding: 0px !important;
}
.bold {
  font-weight: 700;
}
.notif-row-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 4px;
}
.notif-row-container.row-new {
  top: -10px !important;
}
.notif-row-container .new-circle-container {
  display: flex;
  padding-top: 2.5px;
  /* margin-left: -15px; */
}
.notif-row-container .new-circle {
  width: 12px;
  height: 12px;
  border-radius: 50px;
  background-color: var(--primary-color);
}
.notif-container {
  display: flex;
  flex-direction: column;
  padding: 20px 20px !important;
  border-radius: 0px !important;
  width: 100%;
}
.notif-container.is-new {
  background-color: #eaeffe;
}
.notif-container .notif-date-container {
  flex: 1;
  display: flex;
  flex-direction: row;
}
.notif-container .notif-date {
  flex: 1;
  font-size: 14px;
  color: #808080;
  font-family: "EMprint-Semibold";
  margin: 0px;
  line-height: normal !important;
}
.notif-container .notif-clear svg {
  margin-top: -5px !important;
  color: black;
}
.notif-container .notif-info {
  font-size: 16px;
  color: #444444;
  font-family: "EMprint-Semibold";
  margin: 0px;
  line-height: normal !important;
}
.notif-container .notif-info a {
  font-size: 16px;
  color: var(--primary-color);
  font-family: "EMprint-Semibold";
  text-decoration: underline;
  margin: 0px;
}

@media (max-width: 992px) {
  .notif-container .notif-date {
    font-size: 12px;
  }
  .notif-container .notif-info {
    font-size: 14px;
  }
  .notif-container .notif-info a {
    font-size: 14px;
  }
}
