/* Styles for the scrollable container */
.recepction-form-drawer .reception-step-resize {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  width: 100%;
  min-height: calc(100vh - 280px);
  max-height: calc(100vh - 280px);

  overflow: auto;
  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: #44444493 #44444411;
}
.recepction-form-drawer .sub-reception-step {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}
/* WebKit (Chrome, Safari, etc.) */
.recepction-form-drawer .reception-step-resize::-webkit-scrollbar {
  width: 8px;
}
.recepction-form-drawer .reception-step-resize::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: transparent;
}
.recepction-form-drawer .reception-step-resize::-webkit-scrollbar-track {
  border-radius: 6px;
  background-color: transparent;
}
/* .recepction-form-drawer .reception-step-resize:hover::-webkit-scrollbar-thumb {
  background-color: #4444442a;
}
.recepction-form-drawer .reception-step-resize:hover::-webkit-scrollbar-track {
  background-color: #44444411;
} */
.recepction-form-drawer .ant-drawer-body {
  overflow-y: none !important;
}
.recepction-form-drawer .complete-diag-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.recepction-form-drawer .uploading-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: none;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  z-index: 5000;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: all 0.5s ease;
}
.recepction-form-drawer .uploading-loader.ant-spin-spinning {
  display: flex;
  opacity: 1;
}
.recepction-form-drawer .flex-mid-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.recepction-form-drawer .flex-mid-content .back-arrow {
  align-self: flex-start !important;
  width: 20px;
  height: 20px;
  margin-bottom: -23.5px;

  color: var(--primary-color);
}
.recepction-form-drawer .mid-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: 627px !important;
}
.recepction-form-drawer .complete-diag-container .complete {
  width: 270px !important;
  height: 53px !important;
  background-color: #2f66f2;
  border: none;
}
.recepction-form-drawer .complete-diag-container .complete span {
  color: white;
  font-size: 18px !important;
  line-height: 20px !important;
  font-weight: 600;
}
.recepction-form-drawer .complete-diag-container .cancel {
  width: 270px !important;
  height: 53px !important;
  background-color: #a8a8a8;
  border: none;
}
.recepction-form-drawer .complete-diag-container .cancel span {
  color: white;
  font-size: 18px !important;
  line-height: 20px !important;
  font-weight: 600;
}

.recepction-form-drawer .ant-drawer-body {
  padding: 57px 30px 40px 30px;
  background: #fff;
}

.recepction-form-drawer .ant-drawer-header {
  padding-left: 30px;
  padding-right: 30px;
  background: #fff;
  border: none;
}
.recepction-form-drawer .ant-drawer-mask {
  left: -225px;
}
.recepction-form-drawer .ant-carousel {
  width: 100% !important;
}
.recepction-form-drawer .ant-carousel .slick-slider {
  padding-top: 30px;
}
/* .recepction-form-drawer .ant-carousel .slick-dots-top {
  top: 0px;
  bottom: auto;
  width: 100%;
  margin: 0;
}
.recepction-form-drawer .ant-carousel .slick-dots-top li {
  width: 33%;
}
.recepction-form-drawer .ant-carousel .slick-dots-top li button {
  background: #e7e7e7;
  width: 100%;
}
.recepction-form-drawer .ant-carousel .slick-dots-top li.slick-active button {
  background: var(--primary-color);
} */
.recepction-form-drawer .ant-form {
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 100%;
}

.recepction-form-drawer .button-container {
  margin-top: 25px;
}
.recepction-form-drawer .button-container .ant-btn {
  width: 240px;
  height: 40px;
}
.recepction-form-drawer .button-container .ant-btn span {
  font-size: 16px !important;
  line-height: 20px !important;
  font-weight: 600;
}
.recepction-form-drawer .footer-options {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;

  gap: 10px;
}
.recepction-form-drawer .footer-options .blue-next {
  border: 2px solid var(--primary-color);
  width: 100%;
  max-width: 240px;
  height: 40px;
  background-color: var(--primary-color);
}
.recepction-form-drawer .footer-options .blue-next span {
  font-size: 16px !important;
  font-family: "EMprint-Semibold";
}
.recepction-form-drawer .footer-options .blue-cancel {
  border: none;
  width: 100%;
  max-width: 240px;
  height: 40px;
  background-color: white;
}
.recepction-form-drawer .footer-options .blue-cancel span {
  font-size: 16px !important;
  font-family: "EMprint-Semibold";
  text-decoration: underline;
}
.recepction-form-drawer .row-gap-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  gap: 20px;

  width: 100%;
}

@media (max-width: 1050px) {
  .recepction-form-drawer .flex-mid-content .back-arrow {
    display: none;
  }
}

.recepction-form-drawer.ant-drawer-open {
  width: 100% !important;
  /* width: calc(100% - 225px) !important; */
  right: 0 !important;
  left: unset;
}

@media (min-width: 992px) {
  .recepction-form-drawer.ant-drawer-open .ant-drawer-content-wrapper {
    width: calc(100vw - 61px) !important;
  } 
  .expanded-menu .recepction-form-drawer.ant-drawer-open .ant-drawer-content-wrapper {
    width: calc(100vw - 300px) !important;
  }
}

@media (max-width: 992px) {
  .recepction-form-drawer.ant-drawer-open .ant-drawer-content-wrapper {
    width: 100% !important;
  }
  .recepction-form-drawer.ant-drawer-open {
    width: 100% !important;
    top: 65px;
  }
  .recepction-form-drawer .ant-drawer-header-title {
    justify-content: flex-start;
  }
  .recepction-form-drawer .ant-drawer-close .desktop-close-button {
    display: none;
  }
  .recepction-form-drawer .ant-drawer-close .mobile-close-button {
    display: block;
  }
  .recepction-form-drawer .ant-drawer-close {
    padding: 0;
  }
  .recepction-form-drawer .ant-drawer-mask {
    top: -65px;
  }
  .recepction-form-drawer .ant-drawer-body {
    padding: 0px 15px 15px 15px;
    background: #fff;
  }
  .recepction-form-drawer .row-gap-between {
    flex-direction: column;
  }
}

.recepction-form-drawer .ant-form-item.switch-combo {
  margin-bottom: 0 !important;
}
.recepction-form-drawer .switch-combo .ant-form-item-row {
  align-content: center;
  justify-content: center;
  align-items: center;
  height: 80px;
}
.recepction-form-drawer .switch-combo .ant-form-item-control {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  align-content: center;
  gap: 10px;
  height: 32px;
}
.recepction-form-drawer
  .switch-combo
  .ant-form-item-control
  .ant-form-item-extra {
  line-height: 32px !important;
}

.recepction-form-drawer .readonly-col-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.recepction-form-drawer .readonly-image {
  width: 230px;
  height: 177px;
  border-radius: 10px;

  margin-top: 10px;
  background-color: #4444440b;
}
.recepction-form-drawer .readonly-image .ant-image-img {
  object-fit: cover;
  border-radius: 10px;

  min-width: 100%;
  min-height: 100%;
}
.recepction-form-drawer .readonly-image .ant-image-mask {
  border-radius: 10px;
}

.recepction-form-drawer .readonly-image-small {
  margin: 0px;
  height: 80px !important;
  width: 180px !important;
  min-width: 180px !important;

  border-radius: 10px;

  margin-top: 10px;
  object-fit: cover;

  border: 1px solid #44444422;
}
.recepction-form-drawer .readonly-image-small img {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.recepction-form-drawer .readonly-image-small .ant-image-mask {
  border-radius: 10px;
}

.recepction-form-drawer .readonly-image-own {
  min-height: 225px !important;
  max-height: 225px !important;
  width: 100% !important;
  max-width: 340px !important;
  border-radius: 10px;

  object-fit: cover !important;
}
.recepction-form-drawer .ant-image-mask {
  border-radius: 10px !important;
}

.physical-inspection-container .readonly-pdf-own {
  margin: 0px 0px 10px 0px;

  height: 300px !important;
}
