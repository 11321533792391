.wheel-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 1);
  z-index: 199;
  overflow: hidden;
  display: none;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  transition: 0.5s ease all;
  opacity: 0;
}
@media (min-width: 1330px) {
  .wheel-loader {
    top: 115px;
  }
}
.wheel-loader.spinning {
  display: flex;
  opacity: 1;
}
.wheel-loader .wheel-icon svg {
  height: 150px;
  width: 150px;
  color: var(--mariner-color);
  -webkit-animation: rotate-center 3s linear infinite both;
  animation: rotate-center 3s linear infinite both;
}
@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.wheel-loader.cover-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
