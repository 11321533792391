.affiliation-step-badge .ant-badge-count {
    color: #2369BD;
    border: 1px solid #2369BD;
    background-color: #FFFFFF;
    top: 18px;
}
.affiliation-section-main-title {
    font-size: 32px;
    font-weight: 400;
    color: #2369BD;
}
.affiliation-main-container {
    padding: 20px;
    margin: 0 5% 0 5%;
}
@media (max-width: 992px) {
    .affiliation-section-main-title {
        font-size: 24px;
    }
    .affiliation-main-container {
        margin: 0;
    }
}
