.entries-list {
  background: #EEEEEE;
  width: 360px;
  min-width: 360px;
  /* 90px for safe zone, 74px for toolbars */
  height: calc(100vh - 164px);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}

@media (max-width: 990px) {
  .entries-list {
    /* 117px for safe zone, 74px for toolbars */
    height: calc(100vh - 191px);
  }
}

/* Request card styles */
.entries-list .request-card {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  color: #444;
  position: relative;
  padding: 20px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  cursor: pointer;
  border-bottom: 1px dashed #CCCCCC;
}
.entries-list .request-card.active {
  background: #CCCCCC;
}
.entries-list .request-card .main-vehicle-badge-triangle {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-top: 60px solid var(--mariner-color);
  border-left: 60px solid transparent;
  z-index: 2;
}
.entries-list .request-card .main-vehicle-badge {
  color: #fff;
  position: absolute;
  top: 10px;
  right: 5px;
  z-index: 3;
}
.entries-list .request-card .main-vehicle-badge span {
  position: absolute;
  top: 10px;
  right: 10px;
}
.entries-list .request-card .main-vehicle-badge svg {
  width: 20px;
  height: 20px;
}
.entries-list .request-card figure {
  width: 70px;
  height: 70px;
  margin: 0;
}
.entries-list .request-card figure img {
  /* border: 1px solid #ccc; */
  object-fit: cover;
  width: 70px;
  height: 70px;
  border-radius: 6px;
}
.entries-list .request-card figcaption {
  width: 100%;
  min-height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.entries-list .request-card .nickname {
  color: var(--black);
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  font-weight: bold;
  margin-bottom: 0px;
  padding-right: 40px;
}
.entries-list .request-card .compound-name {
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 2px;
  padding-right: 35px;
  word-wrap: break-word;
}
.entries-list .request-card .appointment-id {
  background: var(--primary-color);
  color: #fff;
  font-weight: 600;
  margin-bottom: 5px;
  /* margin-top: -5px; */
  display: inline-block;
  padding: 0px 10px;
  border-radius: 10px;
}


.entries-list .request-card.skeleton figure .ant-skeleton {
  width: 70px;
  height: 70px;
}
.entries-list .request-card.skeleton figure .ant-skeleton .ant-skeleton-header {
  padding: 0;
}
.entries-list .request-card.skeleton figure .ant-skeleton .ant-skeleton-header .ant-skeleton-avatar {
  width: 70px;
  height: 70px;
  border-radius: 10px;
}
.entries-list .request-card.skeleton figcaption .ant-skeleton-paragraph {
  margin: 0;
}
.entries-list .request-card.skeleton figcaption .ant-skeleton-content .ant-skeleton-paragraph > li {
  font-size: 18px;
  border-radius: 5px;
  margin-top: 10px;
}
.entries-list .request-card.skeleton figcaption .ant-skeleton-content .ant-skeleton-paragraph > li:first-child {
  height: 20px;
  margin-top: 0;
}


.entries-list .no-request {
  border-bottom: 1px dashed #ccc;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.entries-list .no-request p {
  width: 90%;
  margin: 0 auto;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: var(--mariner-color);
}

.entries-list > .ant-btn,
.entries-list .no-request > .ant-btn {
  margin: 0 auto;
}
