.workshop-commitments-page {
  padding: 30px 5%;
}
.workshop-commitments-page h1 {
  color: var(--primary-color);
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}
.workshop-commitments-page .anticon {
  color: var(--secondary-dark-color);
}
.workshop-commitments-page .cta {
  color: var(--secondary-dark-color);
}
.red-text {
    color: #F05822;
}

.bold-text {
    font-weight: 600;
}

.half-width {
    width: 50%;
}

.full-width {
    width: 100%;
}

.partial-width {
    width: 90% !important;
}

.custom-width {
    width: 85% !important;
}

.commitment-page-background {
    padding: 1% 4% 4%;
    background-color: #fff;
}

.footer-text {
    width: 100% !important;
}

.commitment-page-title-text {
    font-size: 40px;
    font-family: EMprint-Semibold, serif;
    line-height: 49px;
    text-align: center;
    margin-bottom: 25px;
    width: 60%;
}

.commitment-page-standard-text {
    font-size: 16px;
    width: 60%;
    text-align: center;
}

.commitment-page-large-text {
    font-size: 20px;
    font-weight: 600;
    line-height: 24.5px;
    text-align: center;
    width: 70%;
}

.commitment-list-container {
    padding: 20px 0 25px;
}

@media (max-width: 992px) {
    .commitment-list-container {
        padding: 1px 25px 25px;
    }
    .commitment-page-title-text {
        width: 90%;
        font-size: 20px;
        text-align: center;
        line-height: 24px;
    }
    .commitment-page-standard-text {
        width: 90%;
        text-align: start;
    }
    .custom-width {
        width: 90% !important;
    }
    .custom-width-2 {
        width: 90% !important;
    }
    .footer-text {
        text-align: center !important;
    }
    .indented-text {
        text-indent: 5%;
    }
    .text-right-indent {
        width: 15%;
        display: flex;
    }

}
