.approval-modal-container .options-grid {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  max-width: 400px;
}
@media (max-width: 480px) {
  .approval-modal-container .options-grid {
    max-width: 260px;
  }
}
.approval-modal-container .options-grid .ant-btn {
  padding: 0;
  width: 120px;
  border-width: 2px;
  cursor: pointer;
  transition: all 0.5s ease;
}
