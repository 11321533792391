.user-menu-container {
  margin-right: 0;
  width: auto;
}

.ant-popover-placement-bottom,
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomRight {
  z-index: 9999;
}

.user-menu-container .popover-menu {
  width: fit-content;
  z-index: 9999;
}

.popover-menu-overlay {
  padding-top: 0px;
}

@media (min-width: 992px) {
  .popover-menu-overlay {
    padding-top: 16px;
  }
}

@media (min-width: 1200px) {
  .user-menu-container .popover-menu {
    max-width: 300px;
    min-width: 250px;
    width: auto;
  }
  .user-menu-container {
    max-width: 300px;
    width: auto;
  }
}

.user-menu-container .nav-popover-menu p {
  margin: 0;
  width: 100%;
}

.user-menu-container .user-avatar {
  min-height: 56px;
  min-width: 56px;
  height: 56px !important;
  width: 56px !important;
  line-height: 56px !important;
  font-size: 30px !important;
}

.nav-popover-menu {
  /* padding: 10px 5px !important; */
  border-radius: 10px !important;
  display: flex;
  align-items: center;
  color: var(--primary-color) !important;
  font-weight: 600;
  line-height: 1.5;
}

.nav-popover-menu:hover {
  cursor: pointer !important;
  background-color: rgba(47, 102, 242, 0.05) !important;
  color: var(--primary-color) !important;
}

.nav-popover-menu .dropdown-icon {
  margin-top: 2px;
  margin-left: 15px;
}

@media (min-width: 992px) {
  .user-menu-container .user-avatar {
    background-color: var(--primary-color);
    margin-right: 10px;
  }
}

.user-menu-container .user-default-avatar {
  font-size: 17px;
  margin: 0px;
}

.user-submenu-content {
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding: 0px !important;
  border: 1px solid #888888;
  border-radius: 0px 0px 6px 6px;
  width: 155px;
  height: 73px;
}

.user-submenu-content .ant-menu-item,
.user-submenu-content .ant-menu-submenu-title {
  padding: 0px !important;
  margin: 0 auto !important;
  height: 32px !important;
  width: 100%;
}

.user-submenu-content .submenu-item {
  font-size: 14px;
  color: #444444;
  padding: 5px 10px;
  height: 32px;
  line-height: 22px;
}

.user-submenu-content .submenu-item:hover {
  background-color: rgba(136, 136, 136, 0.15);
}

.ant-popover-inner {
  border-radius: 0px 0px 6px 6px;
}

.nav-popover-menu:hover {
  background-color: transparent !important;
}
