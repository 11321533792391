.landing-page-menu {

}
.landing-page-menu .ant-menu-item-selected:not(.mobile-lp-menu .ant-menu-item-selected) {
    border-bottom: 2px solid #FE000C !important;
}
.landing-page-menu .ant-menu-item .ant-menu-title-content {
    font-size: 14px;
    font-weight: 600;
    color: #001450;
}
.mobile-lp-menu .ant-menu-item .ant-menu-title-content {
    font-size: 20px !important;
}
.landing-navigation-drawer-mobile .ant-drawer-content-wrapper {
    width: 100% !important;
}
.mobile-lp-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.landing-navigation-drawer-mobile .ant-drawer-header-title {
    flex-direction: row-reverse;
}
.btn-login-landing-nav {
    color: #001450;
    border-color: #001450;
}
@media (max-width: 992px) {
    .mobile-lp-menu{
        border-right: none;
    }
}
@media (min-width: 992px) {
    .landing-page-menu {
        min-width: 480px;
    }
}
