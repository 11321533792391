.video-section-main-title {
    font-size: 40px;
    font-weight: 600;
    color: #001450;
}
.video-section-main-subtitle {
    font-size: 20px;
}
.video-section-main-container {
    padding: 0 20px 20px 20px;
    margin: 0 5% 0 5%;
}
.video-section-text-container {
    padding: 20px 20px 0 0;
}
@media (max-width: 992px) {
    .video-section-main-title {
        font-size: 28px;
    }
    .video-section-main-subtitle {
        font-size: 16px;
    }
    .screenshot-main-video svg {
        width: 100%;
        height: auto;
    }
    .reverse-mobile-container {
        flex-direction: column-reverse;
    }
    .video-section-main-container {
        padding: 0 20px 20px 20px;
        margin: 0;
    }
}
