.recepction-form-drawer .reception-header-info {
  width: 100%;
}
.recepction-form-drawer .reception-header-info h2 {
  font-size: 24px;
  line-height: 29.45px;
  font-family: "EMprint-Semibold";
  color: #444444;
}
.recepction-form-drawer .reception-header-info p {
  font-size: 20px;
  line-height: 24.54px;
  font-family: "EMprint-Regular";
  color: #444444;
}
.recepction-form-drawer .dots-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  gap: 10px;

  width: 100%;
}
.recepction-form-drawer .dots-container span {
  flex: 1;
  height: 4px;
  background-color: #e7e7e7;
  transition: all 0.5s ease;
}
.recepction-form-drawer .dots-container span.active {
  background-color: var(--mariner-color);
}
