.edit-service-card {
  border: 1px solid var(--black-20);
  border-bottom: unset;
}
.edit-service-card.border-bottom {
  border-bottom: 1px solid var(--black-20) !important;
}
/* ==========================================================================
  Card header
  ========================================================================== */
.edit-service-card .card-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #f9f9f9;
  padding: 15px 20px;
  transition: all 0.5s ease;
  position: relative;
  top: 0;
  left: 0;
  min-height: 90px;
}
.edit-service-card .card-header .service-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
.edit-service-card .card-header .service-name p {
  color: #000;
  margin-bottom: 0;
}
.edit-service-card .card-header .service-name .ck-icon {
  color: var(--primary-color);
}
.edit-service-card .card-header .ck-button {
  flex-direction: row-reverse;
  height: 30px !important;
  padding: 10px;
  /* position: absolute;
  top: 0;
  right: 0; */
}
.edit-service-card .card-header .ck-button span.ck-icon {
  transition: all 0.5s ease;
}
.edit-service-card.expanded .card-header .ck-button span.ck-icon {
  transform: rotate(-180deg);
}
@media (max-width: 991px) {
  .edit-service-card .card-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .edit-service-card .card-header .ck-button {
    width: 40px;
    height: 40px !important;
    border-radius: 50% !important;
    position: absolute;
    top: 15px;
    right: 15px;
  }
  .edit-service-card .card-header .ck-button span:not(.ck-icon) {
    display: none;
  }
}
.edit-service-card .card-header .service-price {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
}
.edit-service-card .card-header .service-price .no-price {
  margin-bottom: 0;
  font-size: 12px;
  line-height: 14.5px;
}
.edit-service-card .card-header .service-price .tier-container {
  width: 120px;
}
.edit-service-card .card-header .service-price .tier-desc {
  margin-bottom: 2px;
  font-size: 12px;
  line-height: 15px;
}
.edit-service-card .card-header .service-price .tier-price {
  background-color: #fff;
  margin-bottom: 0px;
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  font-size: 25px;
  border: 1px solid var(--gray-300);
  border-radius: 4px;
}
/* ==========================================================================
  /Card header
  ========================================================================== */
/* ==========================================================================
  Card body
  ========================================================================== */
.edit-service-card .card-body {
  height: auto;
  max-height: 0px;
  overflow: hidden;
  transition: height 0.5s ease;
}
.edit-service-card.expanded .card-body {
  max-height: unset;
  height: auto;
  padding: 15px 20px;
  border-top: 1px solid var(--black-20) !important;
}
.edit-service-card.expanded .card-body .delete-service-button {
  margin: 10px auto;
}
.edit-service-card.expanded .card-body .delete-service-button path {
  fill: var(--error-color) !important;
}
.edit-service-card.expanded .card-body .delete-service-button:hover path,
.edit-service-card.expanded .card-body .delete-service-button:focus path, 
.edit-service-card.expanded .card-body .delete-service-button:active path {
  fill: var(--error-dark-color) !important;
}
/* ==========================================================================
  /Card body
  ========================================================================== */
