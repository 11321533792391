.contact-section-main-title {
    font-size: 24px;
    font-weight: 600;
    color: #001450;
    text-align: center;
}
.contact-section-main-subtitle {
    font-size: 20px;
    color: #444444;
    text-align: center;
}
.contact-section-main-container {
    padding: 20px;
    margin: 0 5% 35px 5%;
    background-color: #EFF6FF;
}
.contact-section-schedule-text {
    font-size: 20px;
    color: #404040;
    text-align: center;
}
.contact-section-schedule-container {
    justify-content: center;
}
.contact-options-container {
    padding: 10px 10px 20px 10px;
    justify-content: space-between;
}
.contact-option-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}
@media (max-width: 992px) {
    .contact-section-main-title {
        font-size: 20px;
        text-align: center;
        margin: 0;
    }
    .contact-section-main-subtitle {
        font-size: 16px;
        text-align: center;
    }
    .contact-section-schedule-text {
        font-size: 16px;
        text-align: left;
        margin: 0;
    }
    .contact-section-schedule-container {
        justify-content: flex-start;
    }
    .contact-options-container {
        padding: 10px 10px 20px 10px;
        justify-content: flex-start;
    }
    .contact-option-item {
        justify-content: flex-start;
    }
    .contact-option-item .ck-button.iconed {
        justify-content: flex-start;
    }
}
