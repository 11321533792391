.affix-header .ant-affix {
  top: 0 !important;
  background-color: #ffffff;
}

.exxon-navbar-container {
  display: flex;
  background-color: #ffffff;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  min-height: 64px;
  width: 90%;
  margin: 0 auto;
  border-bottom: 2px solid var(--mariner-color);
}

.navigation-navbar-container {
  width: 40%;
}

.sidebar-collapse-container {
  width: 59px;
  height: 90px;
  background-color: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar-collapse-icon {
  cursor: pointer;
  transform: rotate(180deg);
}

@media (max-width: 991px) {
  .exxon-navbar-container {
    align-items: inherit;
    flex-direction: column-reverse;
    min-height: 117px;
    width: 100%;
    border-bottom: 0;
  }
  /* .exxon-navbar-container .workshop-switch {
    margin-top: 20px;
  } */
}

.exxon-navbar-container .current-section {
  border: none;
}
.exxon-navbar-container .current-section p {
  font-size: 24px;
  line-height: 30px;
  color: var(--mariner-color);
  font-weight: 400;
  margin: 0;
}
@media (max-width: 991px) {
  .exxon-navbar-container .current-section {
    padding: 0 5%;
  }
  .exxon-navbar-container .current-section p {
    border-bottom: 2px solid var(--mariner-color);
    padding-bottom: 10px;
  }
}
.exxon-navbar-container .exxon-navbar > .ant-menu {
  margin: 0 !important;
  padding: 12px 0;
  align-items: center;
}

.ant-layout-header {
  width: 100%;
  padding: 0;
  z-index: 2100;
}
.ant-layout-header > ul {
  width: 100%;
  max-width: 650px;
}

@media (max-width: 1024px) {
  .ant-layout-header {
  }
}



/*
.start-quiz-modal .ant-drawer-content-wrapper {
  width: calc(100vw - 300px) !important;
}
@media(max-width: 992px) {
  .start-quiz-modal .ant-drawer-content-wrapper {
    width: 100vw;
  }
}
*/
.start-quiz-modal,
.cta-use-photos-modal {
  width: 720px !important;
  top: 91px;
}
.start-quiz-modal .ant-modal-content, 
.cta-use-photos-modal .ant-modal-content {
  background-color: #fff !important;
  border-radius: 10px;
  overflow: hidden;
}
@media (max-width: 992px) {
  .start-quiz-modal,
  .cta-use-photos-modal {
    top: 113px;
    width: 95% !important;
    min-width: 320px !important;
  }
}
.start-quiz-modal .ant-modal-body,
.cta-use-photos-modal .cta-use-photos-modal {
  padding: 40px;
}
.start-quiz-modal .ant-drawer-header-title,
.cta-use-photos-modal .ant-drawer-header-title {
  justify-content: flex-end;
}
.start-quiz-modal .ant-modal-body h3,
.cta-use-photos-modal .ant-modal-body h3 {
  text-align: center;
  font-size: 24px !important;
  line-height: 30px !important;
  font-weight: 600;
  margin-bottom: 15px;
  color: var(--mariner-color);
}
.start-quiz-modal .ant-modal-body p,
.cta-use-photos-modal .ant-modal-body p {
  color: #444 !important;
  font-size: 16px !important;
  line-height: 20px;
  font-weight: 600;
  max-width: 600px;
  margin: 0 auto 10px auto !important;
  text-align: center;
}
.cta-use-photos-modal .ant-modal-body h3 {
  font-size: 24px !important;
  line-height: 30px !important;
}
.cta-use-photos-modal .ant-modal-body p {
  font-size: 20px !important;
  line-height: 24px !important;
}
.start-quiz-modal .ant-modal-body p > a,
.cta-use-photos-modal .ant-modal-body p > a {
  color: var(--primary-color);
}
.start-quiz-modal .ant-modal-close,
.cta-use-photos-modal .ant-modal-close {
  top: 20px;
  right: 20px;
  height: 20px;
  width: 20px;
}
.start-quiz-modal .ant-modal-close .ant-modal-close-x,
.start-quiz-modal .ant-modal-close svg,
.cta-use-photos-modal .ant-modal-close .ant-modal-close-x,
.cta-use-photos-modal .ant-modal-close svg {
  width: 20px;
  height: 20px;
  line-height: 20px;
}
.start-quiz-modal .start-quiz-buttons-container,
.cta-use-photos-modal .start-quiz-buttons-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
}
.start-quiz-modal .ant-modal-body .ant-btn {
  width: 100%;
  max-width: 240px;
  height: 40px;
}
.start-quiz-modal .ant-modal-body .ant-btn.ant-btn-default > span {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
}
.start-quiz-modal .ant-modal-body .ant-btn.ant-btn-link  {
  height: 30px;
}
.start-quiz-modal .ant-modal-body .ant-btn.ant-btn-link > span {
  color: var(--primary-color);
  text-decoration: underline;
  font-weight: 600;
}

.start-quiz-modal .ant-modal-body > .anticon {
  display: block;
  margin: 5px auto 10px auto;
  color: var(--primary-color) !important;
}
